import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  fetchRepliesLoading: false,
  conversations: {
    data: [],
    meta: {
      total: 0,
    },
  },

  replies: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: fetch all the conversations
export const fetchConversations = createAsyncThunk(
  "fetchConversations/conversations",
  async ({ limit = 10, page = 1, params }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`message`, {
        params: {
          ...params,
          limit,
          page,
        },
      });

      return {
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: fetch all the replies of a conversation
export const fetchRepliesConversations = createAsyncThunk(
  "fetchRepliesConversations/conversations",
  async ({ limit = 10, page = 1, params }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`message`, {
        params: {
          ...params,
          limit,
          page,
        },
      });

      return {
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: create conversation
export const createConversation = createAsyncThunk(
  "createConversation/conversations",
  async ({ data, handleClose, history, refetch = false, user_id }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`message`, data);

      if (refetch) {
        thunkApi.dispatch(
          fetchConversations({
            limit: 1000,
            params: {
              user_id,
            },
          })
        );
      }
      return {
        data: response.data.data,
        handleClose,
        history,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: update conversation
export const updateConversation = createAsyncThunk(
  "updateConversation/conversations",
  async ({ data, handleClose, id }, thunkApi) => {
    try {
      const response = await axiosInstance.patch(`message/${id}`, data);

      return {
        data: response.data.data,
        handleClose,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: delete conversation
export const deleteConversation = createAsyncThunk(
  "deleteConversation/conversations",
  async ({ handleClose, id }, thunkApi) => {
    try {
      await axiosInstance.delete(`message/${id}`);
      return {
        data: id,
        handleClose,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

const conversationslice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    resetLoadings: (state) => {
      state.isLoading = false;
      state.fetchLoading = false;
    },
  },
  extraReducers: (builder) => {
    // TODO: get conversation
    builder.addCase(fetchConversations.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchConversations.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.conversations = action.payload;
      state.mainConversations = state.conversations?.data?.filter?.((cat) => cat?.parent_id === null);
    });

    builder.addCase(fetchConversations.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      toast.error(action.payload.error.message || "ERROR");
    });

    // TODO: get replies
    builder.addCase(fetchRepliesConversations.pending, (state, _) => {
      state.fetchRepliesLoading = true;
    });

    builder.addCase(fetchRepliesConversations.fulfilled, (state, action) => {
      state.fetchRepliesLoading = false;
      state.replies = action.payload;
    });

    builder.addCase(fetchRepliesConversations.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchRepliesLoading = false;
      toast.error(action.payload.error.message || "ERROR");
    });

    // TODO: create conversation
    builder.addCase(createConversation.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createConversation.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.conversations.data = [action.payload.data, ...state.conversations.data];
      // state.conversations.meta.total = state.conversations.meta.total + 1;
      toast.success("Message is sent successfully.");
    });

    builder.addCase(createConversation.rejected, (state, action) => {
      state.isLoading = false;
      toast(action.payload.error.message);
    });

    // TODO: update conversation
    builder.addCase(updateConversation.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateConversation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.conversations.data = state.conversations.data.map((conversation) => {
        if (conversation.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return conversation;
        }
      });
      toast.success("Message is updated successfully.");
    });

    builder.addCase(updateConversation.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });

    // TODO: delete conversation
    builder.addCase(deleteConversation.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteConversation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.conversations.data = state.conversations.data.filter(
        (conversation) => conversation.id !== action.payload.data
      );
      toast.success("Message is deleted successfully.");
    });

    builder.addCase(deleteConversation.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });
  },
});

export default conversationslice.reducer;

export const { resetLoadings } = conversationslice.actions;
