import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../../../auth/useAuthContext";
import Loading from "../../../common/Loading";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { fetchOrders } from "../../../redux/slices/orderSlice";
import KanbanBoard from "./KanbanBoard";

const NewOrders = () => {
  const { user } = useAuthContext();

  const dispatch = useDispatch();

  const [smBtn, setSmBtn] = useState(false);
  const [dragged, setDragged] = useState(false);

  const paidOrders = useSelector((state) => state.order.paidOrders);
  const invoicedOrders = useSelector((state) => state.order.invoicedOrders);
  const newOrders = useSelector((state) => state.order.newOrders);
  const fetchLoading = useSelector((state) => state.order.fetchLoading);

  useEffect(() => {
    dispatch(
      fetchOrders({
        params: {
          hasInvoice: false,
          ...(user?.role?.slug === "user" ? { user_id: user?.id } : {}),
        },
        limit: 100,
        page: 1,
        status: "new",
      })
    );

    dispatch(
      fetchOrders({
        params: {
          status: "invoiced",
          ...(user?.role?.slug === "user" ? { user_id: user?.id } : {}),
        },
        limit: 100,
        page: 1,
      })
    );

    dispatch(
      fetchOrders({
        params: {
          status: "paid",
          ...(user?.role?.slug === "user" ? { user_id: user?.id } : {}),
        },
        limit: 100,
        page: 1,
      })
    );
  }, [dispatch, dragged, user?.id, user?.role?.slug]);

  const columns = {
    columns: {
      "column-new-order": {
        id: "column-new-order",
        text: "New Patients",
        theme: "light",
        patients: newOrders?.data,
      },
      "column-invoiced": {
        id: "column-invoiced",
        text: "Invoiced",
        theme: "primary",
        patients: invoicedOrders?.data,
      },
      "column-paid": {
        id: "column-paid",
        text: "Paid",
        theme: "warning",
        patients: paidOrders?.data,
      },
    },
    columnOrder: ["column-new-order", "column-invoiced", "column-paid"],
  };

  return (
    <React.Fragment>
      <Head title="New Orders"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>New Orders</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#toggle"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmBtn(!smBtn);
                  }}
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                >
                  <Icon name="menu-alt-r"></Icon>
                </a>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {fetchLoading ? (
          <Loading />
        ) : (
          <Block>
            <div className="nk-kanban">
              <KanbanBoard columns={columns} setDragged={setDragged} />
            </div>
          </Block>
        )}
      </Content>
    </React.Fragment>
  );
};

export default NewOrders;
