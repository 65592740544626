import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
import { fetchReminderOrders } from "./orderSlice";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  fetchLoadingInPaidOrder: false,
  reminders: {
    data: [],
    meta: {
      total: 0,
    },
  },
  mainReminders: [],
};

// TODO: fetch all the reminders
export const fetchReminders = createAsyncThunk(
  "fetchReminders/reminders",
  async ({ limit = 10, page = 1 }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`reminder`, {
        params: {
          limit,
          page,
        },
      });

      return {
        data: response.data.data,
        meta: {
          total: response.data.data.length,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);
// TODO: fetch all the reminders in paid orders
export const fetchRemindersInPaidOrders = createAsyncThunk(
  "fetchRemindersInPaidOrders/reminders",
  async ({ limit = 10, page = 1 }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`reminder`, {
        params: {
          limit,
          page,
        },
      });

      return {
        data: response.data.data,
        meta: {
          total: response.data.data.length,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: create reminder
export const createReminder = createAsyncThunk("createReminder/reminders", async ({ data, handleClose }, thunkApi) => {
  try {
    const response = await axiosInstance.post(`reminder`, data);

    return {
      data: response.data.data,
      handleClose,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: update reminder
export const updateReminder = createAsyncThunk(
  "updateReminder/reminders",
  async ({ data, handleClose, id }, thunkApi) => {
    try {
      const response = await axiosInstance.patch(`reminder/${id}`, data);

      return {
        data: response.data.data,
        handleClose,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: delete reminder
export const deleteReminder = createAsyncThunk(
  "deleteReminder/reminders",
  async ({ handleClose, id, user, specialists, selectedSpecialist, defaultSpecialist }, thunkApi) => {
    try {
      await axiosInstance.delete(`delete-reminder/${id}`);

      thunkApi.dispatch(
        fetchReminderOrders({
          limit: 400,
          page: 1,
          params: {
            ...(user?.role?.slug === "user" ? { user_id: user?.id } : {}),
            ...(selectedSpecialist !== "all"
              ? {
                  specialist_id: selectedSpecialist || defaultSpecialist || specialists?.data?.[0]?.id,
                }
              : {}),
          },
        })
      );
      return {
        data: id,
        handleClose,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: bulk reminder update
export const updateBulkReminders = createAsyncThunk(
  "updateBulkReminders/reminders",
  async ({ handleClose, data, user, selectedSpecialist, defaultSpecialist, specialists }, thunkApi) => {
    try {
      await axiosInstance.post(`update-reminder-bulk`, data);

      thunkApi.dispatch(
        fetchReminderOrders({
          limit: 400,
          page: 1,
          params: {
            ...(user?.role?.slug === "user" ? { user_id: user?.id } : {}),
            ...(selectedSpecialist !== "all"
              ? {
                  specialist_id: selectedSpecialist || defaultSpecialist || specialists?.data?.[0]?.id,
                }
              : {}),
          },
        })
      );
      return {
        // data: id,
        handleClose,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

const reminderslice = createSlice({
  name: "reminder",
  initialState,
  reducers: {
    resetLoadings: (state) => {
      state.isLoading = false;
      state.fetchLoading = false;
    },
  },
  extraReducers: (builder) => {
    // TODO: get reminder
    builder.addCase(fetchReminders.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchReminders.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.reminders = action.payload;
      state.mainReminders = state.reminders?.data?.filter?.((cat) => cat?.parent_id === null);
    });

    builder.addCase(fetchReminders.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      toast.error(action.payload.error.message || "ERROR");
    });

    // TODO: get reminder in paid orders
    builder.addCase(fetchRemindersInPaidOrders.pending, (state, _) => {
      state.fetchLoadingInPaidOrder = true;
    });

    builder.addCase(fetchRemindersInPaidOrders.fulfilled, (state, action) => {
      state.fetchLoadingInPaidOrder = false;
      state.reminders = action.payload;
    });

    builder.addCase(fetchRemindersInPaidOrders.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoadingInPaidOrder = false;
      toast.error(action.payload.error.message || "ERROR");
    });

    // TODO: create reminder
    builder.addCase(createReminder.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createReminder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reminders.data = [action.payload.data, ...state.reminders.data];
      state.reminders.meta.total = state.reminders.meta.total + 1;
      toast.success("Reminder is created successfully.");
    });

    builder.addCase(createReminder.rejected, (state, action) => {
      state.isLoading = false;
      toast(action.payload.error.message);
    });

    // TODO: update reminder
    builder.addCase(updateReminder.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateReminder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reminders.data = state.reminders.data.map((reminder) => {
        if (reminder.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return reminder;
        }
      });
      toast.success("Reminder is updated successfully.");
    });

    builder.addCase(updateReminder.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });

    // TODO: delete reminder
    builder.addCase(deleteReminder.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteReminder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reminders.data = state.reminders.data.filter((reminder) => reminder.id !== action.payload.data);
      toast.success("Reminder is deleted successfully.");
    });

    builder.addCase(deleteReminder.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });

    // TODO: bulk update reminder
    builder.addCase(updateBulkReminders.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateBulkReminders.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success("Updated successfully.");
    });

    builder.addCase(updateBulkReminders.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });
  },
});

export default reminderslice.reducer;

export const { resetLoadings } = reminderslice.actions;
