import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import NoData from "../../common/NoData";
import { BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import { fetchRepliesConversations } from "../../redux/slices/conversationSlice";

const ViewConversationModal = ({ onClose, order, message, isEdit = false }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.conversation.isLoading);
  const fetchRepliesLoading = useSelector((state) => state.conversation.fetchRepliesLoading);
  const replies = useSelector((state) => state.conversation.replies);

  console.log(message, "messssageee", replies);
  //   TODO: fetch the replies

  useEffect(() => {
    if (message?.id) {
      dispatch(
        fetchRepliesConversations({
          limit: 100,
          params: {
            parent_id: message?.id,
          },
        })
      );
    }
  }, [dispatch, isEdit, message]);

  return (
    <div className="relative p-4">
      <div size="lg">
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Message to {order?.user?.name || message?.user?.name}(
              <span className="text-sm font-normal"> regarding </span>
              {order?.code || message?.order?.code})
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>
        <div>
          {/* TODO: show replies */}
          {message?.id ? (
            <>
              {fetchRepliesLoading ? (
                <Spinner />
              ) : (
                <div className="max-h-[50vh] overflow-y-auto">
                  {replies?.data?.length ? (
                    <div className="mb-3">
                      {[...replies?.data]?.map((r, i) => (
                        <div className="flex flex-col mb-3">
                          <div key={i} className="flex items-start gap-3 border p-2 py-1 rounded-md h-fit">
                            <h2 className="font-bold text-nowrap">{r?.user?.name} says :</h2>

                            <div className="w-full">
                              <h2>{r?.message}</h2>
                              <div className="flex w-full justify-end">
                                <span className="text-[0.55rem] text-end text-gray-400">
                                  {moment(r?.created_at).format("MMM D, YYYY | hh:mm a")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <NoData message="No replies" />
                  )}
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>

      <button className="absolute top-5 right-5 cursor-pointer" disabled={isLoading} type="reset" onClick={onClose}>
        <Icon
          name={"cross"}
          style={{
            fontSize: 20,
          }}
        />
      </button>
    </div>
  );
};

export default ViewConversationModal;
