import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { Bounce, ToastContainer } from "react-toastify";
import App from "./App";
import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import { AuthProvider } from "./auth/JwtContext";
import Loading from "./common/Loading";
import "./index.css";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";


const Error404Modern = lazy(() => import("./pages/error/404-modern"));

ReactDOM.render(
  <React.Fragment>
    <Suspense fallback={<Loading />}>
      <AuthProvider>
        <Provider store={store}>
          <Router basename={`/`}>
            <Route render={({ location }) => (location.state && location.state.is404 ? <Error404Modern /> : <App />)} />
            <ToastContainer
              position="bottom-center"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
              transition={Bounce}
            />
          </Router>
        </Provider>
      </AuthProvider>
    </Suspense>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
