import React from "react";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import { Col } from "../../components/Component";

const LibraryCategoryCard = ({ item }) => {
  return (
    <Col xxl={3} lg={4} sm={6}>
      <Card className="card-bordered product-card">
        <Link to={`${process.env.PUBLIC_URL}/libraries/category/${item?.id}`} className="product-thumb">
          <div className="product-thumb">
            <img
              className="card-img-top h-52 object-cover"
              src={
                item?.image || "https://gratisography.com/wp-content/uploads/2024/10/gratisography-cool-cat-800x525.jpg"
              }
              alt=""
            />
          </div>
          <div className="card-inner text-center">
            <ul className="product-tags">
              <li>{item?.name} </li>
            </ul>
          </div>
        </Link>
      </Card>
    </Col>
  );
};
export default LibraryCategoryCard;
