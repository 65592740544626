import React from "react";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Icon } from "../../components/Component";

const ProductToolbar = ({ sm, onFilterChange, toggle }) => {
  return (
    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
      <ul className="nk-block-tools g-3">
        <li>
          <div className="form-control-wrap">
            <div className="form-icon form-icon-right">
              <Icon name="search"></Icon>
            </div>
            <input
              type="text"
              className="form-control"
              id="default-04"
              placeholder="Quick search by SKU"
              onChange={(e) => onFilterChange(e)}
            />
          </div>
        </li>
        <li>
          <UncontrolledDropdown>
            <DropdownToggle
              color="transparent"
              className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
            >
              Status
            </DropdownToggle>
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => ev.preventDefault()}>
                    <span>New Items</span>
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => ev.preventDefault()}>
                    <span>Featured</span>
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => ev.preventDefault()}>
                    <span>Out of Stock</span>
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
        <li className="nk-block-tools-opt">
          <Button
            className="toggle btn-icon d-md-none"
            color="primary"
            onClick={() => {
              toggle("add");
            }}
          >
            <Icon name="plus"></Icon>
          </Button>
          <Button
            className="toggle d-none d-md-inline-flex"
            color="primary"
            onClick={() => {
              toggle("add");
            }}
          >
            <Icon name="plus"></Icon>
            <span>Add Product</span>
          </Button>
        </li>
      </ul>

      {/* <SimpleBar
        className={`nk-add-product toggle-slide toggle-slide-right toggle-screen-any ${
          view.add ? "content-active" : ""
        }`}
      >
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle tag="h5">Add Product</BlockTitle>
            <BlockDes>
              <p>Add information or update product.</p>
            </BlockDes>
          </BlockHeadContent>
        </BlockHead>
        <Block>
        
        </Block>
      </SimpleBar>

      {view.add && <div className="toggle-overlay" onClick={toggle}></div>} */}
    </div>
  );
};

export default ProductToolbar;
