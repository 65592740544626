import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useAuthContext } from "../../../auth/useAuthContext";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from "../../../components/Component";
import Head from "../../../layout/head/Head";
import Basic from "../../../sections/user-profile/Basic";
import ChangePw from "../../../sections/user-profile/ChangePw";

const UserProfileRegularPage = ({ sm, updateSm }) => {
  const { user } = useAuthContext();

  const [modal, setModal] = useState(false);
  const [changePwModal, setChangePwModal] = useState(false);

  return (
    <React.Fragment>
      <Head title="My Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Personal Information</BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <div className="nk-data data-list">
          <div className="data-head flex justify-between items-center">
            <h6 className="overline-title">Basics</h6>
            {/* {user?.role?.slug === "admin" ? ( */}
            <button onClick={() => setModal(true)} className="overline-title">
              <Icon
                name={"edit-fill"}
                style={{
                  fontSize: 20,
                }}
              />
            </button>
            {/* ) : null} */}
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Full Name</span>
              <span className="data-value">{user?.name}</span>
            </div>
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Email</span>
              <span className="data-value">{user?.email}</span>
            </div>
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Additional Email</span>
              <span className="data-value">{user?.alt_email || "-"}</span>
            </div>
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Phone Number</span>
              <span className="data-value">{user?.phone}</span>
            </div>
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Additional Phone Number</span>
              <span className="data-value">{user?.alt_phone || "-"}</span>
            </div>
          </div>
        </div>
        {/* {user?.role?.slug !== "admin" ? (
          <BlockTitle tag="h4">NOTE: Contact admin for changing personal information.</BlockTitle>
        ) : null} */}

        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Preferences</h6>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Password</span>
            </div>
            <div className="data-col data-col-end">
              <button onClick={() => setChangePwModal(true)} className="link link-primary">
                Change Password
              </button>
            </div>
          </div>
        </div>
      </Block>

      <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
        <a
          href="#dropdownitem"
          onClick={(ev) => {
            ev.preventDefault();
            setModal(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <ModalBody>
          <Basic setModal={setModal} />
        </ModalBody>
      </Modal>

      {/* TODO: change password modal */}
      <Modal isOpen={changePwModal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
        <a
          href="#dropdownitem"
          onClick={(ev) => {
            ev.preventDefault();
            setChangePwModal(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <ModalBody>
          <ChangePw setModal={setChangePwModal} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default UserProfileRegularPage;
