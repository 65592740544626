import React from "react";
import { useSelector } from "react-redux";
import NoData from "../../common/NoData";
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";

const SearchPatientList = ({ setExistingUser, onClose }) => {
  const existingUser = useSelector((state) => state.user.existingUser);

  const handleSelectPatient = (e) => {
    setExistingUser(e);
    onClose();
  };

  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-md">
      <div className="flex justify-between">
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Select User
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Icon
          onClick={() => onClose()}
          name={"cross"}
          style={{
            fontSize: 20,
          }}
        />
      </div>
      <hr />
      {existingUser?.length > 0 ? (
        <div className="space-y-4 mt-4">
          {[...existingUser]?.map((e, i) => (
            <button
              key={i}
              className="w-full text-left bg-white border border-gray-200 p-4 rounded-lg shadow-sm hover:bg-blue-100 hover:shadow-md transform transition-transform duration-300 hover:scale-105"
              onClick={() => handleSelectPatient(e)}
            >
              <span className="font-medium text-gray-800">{e?.name}</span>
              <span className="text-gray-600 ml-2">({e?.phone})</span>
            </button>
          ))}
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default SearchPatientList;
