import { unwrapResult } from "@reduxjs/toolkit";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, Form, Label, Row, Spinner } from "reactstrap";
import Loading from "../../common/Loading";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard } from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { createNewUser, fetchSingleUser, updateUser } from "../../redux/slices/userSlice";

const CreateSpecialist = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { register, handleSubmit, errors, setValue } = useForm({});

  const workTypes = useSelector((state) => state.workType.workTypes);
  const roles = useSelector((state) => state.role.roles);
  const isLoading = useSelector((state) => state.user.isLoading);
  const fetchLoading = useSelector((state) => state.user.fetchLoading);
  const specialist = useSelector((state) => state.user.singleUser);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleUser({ id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (specialist && id) {
      setValue("name", specialist?.name);
      setValue("email", specialist?.email);
      setValue("alt_email", specialist?.alt_email);
      setValue("phone", specialist?.phone);
      setValue("alt_phone", specialist?.alt_phone);
      setValue(
        "workType",
        specialist?.works?.map((w) => JSON.stringify(w?.id))
      );
    }
  }, [
    dispatch,
    id,
    setValue,
    specialist,
    specialist?.alt_email,
    specialist?.alt_phone,
    specialist?.email,
    specialist?.name,
    specialist?.phone,
    specialist?.works,
  ]);

  const onFormSubmit = async (data) => {
    try {
      const resultAction = await dispatch(
        id
          ? updateUser({
              data: {
                name: data.name,
                email: data.email,
                phone: data.phone,
                alt_email: data.alt_email,
                alt_phone: data.alt_phone,
                role_id: roles?.data?.find((role) => role?.slug === "consultant")?.id,
                work_type: data.workType,
              },
              id: specialist?.id,
              history,
            })
          : createNewUser({
              data: {
                name: data.name,
                email: data.email,
                phone: data.phone,
                alt_email: data.alt_email,
                alt_phone: data.alt_phone,
                role_id: roles?.data?.find((role) => role?.slug === "consultant")?.id,
              },
              newOrder: false,
              work_type: data.workType,
              history,
            })
      );
      const originalPromiseResult = unwrapResult(resultAction);
      originalPromiseResult.history && originalPromiseResult.history.push("/specialists/list");
    } catch (error) {}
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  return (
    <React.Fragment>
      <Head title="Specialist Registration" />
      <Content>
        {fetchLoading ? (
          <Loading />
        ) : (
          <Block size="lg">
            <BlockHead>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  {id ? `Edit Specialist (${specialist?.name})` : "Register New Specialist"}
                </BlockTitle>
              </BlockHeadContent>
            </BlockHead>
            <PreviewCard>
              <Form onSubmit={handleSubmit(onFormSubmit)} className={formClass}>
                <Row className="g-gs">
                  {/* Order Form */}
                  <Col md="6">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-full-name">
                        Full Name
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="text"
                          id="fv-full-name"
                          name="name"
                          className="form-control"
                        />
                        {errors.name && <span className="invalid">This field is required</span>}
                      </div>
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-primary-email">
                        Primary Email address *
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address",
                            },
                          })}
                          type="email"
                          id="fv-primary-email"
                          name="email"
                          className="form-control"
                        />
                        {errors.email && errors.email.type === "required" && (
                          <span className="invalid">This is required</span>
                        )}
                        {errors.email && errors.email.type === "pattern" && (
                          <span className="invalid">{errors.email.message}</span>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-additional-email">
                        Additional Email address
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address",
                            },
                          })}
                          type="email"
                          id="fv-additional-email"
                          name="alt_email"
                          className="form-control"
                        />
                        {errors.alt_email && errors.alt_email.type === "pattern" && (
                          <span className="invalid">{errors.alt_email.message}</span>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-primary-phone">
                        Primary Phone Number *
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="tel"
                          id="fv-primary-phone"
                          name="phone"
                          className="form-control"
                        />
                        {errors.phone && errors.phone.type === "required" && (
                          <span className="invalid">This is required</span>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-additional-phone">
                        Additional Phone Number
                      </Label>
                      <div className="form-control-wrap">
                        <input ref={register()} id="fv-additional-phone" name="alt_phone" className="form-control" />
                      </div>
                    </div>
                  </Col>

                  <hr />

                  <Col md="6">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="specialist-name">
                        Choose Work Types *
                      </Label>
                      <div className="form-control-wrap flex gap-2">
                        {workTypes?.data?.map((workType, index) => (
                          <div key={index} className="form-check">
                            <input
                              type="checkbox"
                              id={`workType-${index}`}
                              value={workType?.id}
                              name="workType"
                              ref={register({ required: true })}
                              className="form-check-input"
                            />
                            <Label className="form-check-label" htmlFor={`workType-${index}`}>
                              {workType?.name}
                            </Label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>

                  {/* Save and Cancel Buttons */}
                  <Col md="12">
                    <Button disabled={isLoading} color="primary" type="submit">
                      {isLoading ? <Spinner size="sm" color="light" /> : `${id ? "Save" : "Register"}`}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </PreviewCard>
          </Block>
        )}
      </Content>
    </React.Fragment>
  );
};

export default CreateSpecialist;
