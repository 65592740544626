import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "reactstrap";
import { BlockTitle, Icon } from "../../components/Component";
import Upload from "../../pages/app/file-manager/modals/Upload";
import { postFile } from "../../redux/slices/orderSlice";

const UploadFile = ({ onClose, order }) => {
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);
  const isLoading = useSelector((state) => state.order.isLoading);

  const handleSubmitFile = () => {
    const formData = new FormData();

    if (files.length) {
      formData.append("report", files[0]);
    }
    dispatch(postFile({ id: order.id, data: formData, onClose }));
  };

  return (
    <div className="relative p-4">
      <div className="mt-10">
        <BlockTitle tag="h4" page>
          Upload report for {order?.code}
        </BlockTitle>
        <span>(png, jpg, jpeg, webp) 1MB</span>
        <Upload multiple={false} files={files?.length ? files : []} setFiles={setFiles} />

        <div className="mt-5">
          <Button disabled={isLoading} color="primary" onClick={handleSubmitFile}>
            {isLoading ? <Spinner size="sm" color="light" /> : `Upload`}
          </Button>
        </div>
      </div>
      <button className="absolute top-5 right-5 cursor-pointer" disabled={isLoading} type="reset" onClick={onClose}>
        <Icon
          name={"cross"}
          style={{
            fontSize: 20,
          }}
        />
      </button>
    </div>
  );
};

export default UploadFile;
