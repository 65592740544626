import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  library: {
    data: [],
    meta: {
      total: 0,
    },
  },
  singleLibrary: null,
};

// TODO: fetch all the library
export const fetchLibrary = createAsyncThunk(
  "fetchLibrary/library",
  async ({ limit = 10, page = 1, params }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`library`, {
        params: {
          ...params,
          limit,
          page,
        },
      });

      return {
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: create library
export const createLibrary = createAsyncThunk("createLibrary/library", async ({ data, history }, thunkApi) => {
  try {
    const response = await axiosInstance.post(`library`, data);

    return {
      data: response.data.data,
      history,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: update library
export const updateLibrary = createAsyncThunk("updateLibrary/library", async ({ data, handleClose, id }, thunkApi) => {
  try {
    const response = await axiosInstance.post(`library/${id}`, data);

    return {
      data: response.data.data,
      handleClose,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: delete library
export const deleteLibrary = createAsyncThunk("deleteLibrary/library", async ({ handleClose, id }, thunkApi) => {
  try {
    await axiosInstance.delete(`library/${id}`);
    return {
      data: id,
      handleClose,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: single library
export const fetchSingleLibrary = createAsyncThunk("fetchSingleLibrary/library", async ({ id }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`library/${id}`);
    return response.data.data;
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

const librarylice = createSlice({
  name: "library",
  initialState,
  reducers: {
    resetLoadings: (state) => {
      state.isLoading = false;
      state.fetchLoading = false;
    },

    resetSingleLibrary: (state) => {
      state.singleLibrary = null;
    },
  },
  extraReducers: (builder) => {
    // TODO: get library
    builder.addCase(fetchLibrary.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchLibrary.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.library = action.payload;
      state.mainLibrary = state.library?.data?.filter?.((cat) => cat?.parent_id === null);
    });

    builder.addCase(fetchLibrary.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      toast.error(action.payload.error.message || "ERROR");
    });

    // TODO: create library
    builder.addCase(createLibrary.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createLibrary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.library.data = [action.payload.data, ...state.library.data];
      state.library.meta.total = state.library.meta.total + 1;
      toast.success("Library is created successfully.");
    });

    builder.addCase(createLibrary.rejected, (state, action) => {
      state.isLoading = false;
      toast(action.payload.error.message);
    });

    // TODO: update library
    builder.addCase(updateLibrary.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateLibrary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.library.data = state.library.data.map((library) => {
        if (library.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return library;
        }
      });
      toast.success("Library is updated successfully.");
    });

    builder.addCase(updateLibrary.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });

    // TODO: delete library
    builder.addCase(deleteLibrary.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteLibrary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.library.data = state.library.data.filter((library) => library.id !== action.payload.data);
      toast.success("Library is deleted successfully.");
    });

    builder.addCase(deleteLibrary.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });

    // TODO:fetch single library
    builder.addCase(fetchSingleLibrary.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchSingleLibrary.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.singleLibrary = action.payload;
    });

    builder.addCase(fetchSingleLibrary.rejected, (state, action) => {
      state.fetchLoading = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });
  },
});

export default librarylice.reducer;

export const { resetLoadings, resetSingleLibrary } = librarylice.actions;
