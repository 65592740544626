import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
};

// TODO: change password
export const changePassword = createAsyncThunk(
  "changePassword/auth",
  async ({ data, closeModal, logout }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`change-password`, data);

      return {
        data: response.data,
        closeModal,
        logout,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: forgot password
export const forgotPassword = createAsyncThunk("forgotPassword/auth", async ({ data }, thunkApi) => {
  try {
    const response = await axiosInstance.post(`forgot-password`, data);
    return {
      data: response.data,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

const authlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    // TODO: change pw
    builder.addCase(changePassword.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success("Password is changed successfully");
    });

    builder.addCase(changePassword.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });

    // TODO: forgot pw
    builder.addCase(forgotPassword.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.isLoading = false;
      toast.success("We have emailed your password reset link.");
    });

    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(JSON.stringify(action.payload.error.message));
    });
  },
});

export default authlice.reducer;
