import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Label } from "reactstrap";
import { useAuthContext } from "../../auth/useAuthContext";
import Loading from "../../common/Loading";
import NoData from "../../common/NoData";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { fetchReminderOrders } from "../../redux/slices/orderSlice";
import { fetchUsers } from "../../redux/slices/userSlice";
import KanbanBoard from "./KanbanBoard";

const Reminders = () => {
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const defaultSpecialist = window.location.search.split("=")[1];

  const [smBtn, setSmBtn] = useState(false);
  const [selectedSpecialist, setSelectedSpecialist] = useState(
    user?.role?.slug === "consultant" ? user?.id : defaultSpecialist || "all"
  );

  const { errors, register, watch, setValue } = useForm();

  const roles = useSelector((state) => state.role.roles);
  const specialists = useSelector((state) => state.user.users);
  const orders = useSelector((state) => state.order.orders);
  const fetchReminderLoading = useSelector((state) => state.order.fetchLoading);
  const fetchUserLoading = useSelector((state) => state.user.fetchLoading);

  const [formattedColumns, setFormattedColumns] = useState({
    patients: [],
  });

  // TODO: fetch the specialists
  useEffect(() => {
    if (roles?.data?.find((role) => role?.slug === "consultant")?.id) {
      dispatch(
        fetchUsers({
          limit: 100,
          page: 1,
          params: {
            role_id: roles?.data?.find((role) => role?.slug === "consultant")?.id,
          },
        })
      );
    }
  }, [dispatch, roles?.data, user?.id, user?.role]);

  useEffect(() => {
    setFormattedColumns({
      patient: [],
    });
    if (specialists?.data?.length) {
      dispatch(
        fetchReminderOrders({
          limit: 400,
          page: 1,
          params: {
            ...(user?.role?.slug === "user" ? { user_id: user?.id } : {}),
            ...(selectedSpecialist !== "all"
              ? {
                  specialist_id: selectedSpecialist || defaultSpecialist || specialists?.data?.[0]?.id,
                }
              : {}),
          },
        })
      );
    }
  }, [
    defaultSpecialist,
    dispatch,
    selectedSpecialist,
    specialists?.data,
    specialists?.data?.length,
    user,
    user?.id,
    user?.role,
  ]);

  useEffect(() => {
    setValue("specialist", user?.role?.slug === "consultant" ? user?.id : defaultSpecialist || "all");
  }, [defaultSpecialist, setValue, specialists.data, user?.id, user?.role?.slug]);

  useEffect(() => {
    if (orders?.data?.length) {
      // Create a shallow copy of orders.data to sort without modifying the original array
      const sortedData = [...orders?.data]?.sort((a, b) => a?.reminder?.days - b?.reminder?.days);

      const newFormattedColumns = {};
      const newColumnOrder = new Set();

      // Iterate over the sorted data to populate newFormattedColumns and newColumnOrder
      sortedData.forEach((o) => {
        newColumnOrder.add(o?.reminder?.name);

        newFormattedColumns[o?.reminder?.name] = {
          id: o?.reminder?.name,
          text: `${o?.reminder?.name} (${o?.reminder?.days} Days)`,
          theme: "dark",
          patient: [
            { ...o?.order, time: o?.reminder_datetime, reminder_id: o?.id },
            ...(newFormattedColumns[o?.reminder?.name]?.patient || []),
          ]?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at)),
          reminder: o?.reminder,
        };
      });

      // Update state with the new column order and formatted columns
      setFormattedColumns({ columns: newFormattedColumns, columnOrder: Array.from(newColumnOrder) });
    }
  }, [orders?.data]);

  return (
    <React.Fragment>
      <Head title="Reminders"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Reminders</BlockTitle>
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#toggle"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmBtn(!smBtn);
                  }}
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                >
                  <Icon name="menu-alt-r"></Icon>
                </a>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {/* TODO: select the specialists */}
        <Col md="6" className={"mb-2"}>
          <div className="form-group">
            <Label className="form-label" htmlFor="fv-specialist">
              Select Specialist
            </Label>
            <div className="form-control-wrap">
              <div className="form-control-select">
                <select
                  disabled={user?.role?.slug === "consultant"}
                  onChange={(e) => {
                    setSelectedSpecialist(e.target.value);
                    e.target.value === "all"
                      ? history.push(`/reminders`)
                      : history.push(`/reminders?specialist=${e.target.value}`);
                  }}
                  ref={register({
                    required: true,
                  })}
                  className="form-control form-select"
                  id="fv-specialist"
                  name="specialist"
                  placeholder="Select a specialist"
                >
                  {[{ name: "all", id: "all" }, ...specialists?.data]?.map((s, i) => {
                    return (
                      <option key={i} value={s?.id}>
                        {s?.name}
                      </option>
                    );
                  })}
                </select>
                {errors.specialist && <span className="invalid">This field is required</span>}
              </div>
            </div>
          </div>
        </Col>

        {fetchReminderLoading || fetchUserLoading ? (
          <Loading />
        ) : (
          <>
            {orders?.data?.length ? (
              <Block>
                <div className="nk-kanban">
                  <KanbanBoard
                    columns={formattedColumns}
                    specialist={watch()?.specialist}
                    selectedSpecialist={selectedSpecialist}
                    defaultSpecialist={defaultSpecialist}
                  />
                </div>
              </Block>
            ) : (
              <div className="my-20">
                <NoData imgSize={100} message="No reminders" />
              </div>
            )}
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default Reminders;
