import { unwrapResult } from "@reduxjs/toolkit";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Label, Row, Spinner } from "reactstrap";
import { useAuthContext } from "../../auth/useAuthContext";
import { Block, BlockHeadContent, BlockTitle, PreviewCard } from "../../components/Component";
import { updateUser } from "../../redux/slices/userSlice";

const Basic = ({ setModal }) => {
  const dispatch = useDispatch();
  const { user, updateAuthUser } = useAuthContext();

  const { register, handleSubmit, errors, setValue } = useForm({});
  const isLoading = useSelector((state) => state.user.isLoading);

  const onFormSubmit = async (data) => {
    try {
      const resultAction = await dispatch(
        updateUser({
          data: {
            name: data.name,
            email: data.email,
            phone: data.phone,
            alt_email: data.alt_email,
            alt_phone: data.alt_phone,
            role_id: data.role_id,
          },
          id: user?.id,
          handleClose: () => setModal(false),
          updateAuthUser,
        })
      );
      const originalPromiseResult = unwrapResult(resultAction);
      originalPromiseResult.handleClose && originalPromiseResult.handleClose();
    } catch (error) {}
  };

  useEffect(() => {
    setValue("name", user?.name);
    setValue("email", user?.email);
    setValue("alt_email", user?.alt_email);
    setValue("phone", user?.phone);
    setValue("alt_phone", user?.alt_phone);
    setValue("role_id", user?.role_id);
  }, [dispatch, setValue, user?.name, user?.email, user?.alt_email, user?.phone, user?.alt_phone, user?.role_id]);

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  return (
    <React.Fragment>
      <Block size="lg">
        <divHead>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Update your profile
            </BlockTitle>
          </BlockHeadContent>
        </divHead>
        <PreviewCard className={"mt-2"}>
          <Form onSubmit={handleSubmit(onFormSubmit)} className={formClass}>
            <Row className="g-gs">
              {/* Order Form */}
              <Col md="6">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-full-name">
                    Full Name
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      ref={register({ required: true })}
                      type="text"
                      id="fv-full-name"
                      name="name"
                      className="form-control"
                    />
                    {errors.name && <span className="invalid">This field is required</span>}
                  </div>
                </div>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-primary-email">
                    Primary Email address *
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      ref={register({
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      type="email"
                      id="fv-primary-email"
                      name="email"
                      className="form-control"
                    />
                    {errors.email && errors.email.type === "required" && (
                      <span className="invalid">This is required</span>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                      <span className="invalid">{errors.email.message}</span>
                    )}
                  </div>
                </div>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-additional-email">
                    Additional Email address
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      ref={register({
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      type="email"
                      id="fv-additional-email"
                      name="alt_email"
                      className="form-control"
                    />
                    {errors.alt_email && errors.alt_email.type === "pattern" && (
                      <span className="invalid">{errors.alt_email.message}</span>
                    )}
                  </div>
                </div>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-primary-phone">
                    Primary Phone Number *
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      ref={register({ required: true })}
                      type="tel"
                      id="fv-primary-phone"
                      name="phone"
                      className="form-control"
                    />
                    {errors.phone && errors.phone.type === "required" && (
                      <span className="invalid">This is required</span>
                    )}
                  </div>
                </div>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-additional-phone">
                    Additional Phone Number
                  </Label>
                  <div className="form-control-wrap">
                    <input ref={register()} id="fv-additional-phone" name="alt_phone" className="form-control" />
                  </div>
                </div>
              </Col>

              <hr />

              {/* Save and Cancel Buttons */}
              <Col md="12">
                <Button disabled={isLoading} color="primary" type="submit">
                  {isLoading ? <Spinner size="sm" color="light" /> : "update"}
                </Button>
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </React.Fragment>
  );
};

export default Basic;
