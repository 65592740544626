import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  testimonials: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: fetch all the testimonials
export const fetchTestimonials = createAsyncThunk(
  "fetchTestimonials/testimonials",
  async ({ enqueueSnackbar, limit = 10, page = 1, search }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`testimonials`, {
        params: {
          ...(search !== "" && {
            search,
          }),
          limit,
          page,
        },
      });

      return {
        data: response.data.data,
        meta: {
          total: response.data.data.length,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: create the testimonials
export const createTestimonials = createAsyncThunk(
  "createTestimonials/testimonials",
  async ({ enqueueSnackbar, data, handleClose }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`testimonials`, data);
      return {
        data: response.data.data,
        meta: {
          total: response.data.data.length,
        },
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: update the testimonials
export const updateTestimonial = createAsyncThunk(
  "updateTestimonial/testimonials",
  async ({ enqueueSnackbar, data, handleClose, id }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`testimonials/${id}`, data);
      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: delete team
export const deleteTestimonial = createAsyncThunk(
  "deleteTestimonial/testimonials",
  async ({ enqueueSnackbar, handleClose, id }, thunkApi) => {
    try {
      await axiosInstance.delete(`testimonials/${id}`);
      return {
        data: id,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

const testimonialslice = createSlice({
  name: "testimonials",
  initialState,
  extraReducers: (builder) => {
    // TODO: fetch testimonials
    builder.addCase(fetchTestimonials.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchTestimonials.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.testimonials = action.payload;
    });

    builder.addCase(fetchTestimonials.rejected, (state, action) => {
      state.fetchLoading = false;
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: create testimonial
    builder.addCase(createTestimonials.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createTestimonials.fulfilled, (state, action) => {
      state.isLoading = false;
      state.testimonials.data = [action.payload.data, ...state.testimonials.data];
      state.testimonials.meta.total = state.testimonials.meta.total + 1;
      action.payload.enqueueSnackbar("Testimonial is created successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(createTestimonials.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: update testimonial
    builder.addCase(updateTestimonial.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateTestimonial.fulfilled, (state, action) => {
      state.isLoading = false;
      state.testimonials.data = state.testimonials.data.map((testimonial) => {
        if (testimonial.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return testimonial;
        }
      });
      action.payload.enqueueSnackbar("Testimonial is updated successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(updateTestimonial.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: delete testimonial
    builder.addCase(deleteTestimonial.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteTestimonial.fulfilled, (state, action) => {
      state.isLoading = false;
      state.testimonials.data = state.testimonials.data.filter((testimonial) => testimonial.id !== action.payload.data);
      action.payload.enqueueSnackbar("Testimonial is deleted successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(deleteTestimonial.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });
  },
});

export default testimonialslice.reducer;
