import { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";

// components
//
import { useDispatch } from "react-redux";
import Loading from "../common/Loading";
import Login from "../pages/auth/Login";
import { fetchRoles } from "../redux/slices/roleSlice";
import { fetchWorkTypes } from "../redux/slices/workTypeSlice";
import { useAuthContext } from "./useAuthContext";

export default function AuthGuard({ children }) {
  const dispatch = useDispatch();

  const { isAuthenticated, isInitialized, user } = useAuthContext();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  // TODO: fetch the common data here

  useEffect(() => {
    if (!(!isAuthenticated || !user?.id)) {
      dispatch(fetchWorkTypes({}));
      dispatch(fetchRoles());
    }
  }, [dispatch, isAuthenticated, user?.id]);

  // ===================

  if (!isInitialized) {
    return <Loading />;
  }

  if (!isAuthenticated || !user?.id) {
    // TODO: change this to !isAuthenticated
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Redirect to={requestedLocation} />;
  }

  return <> {children} </>;
}
