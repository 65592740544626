import React, { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import ConfirmDrag from "../../../common/modals/ConfirmDrag";
import { updateOrder } from "../../../redux/slices/orderSlice";
import { KanbanColumn } from "./KanbanPartials";

const KanbanBoard = ({ columns, setDragged }) => {
  const dispatch = useDispatch();
  const [dragData, setDragData] = useState();
  const [userId, setUserId] = useState();

  const [modal, setModal] = useState({
    confirm: false,
  });

  const isLoading = useSelector((state) => state.order.isLoading);

  const handleOnDragEnd = (result) => {
    if (result.destination && result.destination.droppableId !== result.source.droppableId) {
      setModal({ confirm: true });
      setDragData(result);
    }
  };

  const handleDrag = () => {
    if (dragData?.destination) {
      dispatch(
        updateOrder({
          id: dragData?.draggableId,
          data: {
            user_id: userId,
            status:
              dragData?.destination?.droppableId === "column-new-order"
                ? "new"
                : dragData?.destination?.droppableId === "column-invoiced"
                ? "invoiced"
                : "paid",
          },
          handleClose: () => setModal({ confirm: false }),
          setDragged,
        })
      );
    } else {
      toast.info("Please try again.");
    }
  };

  return (
    <DragDropContext
      onDragEnd={(e) => {
        setUserId(columns.columns[e.source.droppableId].patients[e.source.index].user.id);
        handleOnDragEnd(e);
      }}
    >
      <Droppable droppableId="all-columns" direction="horizontal" type="column">
        {(provided) => (
          <div
            className="kanban-container"
            id="kanban-container"
            style={{ width: `${columns.columnOrder.length * 320}px` }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {columns.columnOrder.map((columnId, index) => {
              const column = columns.columns[columnId];
              return <KanbanColumn data={columns} column={column} key={index} index={index} />;
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <Modal isOpen={modal.confirm} className="modal-dialog-centered" size="sm">
        <ConfirmDrag onDrag={handleDrag} onClose={() => setModal({ confirm: false })} isLoading={isLoading} />
      </Modal>
    </DragDropContext>
  );
};

export default KanbanBoard;
