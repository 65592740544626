import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  enquiries: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: fetch all the enquiries
export const fetchEnquiries = createAsyncThunk(
  "fetchEnquiries/enquiries",
  async ({ enqueueSnackbar, limit = 10, page = 1, search, filterTab }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`contacts`, {
        params: {
          ...(search !== "" && {
            search,
          }),
          ...(filterTab !== "" && {
            type: filterTab,
          }),
          limit,
          page,
        },
      });

      return {
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: create the enquiries
export const createEnquiry = createAsyncThunk(
  "createEnquiry/enquiries",
  async ({ enqueueSnackbar, data, handleClose }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`contacts`, data);
      return {
        data: response.data.data,
        meta: {
          total: response.data.data.length,
        },
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: update the enquiries
export const updateEnquiry = createAsyncThunk(
  "updateEnquiry/enquiries",
  async ({ enqueueSnackbar, data, handleClose, id }, thunkApi) => {
    try {
      const response = await axiosInstance.patch(`contacts/${id}`, data);
      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: delete team
export const deleteEnquiry = createAsyncThunk(
  "deleteEnquiry/enquiries",
  async ({ enqueueSnackbar, handleClose, id }, thunkApi) => {
    try {
      await axiosInstance.delete(`contacts/${id}`);
      return {
        data: id,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

const enquirieslice = createSlice({
  name: "enquiry",
  initialState,
  extraReducers: (builder) => {
    // TODO: fetch enquiries
    builder.addCase(fetchEnquiries.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchEnquiries.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.enquiries = action.payload;
    });

    builder.addCase(fetchEnquiries.rejected, (state, action) => {
      state.fetchLoading = false;
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: create enquiry
    builder.addCase(createEnquiry.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createEnquiry.fulfilled, (state, action) => {
      state.isLoading = false;
      state.enquiries.data = [action.payload.data, ...state.enquiries.data];
      state.enquiries.meta.total = state.enquiries.meta.total + 1;
      action.payload.enqueueSnackbar("Enquiry is created successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(createEnquiry.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: update enquiry
    builder.addCase(updateEnquiry.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateEnquiry.fulfilled, (state, action) => {
      state.isLoading = false;
      state.enquiries.data = state.enquiries.data.map((enquiry) => {
        if (enquiry.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return enquiry;
        }
      });
      action.payload.enqueueSnackbar("Enquiry is updated successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(updateEnquiry.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: delete enquiry
    builder.addCase(deleteEnquiry.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteEnquiry.fulfilled, (state, action) => {
      state.isLoading = false;
      state.enquiries.data = state.enquiries.data.filter((enquiry) => enquiry.id !== action.payload.data);
      action.payload.enqueueSnackbar("Enquiry is deleted successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(deleteEnquiry.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });
  },
});

export default enquirieslice.reducer;
