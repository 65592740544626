import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  categories: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: fetch all the categories
export const fetchCategories = createAsyncThunk(
  "fetchCategories/categories",
  async ({ limit = 10, page = 1 }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`category`, {
        params: {
          limit,
          page,
        },
      });

      return {
        data: response.data.data,
        meta: {
          total: response.data.data.length,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: create category
export const createCategory = createAsyncThunk("createCategory/categories", async ({ data, handleClose }, thunkApi) => {
  try {
    const response = await axiosInstance.post(`category`, data);

    return {
      data: response.data.data,
      handleClose,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: update category
export const updateCategory = createAsyncThunk(
  "updateCategory/categories",
  async ({ data, handleClose, id }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`category/${id}`, data);
      return {
        data: response.data.data,
        handleClose,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: delete category
export const deleteCategory = createAsyncThunk("deleteCategory/categories", async ({ handleClose, id }, thunkApi) => {
  try {
    await axiosInstance.delete(`category/${id}`);
    return {
      data: id,
      handleClose,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

const categorieslice = createSlice({
  name: "category",
  initialState,
  reducers: {
    resetLoadings: (state) => {
      state.isLoading = false;
      state.fetchLoading = false;
    },
  },
  extraReducers: (builder) => {
    // TODO: get category
    builder.addCase(fetchCategories.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.categories = action.payload;
      state.mainCategories = state.categories?.data?.filter?.((cat) => cat?.parent_id === null);
    });

    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      toast.error(action.payload.error.message || "ERROR");
    });

    // TODO: create category
    builder.addCase(createCategory.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories.data = [action.payload.data, ...state.categories.data];
      state.categories.meta.total = state.categories.meta.total + 1;
      toast.success("Category is created successfully.");
    });

    builder.addCase(createCategory.rejected, (state, action) => {
      state.isLoading = false;
      toast(action.payload.error.message);
    });

    // TODO: update category
    builder.addCase(updateCategory.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories.data = state.categories.data.map((category) => {
        if (category.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return category;
        }
      });
      toast.success("Category is updated successfully.");
    });

    builder.addCase(updateCategory.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });

    // TODO: delete category
    builder.addCase(deleteCategory.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories.data = state.categories.data.filter((category) => category.id !== action.payload.data);
      toast.success("Category is deleted successfully.");
    });

    builder.addCase(deleteCategory.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });
  },
});

export default categorieslice.reducer;

export const { resetLoadings } = categorieslice.actions;
