import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, Modal, Spinner, UncontrolledDropdown } from "reactstrap";
import Loading from "../../../common/Loading";
import ConfirmDelete from "../../../common/modals/ConfirmDelete";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  TooltipComponent,
  UserAvatar,
} from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { deleteUser, exportUser, fetchUsers, searchExistingUser } from "../../../redux/slices/userSlice";
import { findUpper } from "../../../utils/Utils";

const AllSpecialists = () => {
  const dispatch = useDispatch();

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState(null);

  const [statusFilter, setStatusFilter] = useState("all");
  const [workTypeFilter, setWorkTypeFilter] = useState("all");

  const [modal, setModal] = useState({
    delete: false,
  });
  const [selectedUser, setSelectedUser] = useState(false);
  const [sort, setSortState] = useState({ column: "", direction: "" });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);

  const roles = useSelector((state) => state.role.roles);
  const workTypes = useSelector((state) => state.workType.workTypes);
  const specialists = useSelector((state) => state.user.users);
  const isLoading = useSelector((state) => state.user.isLoading);
  const fetchLoading = useSelector((state) => state.user.fetchLoading);
  const exportLoading = useSelector((state) => state.user.isLoading);

  // TODO: fetch the specialists
  useEffect(() => {
    if (roles?.data?.find((role) => role?.slug === "consultant")?.id) {
      if (onSearchText === "" || onSearchText === null) {
        dispatch(
          fetchUsers({
            limit: itemPerPage,
            page: currentPage,

            params: {
              role_id: roles?.data?.find((role) => role?.slug === "consultant")?.id,
              ...(sort.column !== "" && sort.direction !== ""
                ? {
                    sort: `${sort.column}:${sort.direction}`,
                  }
                : {}),

              ...(statusFilter !== "all"
                ? {
                    status: statusFilter === "active" ? 1 : 0,
                  }
                : {}),

              ...(workTypeFilter !== "all"
                ? {
                    work_type_id: workTypeFilter,
                  }
                : {}),
            },
          })
        );
      }
    }
  }, [currentPage, dispatch, itemPerPage, onSearchText, roles?.data, sort, statusFilter, workTypeFilter]);

  useEffect(() => {
    if (onSearchText !== null) {
      dispatch(
        searchExistingUser({
          useUsers: true,
          params: {
            role_id: roles?.data?.find((role) => role?.slug === "consultant")?.id,
            ...(onSearchText !== ""
              ? {
                  phone: onSearchText,
                }
              : {}),
          },
        })
      );
    }
  }, [dispatch, onSearchText, roles?.data]);

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDelete = async () => {
    try {
      const resultAction = await dispatch(
        deleteUser({ handleClose: () => setModal({ delete: false }), id: selectedUser?.id })
      );
      const originalPromiseResult = unwrapResult(resultAction);
      originalPromiseResult.handleClose && originalPromiseResult.handleClose();
    } catch (error) {}
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [itemPerPage]);

  const handleExportSpecialists = () => {
    dispatch(
      exportUser({
        role_id: roles?.data?.find((role) => role?.slug === "consultant")?.id,
        file_name: "specialists",
      })
    );
  };

  const onSortChange = (column) => {
    setSortState((prev) => ({
      column,
      direction: prev.column === column ? (prev.direction === "asc" ? "desc" : "asc") : "asc",
    }));
  };

  return (
    <React.Fragment>
      <Head title="Specialists List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Specialists Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {specialists?.meta?.total} specialists.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button onClick={handleExportSpecialists} color={"primary"} className={"flex items-center"}>
                        <Icon
                          name={"download"}
                          style={{
                            marginRight: 3,
                          }}
                        />
                        {exportLoading ? <Spinner size="sm" color="light" /> : "Export"}
                      </Button>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Link to="/specialists/create">
                        <Button color="primary" className="btn-icon">
                          <Icon name="plus"></Icon>
                        </Button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {fetchLoading ? (
          <Loading />
        ) : (
          <Block>
            <DataTable className="card-stretch">
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group flex justify-between">
                  <div className="card-tools">
                    <div className="form-inline flex-nowrap gx-3">
                      <div className="form-wrap w-150px">
                        <select
                          className="form-select js-select2"
                          value={statusFilter}
                          onChange={(e) => setStatusFilter(e.target.value)}
                        >
                          <option value="all">All Status</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </div>

                      <div className="form-wrap w-150px">
                        <select
                          className="form-select js-select2"
                          value={workTypeFilter}
                          onChange={(e) => setWorkTypeFilter(e.target.value)}
                        >
                          <option value="all">Work Type</option>
                          {workTypes?.data?.map((w, i) => (
                            <option key={i} value={w?.id}>
                              {w?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <Button
                        onClick={() => {
                          setStatusFilter("all");
                          setWorkTypeFilter("all");
                        }}
                      >
                        RESET
                      </Button>
                    </div>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar gx-1">
                      <li>
                        <a
                          href="#search"
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggle();
                          }}
                          className="btn btn-icon search-toggle toggle-search"
                        >
                          <Icon name="search"></Icon>
                        </a>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <div className="toggle-wrap">
                          <Button
                            className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                            onClick={() => updateTableSm(true)}
                          >
                            <Icon name="menu-right"></Icon>
                          </Button>
                          <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                            <ul className="btn-toolbar gx-1">
                              <li className="toggle-close">
                                <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                  <Icon name="arrow-left"></Icon>
                                </Button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                  <div className="card-body">
                    <div className="search-content">
                      <Button
                        className="search-back btn-icon toggle-search active"
                        onClick={() => {
                          setSearchText(null);
                          toggle();
                        }}
                      >
                        <Icon name="arrow-left"></Icon>
                      </Button>
                      <input
                        type="text"
                        className="border-transparent form-focus-none form-control"
                        placeholder="Search by name or email or phone"
                        value={onSearchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                      <Button className="search-submit btn-icon">
                        <Icon name="search"></Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <DataTableBody compact>
                <DataTableHead>
                  <DataTableRow>
                    <button
                      onClick={() => onSortChange("name")}
                      className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                    >
                      <span>Full name</span>{" "}
                      <span>
                        {sort.column === "name" ? (
                          sort.direction === "asc" ? (
                            <Icon name={"sort-down-fill"} />
                          ) : (
                            <Icon name={"sort-up-fill"} />
                          )
                        ) : (
                          <Icon
                            name="sort-fill"
                            style={{
                              opacity: 0.3,
                            }}
                          />
                        )}
                      </span>
                    </button>{" "}
                  </DataTableRow>
                  <DataTableRow size="sm">
                    <button
                      onClick={() => onSortChange("email")}
                      className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                    >
                      <span>Email</span>{" "}
                      <span>
                        {sort.column === "email" ? (
                          sort.direction === "asc" ? (
                            <Icon name={"sort-down-fill"} />
                          ) : (
                            <Icon name={"sort-up-fill"} />
                          )
                        ) : (
                          <Icon
                            name="sort-fill"
                            style={{
                              opacity: 0.3,
                            }}
                          />
                        )}
                      </span>
                    </button>{" "}
                  </DataTableRow>
                  <DataTableRow size="md">
                    <button
                      onClick={() => onSortChange("phone")}
                      className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                    >
                      <span>Phone</span>{" "}
                      <span>
                        {sort.column === "phone" ? (
                          sort.direction === "asc" ? (
                            <Icon name={"sort-down-fill"} />
                          ) : (
                            <Icon name={"sort-up-fill"} />
                          )
                        ) : (
                          <Icon
                            name="sort-fill"
                            style={{
                              opacity: 0.3,
                            }}
                          />
                        )}
                      </span>
                    </button>{" "}
                  </DataTableRow>

                  <DataTableRow size="md">
                    <span className="sub-text font-bold">Work Type</span>
                  </DataTableRow>

                  <DataTableRow size="md">
                    <button
                      onClick={() => onSortChange("status")}
                      className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                    >
                      <span>Status</span>{" "}
                    </button>
                  </DataTableRow>

                  <DataTableRow className="nk-tb-col-tools text-end">
                    <span className="sub-text font-bold">Action</span>
                  </DataTableRow>
                </DataTableHead>
                {/*Head*/}
                {specialists?.data?.length > 0
                  ? specialists?.data?.map((item) => {
                      return (
                        <DataTableItem key={item.id}>
                          <DataTableRow>
                            <div className="user-card">
                              <UserAvatar
                                theme={item.avatarBg}
                                className="xs"
                                text={findUpper(item.name)}
                                image={item.image}
                              ></UserAvatar>
                              <div className="user-info">
                                <span className="tb-lead">{item?.name} </span>
                              </div>
                            </div>
                          </DataTableRow>

                          <DataTableRow size="sm">
                            <span>{item?.email}</span>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <span>{item?.phone}</span>
                          </DataTableRow>

                          <DataTableRow size="md">
                            <div className="flex gap-2">
                              {item?.works?.map((w, i) => (
                                <h3
                                  key={i}
                                  className="bg-slate-500 text-white p-2 flex justify-center items-center rounded-lg"
                                >
                                  {w?.name}
                                </h3>
                              ))}
                            </div>
                          </DataTableRow>

                          <DataTableRow size="md">
                            <button
                              onClick={() => {
                                setModal((prev) => ({ ...prev, status: true }));
                                setSelectedUser(item);
                              }}
                              className={`${item?.status ? "bg-green-500" : "bg-red-500"} p-1 rounded-lg text-white`}
                            >
                              {item?.status ? "Active" : "Suspended"}
                            </button>
                          </DataTableRow>

                          <DataTableRow className="nk-tb-col-tools">
                            <ul className="nk-tb-actions gx-1">
                              <Link to={`/specialists/edit/${item?.id}`} className="nk-tb-action-hidden">
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"edit" + item.id}
                                  icon="edit-alt-fill"
                                  direction="top"
                                  text="Edit"
                                />
                              </Link>

                              <li
                                className="nk-tb-action-hidden"
                                onClick={() => {
                                  setModal((prev) => ({ ...prev, delete: true }));
                                  setSelectedUser(item);
                                }}
                              >
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"delete" + item?.id}
                                  icon="trash-fill"
                                  direction="top"
                                  text="Delete"
                                />
                              </li>

                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <Link to={`/specialists/edit/${item?.id}`} className="p-0">
                                        <DropdownItem
                                          tag="a"
                                          href="#edit"
                                          // onClick={(ev) => {
                                          //   ev.preventDefault();
                                          // }}
                                        >
                                          <Icon name="edit"></Icon>
                                          <span>Edit</span>
                                        </DropdownItem>
                                      </Link>

                                      <li className="cursor-pointer">
                                        <DropdownItem
                                          tag="a"
                                          onClick={(ev) => {
                                            setModal((prev) => ({ ...prev, delete: true }));
                                            setSelectedUser(item);
                                          }}
                                        >
                                          <Icon name="trash"></Icon>
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
              <div className="card-inner">
                {specialists?.data?.length > 0 ? (
                  <PaginationComponent
                    itemPerPage={itemPerPage}
                    totalItems={specialists?.meta?.total}
                    paginate={paginate}
                    currentPage={currentPage}
                    setItemPerPage={setItemPerPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            </DataTable>
          </Block>
        )}
      </Content>

      <Modal isOpen={modal.delete} className="modal-dialog-centered" size="sm">
        <ConfirmDelete onDelete={handleDelete} onClose={() => setModal({ delete: false })} isLoading={isLoading} />
      </Modal>
    </React.Fragment>
  );
};
export default AllSpecialists;
