import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  resources: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: fetch all the resources
export const fetchResources = createAsyncThunk(
  "fetchResources/resources",
  async ({ enqueueSnackbar, limit, page = 1, category_id }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`pages`, {
        params: {
          page,
          limit,
          category_id,
        },
      });

      return {
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: create resource
export const createResource = createAsyncThunk(
  "createResource/resources",
  async ({ data, enqueueSnackbar, handleClose }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`pages`, data);

      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: update resource
export const updateResource = createAsyncThunk(
  "updateResource/resources",
  async ({ data, enqueueSnackbar, handleClose, id }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`pages/${id}`, data);

      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: delete resource
export const deleteResource = createAsyncThunk(
  "deleteResource/resources",
  async ({ enqueueSnackbar, handleClose, id }, thunkApi) => {
    try {
      await axiosInstance.delete(`pages/${id}`);
      return {
        data: id,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

const resourceslice = createSlice({
  name: "resource",
  initialState,
  reducers: {
    resetResource: (state) => {
      state.fetchLoading = false;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    // TODO: create resource
    builder.addCase(fetchResources.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchResources.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.resources = action.payload;
    });

    builder.addCase(fetchResources.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: create resource
    builder.addCase(createResource.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createResource.fulfilled, (state, action) => {
      state.isLoading = false;
      state.resources.data = [action.payload.data, ...state.resources.data];
      state.resources.meta.total = state.resources.meta.total + 1;
      action.payload.enqueueSnackbar("Resource is created successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(createResource.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: update resource
    builder.addCase(updateResource.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateResource.fulfilled, (state, action) => {
      state.isLoading = false;
      state.resources.data = state.resources.data.map((resource) => {
        if (resource.slug === action.payload.data.slug) {
          return action.payload.data;
        } else {
          return resource;
        }
      });
      action.payload.enqueueSnackbar("Resource is updated successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(updateResource.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: delete resource
    builder.addCase(deleteResource.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteResource.fulfilled, (state, action) => {
      state.isLoading = false;
      state.resources.data = state.resources.data.filter((resource) => resource.slug !== action.payload.data);
      action.payload.enqueueSnackbar("Resource is deleted successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(deleteResource.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });
  },
});

export default resourceslice.reducer;

export const { resetResource } = resourceslice.actions;
