import React from "react";
import Dropzone from "react-dropzone";
import { Icon } from "../../../../components/Component";
import { bytesToMegaBytes } from "../../../../utils/Utils";
import { svgSelect } from "../Data";

const Upload = ({ multiple = true, files, setFiles }) => {
  // Handles onDrop function of Dropzone
  const handleDropChange = (acceptedFiles) => {
    setFiles((prev) => (multiple ? [...acceptedFiles, ...prev] : [acceptedFiles[0]]));
  };

  const removeFromList = (name) => {
    const defaultFiles = files.filter((item) => item.name !== name);
    setFiles([...defaultFiles]);
  };

  function isUrl(url) {
    return typeof url === "string" ? true : false;
  }

  return (
    <React.Fragment>
      <div className="">
        <div className="nk-upload-form">
          <Dropzone onDrop={handleDropChange} multiple={multiple}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()} className="dropzone upload-zone small bg-lighter my-2 dz-clickable">
                  <input {...getInputProps()} />
                  <div className="dz-message">
                    <span className="dz-message-text">
                      <span>Drag and drop</span> file here or <span>browse</span>
                    </span>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <div className="nk-upload-list">
          <h6 className="title">Uploaded Files</h6>
          {files.length > 0 ? (
            files.map((file, index) => (
              <>
                {isUrl(file) ? (
                  <>
                    {file?.includes(".pdf") ? (
                      <a href={file} download target="__blank">
                        <Icon
                          name="file-pdf"
                          style={{
                            fontSize: 60,
                          }}
                        />
                      </a>
                    ) : (
                      <img src={file} alt={file} className="w-full aspect-video object-contain" />
                    )}
                  </>
                ) : (
                  <div className="nk-upload-item" key={index}>
                    <div className="nk-upload-icon">
                      {svgSelect[file?.type] ? svgSelect[file.type] : svgSelect["others"]}
                    </div>
                    <div className="nk-upload-info">
                      <div className="nk-upload-title">
                        <span className="title">{file?.name}</span>
                      </div>
                      <div className="nk-upload-size">{bytesToMegaBytes(file?.size)} MB</div>
                    </div>
                    <div className="nk-upload-action">
                      <a
                        href="#delete"
                        onClick={(ev) => {
                          ev.preventDefault();
                          removeFromList(file?.name);
                        }}
                        className="btn btn-icon btn-trigger"
                      >
                        <Icon name="trash"></Icon>
                      </a>
                    </div>
                  </div>
                )}
              </>
            ))
          ) : (
            <div className="d-flex justify-center">
              <span>No files added yet!</span>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Upload;
