import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  graphs: {
    data: [],
  },
  singleGraph: null,
};

// TODO: fetch all the graphs
export const fetchGraphs = createAsyncThunk("fetchGraphs/graphs", async ({ params }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`graph`, {
      params,
    });

    console.log(response.data);
    return {
      data: response.data,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

const graphslice = createSlice({
  name: "graph",
  initialState,
  reducers: {
    resetLoadings: (state) => {
      state.isLoading = false;
      state.fetchLoading = false;
    },
  },
  extraReducers: (builder) => {
    // TODO: get graph
    builder.addCase(fetchGraphs.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchGraphs.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.graphs = action.payload;
      state.mainGraphs = state.graphs?.data?.filter?.((cat) => cat?.parent_id === null);
    });

    builder.addCase(fetchGraphs.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      // toast.error(action.payload.error.message || "ERROR");
    });
  },
});

export default graphslice.reducer;

export const { resetLoadings } = graphslice.actions;
