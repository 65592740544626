import React from "react";
import { Card } from "reactstrap";
import { Col } from "../../components/Component";

const LibraryCard = ({ item }) => {
  return (
    <Col xxl={3} lg={4} sm={6}>
      <Card className="card-bordered product-card">
        <div className="product-thumb">
          {item?.file?.includes(".pdf") ? (
            <a href={item?.file} download target="__blank" className="card-img-top">
              <img className="card-img-top h-52 object-cover" src={"/pdfImage.png"} alt="" />
            </a>
          ) : (
            <a href={item?.file} download target="__blank" className="card-img-top">
              <img className="card-img-top h-52 object-cover" src={item?.file} alt="" />
            </a>
          )}
        </div>
        <div className="card-inner text-center">
          <ul className="product-tags">
            <li>{item?.name} </li>
          </ul>
        </div>
      </Card>
    </Col>
  );
};
export default LibraryCard;
