import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Row } from "reactstrap";
import Loading from "../../common/Loading";
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle, PaginationComponent } from "../../components/Component";
import Content from "../../layout/content/Content";
import { fetchLibrary } from "../../redux/slices/librarySlice";
import LibraryCard from "../../sections/library/LibraryCard";

const LibraryCardUI = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);

  useEffect(() => {
    dispatch(
      fetchLibrary({
        limit: 50,
        params: {
          category_id: id,
        },
      })
    );
  }, [dispatch, id]);

  const library = useSelector((state) => state.library.library);
  const fetchLoading = useSelector((state) => state.library.fetchLoading);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Content>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Libraries
            </BlockTitle>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {fetchLoading ? (
        <Loading />
      ) : (
        <Row className="g-gs">
          {library?.data?.map((c, i) => (
            <LibraryCard item={c} />
          ))}
        </Row>
      )}

      {library?.data?.length > 0 && (
        <div className="mt-3">
          <PaginationComponent
            itemPerPage={itemPerPage}
            totalItems={library?.meta?.total}
            paginate={paginate}
            currentPage={currentPage}
            setItemPerPage={setItemPerPage}
          />
        </div>
      )}
    </Content>
  );
};

export default LibraryCardUI;
