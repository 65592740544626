import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, Row, Spinner } from "reactstrap";
import Loading from "../../common/Loading";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import { QuillComponentMinimal } from "../../components/partials/rich-editor/QuillComponent";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { createTemplate, fetchTemplate, updateTemplate } from "../../redux/slices/templateSlice";

const Template = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");

  const isLoading = useSelector((state) => state.template.isLoading);
  const fetchLoading = useSelector((state) => state.template.fetchLoading);
  const template = useSelector((state) => state.template.template);

  useEffect(() => {
    dispatch(fetchTemplate({}));
  }, [dispatch]);

  useEffect(() => {
    if (template) {
      setTitle(template?.title);
      setMessage(template?.template);
    }
  }, [template]);

  const handleSubmitTemplate = () => {
    dispatch(
      template
        ? updateTemplate({
            data: {
              template: message,
              title,
            },
            id: template?.id,
          })
        : createTemplate({
            data: {
              template: message,
              title,
            },
          })
    );
  };

  return (
    <React.Fragment>
      <Head title="Email Templates"></Head>

      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Email Template
              </BlockTitle>
              <BlockDes className="text-soft">{/* <p>You have total {patients?.meta?.total} patients.</p> */}</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button className={`btn-icon btn-trigger toggle-expand me-n1`}>
                  <Icon name="menu-alt-r"></Icon>
                </Button>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {fetchLoading ? (
          <Loading />
        ) : (
          <Block>
            <Row>
              <Col md1="12">
                <div className="flex flex-col gap-4">
                  <div className="flex items-center">
                    <div className="flex flex-col w-full">
                      <span className="overline-title mb-2">Title</span>
                      <div className="form-control-wrap w-full">
                        <input
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          id="fv-title"
                          name="title"
                          placeholder="Type a title"
                          className="form-control w-full border border-gray-300 rounded-md p-2 text-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <div className="w-full">
                      <QuillComponentMinimal setMessage={setMessage} message={message} />
                    </div>
                  </div>

                  <div className="flex items-start gap-3">
                    <Button onClick={handleSubmitTemplate} color="primary" type="submit">
                      {isLoading ? <Spinner size="sm" color="light" /> : "Save"}
                    </Button>

                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      color="danger"
                      type="reset"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Block>
        )}
      </Content>
    </React.Fragment>
  );
};

export default Template;
