import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Card } from "reactstrap";
import { useAuthContext } from "../../auth/useAuthContext";
import Loading from "../../common/Loading";
import { Block, Button, Icon, PaginationComponent } from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { fetchMyOrders } from "../../redux/slices/orderSlice";

const PatientOrders = () => {
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState({ column: "", direction: "" });
  const [statusFilter, setStatusFilter] = useState("all");

  const orders = useSelector((state) => state.order.orders);
  const fetchLoading = useSelector((state) => state.order.fetchLoading);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  useEffect(() => {
    dispatch(
      fetchMyOrders({
        limit: itemPerPage,
        page: currentPage,
        params: {
          ...(user?.role?.slug === "user" ? { user_id: user?.id } : {}),
          ...(sort.column !== "" && sort.direction !== ""
            ? {
                sort: `${sort.column}:${sort.direction}`,
              }
            : {}),

          ...(statusFilter !== "all"
            ? {
                status: statusFilter,
              }
            : {}),
        },
      })
    );
  }, [currentPage, dispatch, itemPerPage, sort.column, sort.direction, statusFilter, user?.id, user?.role?.slug]);

  useEffect(() => {
    setCurrentPage(1);
  }, [itemPerPage]);

  const onSortChange = (column) => {
    setSortState((prev) => ({
      column,
      direction: prev.column === column ? (prev.direction === "asc" ? "desc" : "asc") : "asc",
    }));
  };

  return (
    <React.Fragment>
      <Head title="Completed Order List"></Head>
      <Content>
        <Block>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group justify-start">
                  <div className="card-tools">
                    <div className="form-inline flex-nowrap gx-3">
                      <div className="form-wrap">
                        <select
                          className="form-select js-select2"
                          value={statusFilter}
                          onChange={(e) => setStatusFilter(e.target.value)}
                        >
                          <option value="all">All Status</option>
                          <option value="unpaid">Pending</option>
                          <option value="paid">Paid</option>
                        </select>
                      </div>

                      <Button
                        onClick={() => {
                          setStatusFilter("all");
                        }}
                      >
                        RESET
                      </Button>
                    </div>
                  </div>
                  <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                    <div className="search-content">
                      <Button
                        className="search-back btn-icon toggle-search"
                        onClick={() => {
                          setSearchText(null);
                          toggle();
                        }}
                      >
                        <Icon name="arrow-left"></Icon>
                      </Button>
                      <input
                        type="text"
                        className="form-control border-transparent form-focus-none"
                        placeholder="Search by patient or specialist"
                        value={onSearchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                      <Button className="search-submit btn-icon">
                        <Icon name="search"></Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-inner p-0">
                <table className="table table-orders">
                  <thead className="tb-odr-head">
                    <tr className="tb-odr-item">
                      <th>
                        <button
                          onClick={() => onSortChange("code")}
                          className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                        >
                          <span>Order ID</span>

                          <span>
                            {sort.column === "code" ? (
                              sort.direction === "asc" ? (
                                <Icon name={"sort-down-fill"} />
                              ) : (
                                <Icon name={"sort-up-fill"} />
                              )
                            ) : (
                              <Icon
                                name="sort-fill"
                                style={{
                                  opacity: 0.3,
                                }}
                              />
                            )}
                          </span>
                        </button>
                      </th>

                      <th>
                        <button
                          onClick={() => onSortChange("created_at")}
                          className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                        >
                          <span>Created At</span>

                          <span>
                            {sort.column === "created_at" ? (
                              sort.direction === "asc" ? (
                                <Icon name={"sort-down-fill"} />
                              ) : (
                                <Icon name={"sort-up-fill"} />
                              )
                            ) : (
                              <Icon
                                name="sort-fill"
                                style={{
                                  opacity: 0.3,
                                }}
                              />
                            )}
                          </span>
                        </button>
                      </th>

                      <th>
                        <button
                          onClick={() => onSortChange("name")}
                          className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                        >
                          <span>Specialist</span>

                          {/* <span>
                            {sort.column === "name" ? (
                              sort.direction === "asc" ? (
                                <Icon name={"sort-down-fill"} />
                              ) : (
                                <Icon name={"sort-up-fill"} />
                              )
                            ) : (
                              <Icon
                                name="sort-fill"
                                style={{
                                  opacity: 0.3,
                                }}
                              />
                            )}
                          </span> */}
                        </button>{" "}
                      </th>

                      <th>
                        <button
                          onClick={() => onSortChange("price")}
                          className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                        >
                          <span>Amount</span>

                          <span>
                            {sort.column === "price" ? (
                              sort.direction === "asc" ? (
                                <Icon name={"sort-down-fill"} />
                              ) : (
                                <Icon name={"sort-up-fill"} />
                              )
                            ) : (
                              <Icon
                                name="sort-fill"
                                style={{
                                  opacity: 0.3,
                                }}
                              />
                            )}
                          </span>
                        </button>{" "}
                      </th>

                      <th>
                        <span>Status</span>
                      </th>

                      <th>
                        <span>Report</span>
                      </th>
                    </tr>
                  </thead>

                  {fetchLoading ? (
                    <Loading />
                  ) : (
                    <tbody className="tb-odr-body">
                      {orders?.data?.length > 0
                        ? orders?.data?.map((item) => {
                            return (
                              <tr className="tb-odr-item" key={item.id}>
                                <td className="">{item?.code}</td>

                                <td>
                                  <span>{moment(item?.created_at).format("MMM DD, YYYY")}</span>
                                </td>

                                <td>
                                  <span className={`${item?.specialist?.name ? "" : "text-red-500"}`}>
                                    {item?.specialist?.name || "Not assigned yet"}
                                  </span>
                                </td>

                                <td>
                                  <span>${parseFloat(item?.price)?.toLocaleString()}</span>
                                </td>

                                <td>
                                  <Badge color={item?.paid === 1 ? "success" : "danger"} className="badge-dot">
                                    <span>{item?.paid === 1 ? "Paid" : "Pending"}</span>
                                  </Badge>
                                </td>

                                <td>
                                  {item?.report ? (
                                    <Button color={"primary"}>
                                      <a
                                        href={item?.report}
                                        target="__blank"
                                        className="flex items-center text-white gap-1 py-2"
                                      >
                                        <Icon name={"eye"} />
                                        View
                                      </a>
                                    </Button>
                                  ) : (
                                    <Button color={"info"} disabled={true}>
                                      No report
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  )}
                </table>
              </div>
              <div className="card-inner">
                {orders?.data?.length > 0 ? (
                  <PaginationComponent
                    noDown
                    itemPerPage={itemPerPage}
                    totalItems={orders?.meta?.total}
                    paginate={paginate}
                    currentPage={currentPage}
                    setItemPerPage={setItemPerPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default PatientOrders;
