import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  services: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: fetch all the services
export const fetchServices = createAsyncThunk(
  "fetchServices/services",
  async ({ enqueueSnackbar, limit, page = 1, category_id }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`pages`, {
        params: {
          page,
          limit,
          category_id,
        },
      });

      return {
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: create service
export const createService = createAsyncThunk(
  "createService/services",
  async ({ data, enqueueSnackbar, handleClose }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`pages`, data);

      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: update service
export const updateService = createAsyncThunk(
  "updateService/services",
  async ({ data, enqueueSnackbar, handleClose, id }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`pages/${id}`, data);

      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: delete service
export const deleteService = createAsyncThunk(
  "deleteService/services",
  async ({ enqueueSnackbar, handleClose, id }, thunkApi) => {
    try {
      await axiosInstance.delete(`pages/${id}`);
      return {
        data: id,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

const serviceslice = createSlice({
  name: "service",
  initialState,
  reducers: {
    resetService: (state) => {
      state.fetchLoading = false;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    // TODO: create service
    builder.addCase(fetchServices.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchServices.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.services = action.payload;
    });

    builder.addCase(fetchServices.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: create service
    builder.addCase(createService.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createService.fulfilled, (state, action) => {
      state.isLoading = false;
      state.services.data = [action.payload.data, ...state.services.data];
      state.services.meta.total = state.services.meta.total + 1;
      action.payload.enqueueSnackbar("Service is created successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(createService.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: update service
    builder.addCase(updateService.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateService.fulfilled, (state, action) => {
      state.isLoading = false;
      state.services.data = state.services.data.map((service) => {
        if (service.slug === action.payload.data.slug) {
          return action.payload.data;
        } else {
          return service;
        }
      });
      action.payload.enqueueSnackbar("Service is updated successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(updateService.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: delete service
    builder.addCase(deleteService.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteService.fulfilled, (state, action) => {
      state.isLoading = false;
      state.services.data = state.services.data.filter((service) => service.slug !== action.payload.data);
      action.payload.enqueueSnackbar("Service is deleted successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(deleteService.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });
  },
});

export default serviceslice.reducer;

export const { resetService } = serviceslice.actions;
