import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Modal, UncontrolledTooltip } from "reactstrap";
import { useAuthContext } from "../../../auth/useAuthContext";
import ConfirmDelete from "../../../common/modals/ConfirmDelete";
import NoData from "../../../common/NoData";
import { Icon, UserAvatar } from "../../../components/Component";
import { deleteOrder } from "../../../redux/slices/orderSlice";
import CreateMessageForm from "../../../sections/conversations/CreateMessageForm";
import AssignSpecialistForm from "../../../sections/patients/AssignSpecialistForm";
import EditUnpaidOrder from "../../../sections/patients/EditUnpaidOrder";
import { findUpper } from "../../../utils/Utils";

export const KanbanCard = ({ card, index, column }) => {

  console.log(card, "cardddddd")
  const dispatch = useDispatch();
  const { user } = useAuthContext();

  const [patientModal, setPatientModal] = useState(false);
  const [conversationModal, setConversationModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();

  const togglePatientModal = () => {
    setPatientModal(!patientModal);
  };

  const toggleConversationModal = () => {
    setConversationModal(!conversationModal);
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const isLoading = useSelector((state) => state.order.isLoading);

  const handleDelete = async () => {
    try {
      const resultAction = await dispatch(
        deleteOrder({ handleClose: toggleDeleteModal, id: selectedOrder?.id, from: "new", user })
      );
      const originalPromiseResult = unwrapResult(resultAction);
      originalPromiseResult.handleClose && originalPromiseResult.handleClose();
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <Draggable draggableId={JSON.stringify(card?.id)} key={JSON.stringify(card?.id)} index={index}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="mt-2">
            <div className="kanban-item">
              <div className="flex justify-between items-center mb-2">
                <h6 className="title font-bold">{card?.code}</h6>
                {card?.specialist_id ? (
                  <UserAvatar
                    theme={card?.avatarBg}
                    className="xs"
                    text={findUpper(card?.specialist?.name || "-")}
                    image={card?.image}
                  ></UserAvatar>
                ) : null}
              </div>

              <div className="kanban-item-title">
                <h6 className="title font-bold">{card?.user?.name}</h6>
              </div>

              <h2 className="text-gray-400 mt-2">$ {card?.price || "NIL"}</h2>

              <div className="kanban-item-meta">
                <ul className="kanban-item-meta-list">
                  <li>
                    <Icon name="calendar"></Icon>
                    <span>{moment(card?.created_at).format("YYYY-MM-DD")}</span>
                  </li>
                  <li>
                    <Icon name="notes"></Icon>
                    <span>{card?.work_type?.name}</span>
                  </li>
                </ul>
              </div>

              <div className="flex justify-between items-center">
                <div className="kanban-item-meta">
                  <ul className="kanban-item-meta-list">
                    <UncontrolledTooltip
                      autohide={false}
                      placement="top"
                      target={`UncontrolledTooltipExample${card?.id}`}
                    >
                      {card?.user?.phone}
                    </UncontrolledTooltip>
                    <li id={`UncontrolledTooltipExample${card?.id}`} className="cursor-pointer">
                      <Icon name="call"></Icon>
                    </li>

                    <UncontrolledTooltip
                      autohide={false}
                      placement="top"
                      target={`UncontrolledTooltipExampleNote${card?.id}`}
                    >
                      {card?.note || "NO NOTE"}
                    </UncontrolledTooltip>
                    <li id={`UncontrolledTooltipExampleNote${card?.id}`} className="cursor-pointer">
                      <Icon name="file"></Icon>
                    </li>

                    <li
                      className="cursor-pointer"
                      onClick={() => {
                        toggleConversationModal();
                        setSelectedOrder(card);
                      }}
                    >
                      <Icon name="chat"></Icon>
                    </li>

                    {user?.role?.slug === "admin" ? (
                      <li
                        onClick={() => {
                          toggleEditModal();
                          setSelectedOrder(card);
                        }}
                        className="cursor-pointer"
                      >
                        <Icon name="edit"></Icon>
                      </li>
                    ) : null}

                    {user?.role?.slug === "admin" ? (
                      <li
                        onClick={() => {
                          toggleDeleteModal();
                          setSelectedOrder(card);
                        }}
                        className="cursor-pointer"
                      >
                        <Icon name="trash"></Icon>
                      </li>
                    ) : null}
                  </ul>
                </div>

                {card?.status === "paid" ? (
                  <>
                    <UncontrolledTooltip autohide={false} placement="top" target={`UncontrolledTooltipPlus${card?.id}`}>
                      Assign specialist and reminder stages
                    </UncontrolledTooltip>
                    <button id={`UncontrolledTooltipPlus${card?.id}`} onClick={togglePatientModal}>
                      <Icon
                        name="plus-circle-fill"
                        style={{
                          fontSize: 20,
                        }}
                      />
                    </button>
                  </>
                ) : null}
                {/* <Tooltip isOpen={tooltipOpen} toggle={toggle} target={"assign-specialist"} placement={"top"}>
                  Assign Specialist
                </Tooltip> */}
              </div>
            </div>
          </div>
        )}
      </Draggable>
      <Modal size="lg" isOpen={patientModal} fade={true} returnFocusAfterClose={true}>
        <AssignSpecialistForm onClick={togglePatientModal} data={card} />
      </Modal>

      <Modal size="lg" isOpen={conversationModal} fade={true} returnFocusAfterClose={true}>
        <CreateMessageForm order={selectedOrder} onClose={toggleConversationModal} data={card} isEdit={false} />
      </Modal>

      <Modal size="lg" isOpen={editModal} fade={true} returnFocusAfterClose={true}>
        <EditUnpaidOrder order={selectedOrder} onClose={toggleEditModal} data={card} isEdit={false} />
      </Modal>

      <Modal isOpen={deleteModal} className="modal-dialog-centered" size="sm">
        <ConfirmDelete onDelete={handleDelete} onClose={toggleDeleteModal} isLoading={isLoading} />
      </Modal>
    </React.Fragment>
  );
};

export const KanbanCardList = ({ data, setData, patients, column }) => {
  return patients?.length > 0 ? (
    patients?.map((patient, index) => {
      return <KanbanCard card={patient} data={data} key={patient?.id} index={index} column={column} />;
    })
  ) : (
    <NoData />
  );
};

export const KanbanColumn = ({ data, column, index }) => {
  return (
    <React.Fragment>
      <div className="kanban-board overflow-hidden">
        <div className={`kanban-board-header kanban-${column.theme}`}>
          <div className="kanban-title-board">
            <div className="kanban-title-content">
              <h6 className="title">{column?.text}</h6>
              <Badge color="outline-light" pill className="text-dark">
                {column?.patients?.length}
              </Badge>
            </div>
          </div>
        </div>
        <Droppable droppableId={column?.id} type="patient">
          {(provided) => (
            <div className="kanban-drag h-full" {...provided.droppableProps} ref={provided.innerRef}>
              <KanbanCardList data={data} patients={column?.patients} column={column} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </React.Fragment>
  );
};
