import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import Loading from "../../common/Loading";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  PaginationComponent,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { fetchLogs } from "../../redux/slices/logSlice";

const EmailLogs = () => {
  const dispatch = useDispatch();
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("asc");

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const logs = useSelector((state) => state.log.logs);
  const fetchLoading = useSelector((state) => state.log.fetchLoading);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  useEffect(() => {
    dispatch(
      fetchLogs({
        limit: itemPerPage,
        page: currentPage,
      })
    );
  }, [currentPage, dispatch, itemPerPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [itemPerPage]);

  return (
    <React.Fragment>
      <Head title="Log List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Email Logs</BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {logs?.meta?.total} email logs.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h5 className="title">All Email Logs</h5>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                          <Icon name="search"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <UncontrolledDropdown>
                          <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                            <Icon name="setting"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end>
                            <ul className="link-check">
                              <li>
                                <span>Order</span>
                              </li>
                              <li className={sort === "dsc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSortState("dsc");
                                  }}
                                >
                                  DESC
                                </DropdownItem>
                              </li>
                              <li className={sort === "asc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSortState("asc");
                                  }}
                                >
                                  ASC
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    </ul>
                  </div>
                  <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                    <div className="search-content">
                      <Button
                        className="search-back btn-icon toggle-search"
                        onClick={() => {
                          setSearchText("");
                          toggle();
                        }}
                      >
                        <Icon name="arrow-left"></Icon>
                      </Button>
                      <input
                        type="text"
                        className="form-control border-transparent form-focus-none"
                        placeholder="Search by Order Id"
                        value={onSearchText}
                        onChange={(e) => onFilterChange(e)}
                      />
                      <Button className="search-submit btn-icon">
                        <Icon name="search"></Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-inner p-0">
                <table className="table table-orders">
                  <thead className="tb-odr-head">
                    <tr className="tb-odr-item">
                      <th>
                        <span>User</span>
                      </th>

                      <th>
                        <span>Account</span>
                      </th>

                      <th>
                        <span>Subject</span>
                      </th>

                      <th>
                        <span>Message</span>
                      </th>

                      <th>
                        <span>Date</span>
                      </th>
                    </tr>
                  </thead>

                  {fetchLoading ? (
                    <Loading />
                  ) : (
                    <tbody className="tb-odr-body">
                      {logs?.data?.length > 0
                        ? logs?.data?.map((item) => {
                            return (
                              <tr className="tb-odr-item" key={item.id}>
                                <td>
                                  <span>{item?.user?.name}</span>
                                </td>

                                <td>
                                  <span>{item?.user?.email}</span>
                                </td>

                                <td>
                                  <span>{item?.subject}</span>
                                </td>

                                <td>
                                  <span>{item?.message}</span>
                                </td>

                                <td>
                                  <span>{moment(item?.created_at).format("MMM DD, YYYY")}</span>
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  )}
                </table>
              </div>
              <div className="card-inner">
                {logs?.data?.length > 0 ? (
                  <PaginationComponent
                    noDown
                    itemPerPage={itemPerPage}
                    totalItems={logs?.meta?.total}
                    paginate={paginate}
                    currentPage={currentPage}
                    setItemPerPage={setItemPerPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default EmailLogs;
