import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Badge, Card } from "reactstrap";
import { useAuthContext } from "../../auth/useAuthContext";
import Loading from "../../common/Loading";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  PaginationComponent,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { fetchInvoices, fetchSearchedInvoices } from "../../redux/slices/invoiceSlice";

const InvoiceList = () => {
  const { user } = useAuthContext();

  const dispatch = useDispatch();
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState({ column: "", direction: "" });
  const [statusFilter, setStatusFilter] = useState("all");

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const invoices = useSelector((state) => state.invoice.invoices);
  const fetchLoading = useSelector((state) => state.invoice.fetchLoading);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  useEffect(() => {
    dispatch(
      fetchInvoices({
        limit: itemPerPage,
        page: currentPage,
        params: {
          ...(user?.role?.slug !== "admin" ? { user_id: user?.id } : {}),

          ...(statusFilter !== "all"
            ? {
                status: statusFilter === "active" ? 1 : 0,
              }
            : {}),

          ...(sort.column !== "" && sort.direction !== ""
            ? {
                sort: `${sort.column}:${sort.direction}`,
              }
            : {}),
        },
      })
    );
  }, [currentPage, dispatch, itemPerPage, sort.column, sort.direction, statusFilter, user?.id, user?.role?.slug]);

  useEffect(() => {
    if (onSearchText !== null) {
      dispatch(
        fetchSearchedInvoices({
          useUsers: true,
          params: {
            ...(user?.role?.slug === "user" ? { user_id: user?.id } : {}),
            ...(onSearchText !== ""
              ? {
                  search: onSearchText,
                }
              : {}),
            ...(sort.column !== "" && sort.direction !== ""
              ? {
                  sort: `${sort.column}:${sort.direction}`,
                }
              : {}),
          },
        })
      );
    }
  }, [dispatch, onSearchText, sort.column, sort.direction, user?.id, user?.role?.slug]);

  useEffect(() => {
    setCurrentPage(1);
  }, [itemPerPage]);

  const onSortChange = (column) => {
    setSortState((prev) => ({
      column,
      direction: prev.column === column ? (prev.direction === "asc" ? "desc" : "asc") : "asc",
    }));
  };

  return (
    <React.Fragment>
      <Head title="Invoice List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Invoices</BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {invoices?.meta?.total} invoices.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h5 className="title">All Invoices</h5>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                          <Icon name="search"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                    </ul>
                  </div>
                  <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                    <div className="search-content">
                      <Button
                        className="search-back btn-icon toggle-search"
                        onClick={() => {
                          setSearchText("");
                          toggle();
                        }}
                      >
                        <Icon name="arrow-left"></Icon>
                      </Button>
                      <input
                        type="text"
                        className="form-control border-transparent form-focus-none"
                        placeholder="Search by patient or specialist's name, email or phone"
                        value={onSearchText}
                        onChange={(e) => onFilterChange(e)}
                      />
                      <Button className="search-submit btn-icon">
                        <Icon name="search"></Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-inner p-0">
                <table className="table table-orders">
                  <thead className="tb-odr-head">
                    <tr className="tb-odr-item">
                      <th>
                        <button
                          onClick={() => onSortChange("invoice_no")}
                          className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                        >
                          <span>Invoice ID</span>

                          <span>
                            {sort.column === "invoice_no" ? (
                              sort.direction === "asc" ? (
                                <Icon name={"sort-down-fill"} />
                              ) : (
                                <Icon name={"sort-up-fill"} />
                              )
                            ) : (
                              <Icon
                                name="sort-fill"
                                style={{
                                  opacity: 0.3,
                                }}
                              />
                            )}
                          </span>
                        </button>
                      </th>

                      <th>
                        <button
                          onClick={() => onSortChange("created_at")}
                          className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                        >
                          <span>Created At</span>

                          <span>
                            {sort.column === "created_at" ? (
                              sort.direction === "asc" ? (
                                <Icon name={"sort-down-fill"} />
                              ) : (
                                <Icon name={"sort-up-fill"} />
                              )
                            ) : (
                              <Icon
                                name="sort-fill"
                                style={{
                                  opacity: 0.3,
                                }}
                              />
                            )}
                          </span>
                        </button>{" "}
                      </th>

                      <th>
                        <button
                          onClick={() => onSortChange("code")}
                          className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                        >
                          <span>Order ID</span>

                          <span>
                            {sort.column === "code" ? (
                              sort.direction === "asc" ? (
                                <Icon name={"sort-down-fill"} />
                              ) : (
                                <Icon name={"sort-up-fill"} />
                              )
                            ) : (
                              <Icon
                                name="sort-fill"
                                style={{
                                  opacity: 0.3,
                                }}
                              />
                            )}
                          </span>
                        </button>
                      </th>

                      <th>
                        <button
                          onClick={() => onSortChange("name")}
                          className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                        >
                          <span>Patient</span>

                          <span>
                            {sort.column === "name" ? (
                              sort.direction === "asc" ? (
                                <Icon name={"sort-down-fill"} />
                              ) : (
                                <Icon name={"sort-up-fill"} />
                              )
                            ) : (
                              <Icon
                                name="sort-fill"
                                style={{
                                  opacity: 0.3,
                                }}
                              />
                            )}
                          </span>
                        </button>{" "}
                      </th>

                      <th>
                        <button className="sub-text font-bold cursor-pointer w-full text-start flex justify-between">
                          <span>Specialist</span>
                        </button>{" "}
                      </th>

                      <th>
                        <button
                          onClick={() => onSortChange("price")}
                          className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                        >
                          <span>Amount</span>

                          <span>
                            {sort.column === "price" ? (
                              sort.direction === "asc" ? (
                                <Icon name={"sort-down-fill"} />
                              ) : (
                                <Icon name={"sort-up-fill"} />
                              )
                            ) : (
                              <Icon
                                name="sort-fill"
                                style={{
                                  opacity: 0.3,
                                }}
                              />
                            )}
                          </span>
                        </button>{" "}
                      </th>

                      <th>
                        <span>Status</span>
                      </th>

                      <th>&nbsp;</th>
                    </tr>
                  </thead>

                  {fetchLoading ? (
                    <Loading />
                  ) : (
                    <tbody className="tb-odr-body">
                      {invoices?.data?.length > 0
                        ? invoices?.data?.map((item) => {
                            return (
                              <tr className="tb-odr-item" key={item.id}>
                                <td className="underline">
                                  <Link to={`${process.env.PUBLIC_URL}/invoice-details/${item.id}`}>
                                    {item?.invoice_no}
                                  </Link>
                                </td>

                                <td>
                                  <span>{moment(item?.invoiced_date).format("MMM DD, YYYY")}</span>
                                </td>

                                <td>
                                  <span>{item?.code}</span>
                                </td>

                                <td>
                                  <span>{item?.user?.name}</span>
                                </td>

                                <td>
                                  <span className={`${item?.specialist?.name ? "" : "text-red-500"}`}>
                                    {item?.specialist?.name || "Not assigned yet"}
                                  </span>
                                </td>

                                <td>
                                  <span>${parseFloat(item?.price)?.toLocaleString()}</span>
                                </td>

                                <td>
                                  <Badge color={item?.paid === 1 ? "success" : "danger"} className="badge-dot">
                                    <span>{item?.paid === 1 ? "Paid" : "Pending"}</span>
                                  </Badge>
                                </td>

                                <td className="">
                                  <div className="tb-odr-btns d-none d-sm-inline">
                                    <Link to={`${process.env.PUBLIC_URL}/invoice-print/${item.id}`} target="_blank">
                                      <Button color="primary" size="sm" className="btn-icon btn-white btn-dim">
                                        <Icon name="printer-fill"></Icon>
                                      </Button>
                                    </Link>
                                    <Link to={`${process.env.PUBLIC_URL}/invoice-details/${item.id}`}>
                                      <Button color="primary" size="sm" className="btn btn-dim">
                                        View
                                      </Button>
                                    </Link>
                                  </div>
                                  <Link to={`${process.env.PUBLIC_URL}/invoice-details/${item.id}`}>
                                    <Button className="btn-pd-auto d-sm-none">
                                      <Icon name="chevron-right"></Icon>
                                    </Button>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  )}
                </table>
              </div>
              <div className="card-inner">
                {invoices?.data?.length > 0 ? (
                  <PaginationComponent
                    noDown
                    itemPerPage={itemPerPage}
                    totalItems={invoices?.meta?.total}
                    paginate={paginate}
                    currentPage={currentPage}
                    setItemPerPage={setItemPerPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default InvoiceList;
