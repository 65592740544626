import React from "react";
import { Button, Col, Spinner } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle } from "../../components/Component";

const ConfirmDelete = ({ onDelete, onClose, isLoading }) => {
  return (
    <div className="p-4">
      <Block size="lg">
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle className={"text-xl"} page>
              Are you sure, you want to delete?
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Col md="12">
          <div className="flex gap-3 items-center">
            <Button disabled={isLoading} color="primary" type="submit" onClick={onDelete}>
              {isLoading ? <Spinner size="sm" color="light" /> : "Yes"}
            </Button>

            <Button disabled={isLoading} color={"danger"} type="reset" onClick={onClose}>
              No
            </Button>
          </div>
        </Col>
      </Block>
    </div>
  );
};

export default ConfirmDelete;
