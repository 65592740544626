import React from "react";
import { useSelector } from "react-redux";
import { CardTitle } from "reactstrap";
import { TooltipComponent } from "../../../Component";
import { BarChart } from "../../charts/default/Charts";

const SalesGraph = () => {
  const graphs = useSelector((state) => state.graph.graphs);

  return (
    <React.Fragment>
      {" "}
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h6 className="title">Total Sales</h6>
          <p>In last 6 months sales data.</p>
        </CardTitle>
        <div className="card-tools">
          <TooltipComponent icon="help-fill" iconClass="card-hint" direction="left" id="Tooltip-3" text="Total Sales" />
        </div>
      </div>
      <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
        <div className="nk-sale-data">
          <span className="amount">
            $
            {new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
              parseFloat(graphs?.data?.totalSales)
            )}
          </span>
        </div>

        <div className="nk-sales-ck">
          <BarChart data={graphs?.data?.sales} label="Sales" />
        </div>
      </div>
    </React.Fragment>
  );
};
export default SalesGraph;
