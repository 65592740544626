import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  newOrders: {
    data: [],
    meta: {
      total: 0,
    },
  },

  paidOrders: {
    data: [],
    meta: {
      total: 0,
    },
  },

  invoicedOrders: {
    data: [],
    meta: {
      total: 0,
    },
  },
  isLoading: false,
  fetchLoading: false,
  isPhoneSearchLoading: false,
  orders: {
    data: [],
    meta: {
      total: 0,
    },
  },
  existingOrder: null,
};

// TODO: create new order
export const createNewOrder = createAsyncThunk(
  "createNewOrder/orders",
  async ({ data, history, specialist, note }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`order`, data);

      // TODO: update the specialist

      if (specialist || note) {
        thunkApi.dispatch(
          updateOrder({
            id: response.data.data.id,
            data: {
              user_id: response.data.data.user_id,
              ...(specialist ? { specialist_id: parseInt(specialist), status: "assigned" } : {}),
              ...(note ? { note: note } : {}),
            },
            fetchOrdersAgain: false,
          })
        );
      }

      return {
        data: response.data,
        history,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: fetch all orders
export const fetchOrders = createAsyncThunk(
  "fetchOrders/orders",
  async ({ params, limit = 10, page = 1, status }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`order`, {
        params: {
          ...params,
          limit,
          page,
        },
      });
      return {
        status: params.status || status,
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: fetch my orders
export const fetchMyOrders = createAsyncThunk(
  "fetchMyOrders/orders",
  async ({ params, limit = 10, page = 1 }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`order`, {
        params: {
          ...params,
          limit,
          page,
        },
      });
      return {
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: fetch all search orders
export const fetchSearchedOrders = createAsyncThunk(
  "fetchSearchedOrders/orders",
  async ({ params, limit = 10, page = 1, status }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`order`, {
        params: {
          ...params,
          limit,
          page,
        },
      });
      return {
        status: params.status || status,
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: update order
export const updateOrder = createAsyncThunk(
  "updateOrder/orders",
  async ({ id, data, handleClose, fetchOrdersAgain = false, setDragged, status, limit, page, params }, thunkApi) => {
    try {
      const response = await axiosInstance.patch(`order/${id}`, data);
      setDragged && setDragged((prev) => !prev);
      handleClose && handleClose();

      if (fetchOrdersAgain) {
        thunkApi.dispatch(
          fetchOrders({
            params,
            limit,
            page,
          })
        );
      }

      return {
        status,
        data: response.data.data,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: delete order
export const deleteOrder = createAsyncThunk(
  "deleteOrder/orders",
  async ({ id, from, handleClose, user, specialists, selectedSpecialist, defaultSpecialist }, thunkApi) => {
    try {
      await axiosInstance.delete(`order/${id}`);

      if (from === "new") {
        thunkApi.dispatch(
          fetchOrders({
            params: {
              hasInvoice: false,
              ...(user?.role?.slug === "user" ? { user_id: user?.id } : {}),
            },
            limit: 100,
            page: 0,
          })
        );

        thunkApi.dispatch(
          fetchOrders({
            params: {
              status: "invoiced",
              ...(user?.role?.slug === "user" ? { user_id: user?.id } : {}),
            },
            limit: 100,
            page: 0,
          })
        );

        thunkApi.dispatch(
          fetchOrders({
            params: {
              status: "paid",
              ...(user?.role?.slug === "user" ? { user_id: user?.id } : {}),
            },
            limit: 100,
            page: 0,
          })
        );
      }

      if (from === "reminder") {
        thunkApi.dispatch(
          fetchReminderOrders({
            limit: 400,
            page: 1,
            params: {
              ...(user?.role?.slug === "user" ? { user_id: user?.id } : {}),
              ...(selectedSpecialist !== "all"
                ? {
                    specialist_id: selectedSpecialist || defaultSpecialist || specialists?.data?.[0]?.id,
                  }
                : {}),
            },
          })
        );
      }
      return {
        handleClose,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: create new reminder
export const createNewReminder = createAsyncThunk(
  "createNewReminder/orders",
  async ({ reminderData, updateOrderData, history }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`set-reminder`, {
        ...reminderData,
      });

      // TODO: update the specialist

      thunkApi.dispatch(
        updateOrder({
          id: reminderData.order_id,
          data: {
            ...updateOrderData,
          },
          fetchOrdersAgain: false,
        })
      );

      return {
        data: response.data,
        history,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: fetch all reminder orders
export const fetchReminderOrders = createAsyncThunk(
  "fetchReminderOrders/orders",
  async ({ params, limit = 200, page = 1 }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`get-reminder`, {
        params: {
          ...params,
          limit,
          page,
        },
      });
      return {
        data: response.data.data,
        meta: {
          total: response.data.length,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: post the file in order
export const postFile = createAsyncThunk("postFile/orders", async ({ data, id, onClose }, thunkApi) => {
  try {
    const response = await axiosInstance.post(`order/upload/${id}`, data);
    onClose && onClose();
    return response.data.data;
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

const orderlice = createSlice({
  name: "orders",
  initialState,
  extraReducers: (builder) => {
    // TODO: create new order
    builder.addCase(createNewOrder.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createNewOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success("New order has been created.");
    });

    builder.addCase(createNewOrder.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });

    // TODO: fetch orders
    builder.addCase(fetchOrders.pending, (state, action) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.fetchLoading = false;

      if (action.payload.status === "new") {
        state.newOrders = action.payload;
      } else if (action.payload.status === "invoiced") {
        state.invoicedOrders = action.payload;
      } else if (action.payload.status === "paid") {
        state.paidOrders = action.payload;
      } else {
        state.orders = action.payload;
      }
    });

    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.fetchLoading = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });

    // TODO: fetch my orders
    builder.addCase(fetchMyOrders.pending, (state, action) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchMyOrders.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.orders = action.payload;
    });

    builder.addCase(fetchMyOrders.rejected, (state, action) => {
      state.fetchLoading = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });

    // TODO: fetch serached orders

    builder.addCase(fetchSearchedOrders.fulfilled, (state, action) => {
      state.orders = action.payload;
    });

    builder.addCase(fetchSearchedOrders.rejected, (state, action) => {
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });

    // TODO: update orders
    builder.addCase(updateOrder.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateOrder.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload.status === "new" || action.payload.status === "assigned") {
        state.newOrders.data = state.newOrders.data.map((o) => {
          if (o.id === action.payload.data.id) {
            return action.payload.data;
          } else {
            return o;
          }
        });
      } else if (action.payload.status === "invoiced") {
        state.invoicedOrders.data = state.invoicedOrders.data.map((o) => {
          if (o.id === action.payload.data.id) {
            return action.payload.data;
          } else {
            return o;
          }
        });
      } else if (action.payload.status === "paid") {
        state.paidOrders.data = state.paidOrders.data.map((o) => {
          if (o.id === action.payload.data.id) {
            return action.payload.data;
          } else {
            return o;
          }
        });
      } else {
        state.orders.data = state.orders.data.map((o) => {
          if (o.id === action.payload.data.id) {
            return action.payload;
          } else {
            return o;
          }
        });
      }
    });

    builder.addCase(updateOrder.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });

    // TODO: create new reminder
    builder.addCase(createNewReminder.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createNewReminder.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success("New reminder has been created.");
    });

    builder.addCase(createNewReminder.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });

    // TODO: fetch reminder orders
    builder.addCase(fetchReminderOrders.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchReminderOrders.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.orders = action.payload;
    });

    builder.addCase(fetchReminderOrders.rejected, (state, action) => {
      state.fetchLoading = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });

    // TODO: post file
    builder.addCase(postFile.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(postFile.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success("File is uploaded successfully.");
      state.orders.data = state.orders.data.map((o) => {
        if (o.id === action.payload.id) {
          return action.payload;
        } else {
          return o;
        }
      });
    });

    builder.addCase(postFile.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });
  },
});

export default orderlice.reducer;
