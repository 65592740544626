const menu = {
  admin: [
    {
      heading: "Dashboard",
    },

    {
      icon: "dashboard",
      text: "Dashboard",
      link: "/",
    },

    {
      heading: "Orders",
    },

    {
      icon: "user-add-fill",
      text: "Add New Order",
      link: "/create-order",
    },

    {
      icon: "list-round",
      text: "New Orders List",
      link: "/orders-list",
    },

    {
      icon: "list-round",
      text: "Orders",
      link: "/completed-orders-list",
    },

    {
      heading: "Reminders",
    },

    {
      icon: "clock-fill",
      text: "Reminders",
      link: "/reminders",
    },

    {
      heading: "Users Management",
    },

    {
      icon: "users-fill",
      text: "Patients",
      link: "/patients",
    },

    {
      icon: "user-alt-fill",
      text: "Specialists",
      subMenu: [
        {
          icon: "list-round",
          text: "List",
          link: "/specialists/list",
        },

        {
          icon: "plus-round-fill",
          text: "Create",
          link: "/specialists/create",
        },
      ],
    },

    {
      icon: "user-alt-fill",
      text: "Admins",
      subMenu: [
        {
          icon: "list-round",
          text: "List",
          link: "/admins/list",
        },

        {
          icon: "plus-round-fill",
          text: "Create",
          link: "/admins/create",
        },
      ],
    },

    {
      heading: "Others",
    },

    {
      icon: "bell-fill",
      text: "Conversations",
      link: "/conversations",
    },

    {
      icon: "sign-php-alt",
      text: "Invoices",
      link: "/invoices",
    },

    {
      icon: "file-fill",
      text: "Libraries",
      subMenu: [
        {
          icon: "list-round",
          text: "List",
          link: "/libraries/list",
        },

        {
          icon: "plus-round-fill",
          text: "Create",
          link: "/libraries/create",
        },

        {
          icon: "grid-add-fill-c",
          text: "Categories",
          link: "/categories",
        },
      ],
    },

    {
      icon: "mail-fill",
      text: "Email Logs",
      link: "/email-logs",
    },

    {
      heading: "Settings",
    },

    {
      icon: "expand",
      text: "Work Types",
      link: "/work-types",
    },
    {
      icon: "clock-fill",
      text: "Stages",
      link: "/stages",
    },

    {
      icon: "template-fill",
      text: "Email templates",
      link: "/email-template",
    },
  ],

  consultant: [
    {
      heading: "Dashboard",
    },

    {
      icon: "dashboard",
      text: "Dashboard",
      link: "/",
    },

    {
      heading: "Orders",
    },

    {
      icon: "user-add-fill",
      text: "Add New Order",
      link: "/create-order",
    },

    {
      icon: "list-round",
      text: "New Orders List",
      link: "/orders-list",
    },

    {
      icon: "list-round",
      text: "Orders",
      link: "/completed-orders-list",
    },

    {
      heading: "Reminders",
    },

    {
      icon: "clock-fill",
      text: "Reminders",
      link: "/reminders",
    },

    {
      heading: "Others",
    },

    {
      icon: "bell-fill",
      text: "Conversations",
      link: "/conversations",
    },

    {
      icon: "file-fill",
      text: "Libraries",
      subMenu: [
        {
          icon: "list-round",
          text: "List",
          link: "/libraries/list",
        },

        {
          icon: "plus-round-fill",
          text: "Create",
          link: "/libraries/create",
        },

        {
          icon: "grid-add-fill-c",
          text: "Categories",
          link: "/categories",
        },
      ],
    },
  ],

  user: [
    {
      heading: "Dashboard",
    },

    {
      icon: "dashboard",
      text: "Dashboard",
      link: "/",
    },

    {
      heading: "Orders",
    },

    {
      icon: "list-round",
      text: "My Orders",
      link: "/my-orders-list",
    },

    {
      heading: "Others",
    },

    {
      icon: "bell-fill",
      text: "Conversations",
      link: "/conversations",
    },

    {
      icon: "sign-php-alt",
      text: "Invoices",
      link: "/invoices",
    },

    {
      icon: "file-fill",
      text: "Libraries",
      subMenu: [
        {
          icon: "list-round",
          text: "List",
          link: "/libraries/list",
        },
      ],
    },
  ],

  reception: [
    {
      heading: "Dashboard",
    },

    {
      icon: "dashboard",
      text: "Dashboard",
      link: "/",
    },

    {
      heading: "Orders",
    },

    {
      icon: "user-add-fill",
      text: "Add New Order",
      link: "/create-order",
    },

    {
      icon: "list-round",
      text: "New Orders List",
      link: "/orders-list",
    },

    {
      icon: "list-round",
      text: "Orders",
      link: "/completed-orders-list",
    },

    {
      heading: "Reminders",
    },

    {
      icon: "clock-fill",
      text: "Reminders",
      link: "/reminders",
    },

    {
      heading: "Users Management",
    },

    {
      icon: "users-fill",
      text: "Patients",
      link: "/patients",
    },

    {
      icon: "user-alt-fill",
      text: "Specialists",
      subMenu: [
        {
          icon: "list-round",
          text: "List",
          link: "/specialists/list",
        },

        {
          icon: "plus-round-fill",
          text: "Create",
          link: "/specialists/create",
        },
      ],
    },

    {
      icon: "user-alt-fill",
      text: "Admins",
      subMenu: [
        {
          icon: "list-round",
          text: "List",
          link: "/admins/list",
        },

        {
          icon: "plus-round-fill",
          text: "Create",
          link: "/admins/create",
        },
      ],
    },

    {
      heading: "Others",
    },

    {
      icon: "bell-fill",
      text: "Conversations",
      link: "/conversations",
    },

    {
      icon: "sign-php-alt",
      text: "Invoices",
      link: "/invoices",
    },

    {
      icon: "file-fill",
      text: "Libraries",
      subMenu: [
        {
          icon: "list-round",
          text: "List",
          link: "/libraries/list",
        },

        {
          icon: "plus-round-fill",
          text: "Create",
          link: "/libraries/create",
        },

        {
          icon: "grid-add-fill-c",
          text: "Categories",
          link: "/categories",
        },
      ],
    },

    {
      icon: "mail-fill",
      text: "Email Logs",
      link: "/email-logs",
    },

    {
      heading: "Settings",
    },

    {
      icon: "expand",
      text: "Work Types",
      link: "/work-types",
    },
    {
      icon: "clock-fill",
      text: "Stages",
      link: "/stages",
    },

    {
      icon: "template-fill",
      text: "Email templates",
      link: "/email-template",
    },
  ],
};
export default menu;
