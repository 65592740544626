import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// components
//
import Loading from "../common/Loading";
import { useAuthContext } from "./useAuthContext";

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuthContext();

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (!isInitialized) {
    return <Loading />;
  }

  return <> {children} </>;
}
