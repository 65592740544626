import classNames from "classnames";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Label, Row, Spinner } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import Content from "../../layout/content/Content";
import { updateOrder } from "../../redux/slices/orderSlice";

const EditUnpaidOrder = ({ order, onClose }) => {
  const dispatch = useDispatch();

  const { errors, register, handleSubmit, setValue, reset } = useForm();

  const workTypes = useSelector((state) => state.workType.workTypes);
  const isLoading = useSelector((state) => state.order.isLoading);

  useEffect(() => {
    if (order?.id) {
      setValue("price", order?.price);
      setValue("work_type_id", order?.work_type_id);
    }
  }, [order?.id, order?.price, order?.work_type_id, setValue]);

  const onFormSubmit = async (data) => {
    try {
      dispatch(
        updateOrder({
          data: {
            price: data.price,
            work_type_id: data.work_type_id,
            user_id: order?.user?.id,
          },
          id: order?.id,
          handleClose: onClose,
          status: order?.status,
        })
      );
    } catch (error) {}
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  return (
    <React.Fragment>
      <Content>
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Edit Order - {order?.code}
              </BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <Form onSubmit={handleSubmit(onFormSubmit)} className={formClass}>
            <Row className={"mt-3"}>
              <Col md="6">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-work_type_id">
                    Work Type *
                  </Label>
                  <div className="form-control-wrap">
                    <select
                      ref={register({ required: true })}
                      className="form-control form-select"
                      id="fv-work_type_id"
                      name="work_type_id"
                      // disabled={!isNewOrder}
                    >
                      <option label="Select a work type" value=""></option>
                      {workTypes?.data?.map((item, index) => (
                        <option key={index} value={item?.id}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    {errors.work_type_id && errors.work_type_id.type === "required" && (
                      <span className="invalid">This is required</span>
                    )}
                  </div>
                </div>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-cost">
                    Cost
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      ref={register({ required: true })}
                      type="number"
                      id="fv-cost"
                      name="price"
                      className="form-control"
                    />
                    {errors.cost && errors.cost.type === "required" && (
                      <span className="invalid">This is required</span>
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <Col md="12" className="mt-5">
              <Button disabled={isLoading} color="primary" type="submit">
                {isLoading ? <Spinner size="sm" color="light" /> : "Update"}
              </Button>
            </Col>
          </Form>
        </Block>

        <button className="absolute top-5 right-5 cursor-pointer" type="reset" onClick={onClose}>
          <Icon
            name={"cross"}
            style={{
              fontSize: 20,
            }}
          />
        </button>
      </Content>
    </React.Fragment>
  );
};

export default EditUnpaidOrder;
