import React from "react";
import { useSelector } from "react-redux";
import { CardTitle } from "reactstrap";
import { TooltipComponent } from "../../../Component";
import { BarChart } from "../../charts/default/Charts";

const PatientGraph = () => {
  const graphs = useSelector((state) => state.graph.graphs);

  return (
    <React.Fragment>
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h6 className="title">Total Patients</h6>
          <p>In last 6 months patients data.</p>
        </CardTitle>
        <div className="card-tools">
          <TooltipComponent
            icon="help-fill"
            iconClass="card-hint"
            direction="left"
            id="Tooltip-2"
            text="Total active patients"
          />
        </div>
      </div>
      <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
        <div className="nk-sale-data">
          <span className="amount">{graphs?.data?.totalPatients}</span>
          {/* <span className="sub-title">
            <span className="change up text-success">
              <Icon name="arrow-long-up" />
              1.93%
            </span>
            since last month
          </span> */}
        </div>
        <div className="nk-sales-ck">
          <BarChart data={graphs?.data?.patients} label="Patients" />
        </div>
      </div>
    </React.Fragment>
  );
};
export default PatientGraph;
