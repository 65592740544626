import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { useAuthContext } from "../../auth/useAuthContext";
import Loading from "../../common/Loading";
import {
  Block,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  TooltipComponent,
  UserAvatar,
} from "../../components/Component";
import { fetchConversations } from "../../redux/slices/conversationSlice";
import { getMySpecialists } from "../../redux/slices/userSlice";
import { findUpper } from "../../utils/Utils";
import CreateMessageForm from "./CreateMessageForm";
import ViewConversationModal from "./ViewConversationModal";

const ConversationView = () => {
  const { user } = useAuthContext();

  const dispatch = useDispatch();
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(50);
  const [conversationModal, setConversationModal] = useState(false);
  const [showConversationModal, setShowConversationModal] = useState(false);
  const [newConversationModal, setNewConversationModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState();
  const [selectedSpecialist, setSelectedSpecialist] = useState();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropDown = () => setDropdownOpen((prevState) => !prevState);

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const fetchLoading = useSelector((state) => state.conversation.fetchLoading);
  const isLoading = useSelector((state) => state.user.isLoading);
  const conversations = useSelector((state) => state.conversation.conversations);
  const mySpecialists = useSelector((state) => state.user.mySpecialists);

  const toggleConversationModal = () => {
    setConversationModal(!conversationModal);
  };

  const toggleNewConversationModal = () => {
    setNewConversationModal(!newConversationModal);
  };

  const toggleShowConversationModal = () => {
    setShowConversationModal(!showConversationModal);
  };

  // TODO: get the messages of that selected user
  useEffect(() => {
    // if (selectedUser?.id) {
    dispatch(
      fetchConversations({
        limit: itemPerPage,
        page: currentPage,
        params: {
          ...(user?.role?.slug !== "admin" ? { user_id: user?.id } : {}),
        },
      })
    );
    // }
  }, [currentPage, dispatch, itemPerPage, user?.id, user?.role?.slug]);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    setCurrentPage(1);
  }, [itemPerPage]);

  const getSpecialists = () => {
    dispatch(getMySpecialists());
  };

  return (
    <div className="flex flex-col h-screen">
      {fetchLoading ? (
        <Loading />
      ) : (
        <>
          <Block>
            <DataTable className="card-stretch">
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group">
                  <div className="card-tools"></div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar gx-1">
                      {/* <li>
                        <a
                          href="#search"
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggle();
                          }}
                          className="btn btn-icon search-toggle toggle-search"
                        >
                          <Icon name="search"></Icon>
                        </a>
                      </li>
                      <li className="btn-toolbar-sep"></li> */}
                      {user?.role?.slug === "user" && (
                        <li>
                          <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
                            <DropdownToggle className="btn-action" color="primary" onClick={getSpecialists}>
                              <span>Talk with specialist</span>{" "}
                            </DropdownToggle>
                            <DropdownMenu>
                              <ul className="link-list-opt">
                                {isLoading ? (
                                  <div className="flex justify-center items-center my-10">
                                    <Spinner size="sm" color="light" />
                                  </div>
                                ) : mySpecialists?.length > 0 ? (
                                  <>
                                    {mySpecialists?.map((spec, index) => (
                                      <li key={index}>
                                        <DropdownItem
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setSelectedSpecialist(spec);
                                            setNewConversationModal(true);
                                          }}
                                        >
                                          <span>
                                            {spec?.name}({spec?.code})
                                          </span>
                                        </DropdownItem>
                                      </li>
                                    ))}
                                  </>
                                ) : (
                                  <DropdownItem>
                                    <div className="flex justify-center">No Specialists</div>
                                  </DropdownItem>
                                )}
                              </ul>
                            </DropdownMenu>
                          </Dropdown>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                  <div className="card-body">
                    <div className="search-content">
                      <Button
                        className="search-back btn-icon toggle-search active"
                        onClick={() => {
                          setSearchText("");
                          toggle();
                        }}
                      >
                        <Icon name="arrow-left"></Icon>
                      </Button>
                      <input
                        type="text"
                        className="border-transparent form-focus-none form-control"
                        placeholder="Search by user or email"
                        value={onSearchText}
                      />
                      <Button className="search-submit btn-icon">
                        <Icon name="search"></Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <DataTableBody compact>
                <DataTableHead>
                  <DataTableRow>
                    <span className="sub-text font-bold">Sender</span>
                  </DataTableRow>
                  <DataTableRow size="sm" className={"w-1/2"}>
                    <span className="sub-text font-bold">Message</span>
                  </DataTableRow>

                  <DataTableRow size="sm">
                    <span className="sub-text font-bold">Order</span>
                  </DataTableRow>

                  <DataTableRow size="sm">
                    <span className="sub-text font-bold">Replies</span>
                  </DataTableRow>

                  <DataTableRow className="nk-tb-col-tools text-end">
                    <span className="sub-text font-bold">Action</span>
                  </DataTableRow>
                </DataTableHead>
                {/*Head*/}
                {conversations?.data?.length > 0
                  ? conversations?.data?.map((item) => {
                      return (
                        <DataTableItem key={item.id}>
                          <DataTableRow size="sm" className={"underline cursor-pointer"}>
                            <div
                              className="user-card"
                              onClick={() => {
                                setSelectedMessage(item);
                                toggleShowConversationModal();
                              }}
                            >
                              <UserAvatar
                                theme={item?.user?.avatarBg}
                                className="xs"
                                text={findUpper(item?.user?.name)}
                                image={item?.user?.image}
                              ></UserAvatar>
                              <div className="user-info">
                                <span className="tb-lead text-nowrap">{item?.user?.name}</span>
                              </div>
                            </div>
                          </DataTableRow>

                          <DataTableRow size="sm">
                            <span dangerouslySetInnerHTML={{ __html: item?.message }} />
                          </DataTableRow>

                          <DataTableRow size="sm">
                            <span>{item?.order?.code}</span>
                          </DataTableRow>

                          <DataTableRow size="sm">
                            <span>{item?.children_count}</span>
                          </DataTableRow>

                          {/* <DataTableRow size="sm">
                            <span className="tb-lead">4</span>
                          </DataTableRow> */}

                          <DataTableRow className="nk-tb-col-tools">
                            <ul className="nk-tb-actions gx-1">
                              <li
                                onClick={() => {
                                  toggleConversationModal();
                                  setSelectedMessage(item);
                                }}
                                className="nk-tb-action-hidden"
                              >
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon scale-x-[-1]"
                                  id={"edit" + item.id}
                                  icon="reply-fill"
                                  direction="top"
                                  text="Reply"
                                />
                              </li>

                              <li
                                onClick={() => {
                                  toggleShowConversationModal();
                                  setSelectedMessage(item);
                                }}
                                className="nk-tb-action-hidden"
                              >
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon scale-x-[-1]"
                                  id={"view" + item.id}
                                  icon="eye-fill"
                                  direction="top"
                                  text="View"
                                />
                              </li>

                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li className="p-0">
                                        <DropdownItem
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            toggleConversationModal();
                                            setSelectedMessage(item);
                                          }}
                                        >
                                          <Icon className={"scale-x-[-1]"} name="reply-fill"></Icon>
                                          <span>Reply</span>
                                        </DropdownItem>

                                        <DropdownItem
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            toggleShowConversationModal();
                                            setSelectedMessage(item);
                                          }}
                                        >
                                          <Icon className={"scale-x-[-1]"} name="eye-fill"></Icon>
                                          <span>View</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
              <div className="card-inner">
                {conversations?.data?.length > 0 ? (
                  <PaginationComponent
                    itemPerPage={itemPerPage}
                    totalItems={conversations?.meta?.total}
                    paginate={paginate}
                    currentPage={currentPage}
                    setItemPerPage={setItemPerPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            </DataTable>
          </Block>
        </>
      )}

      <Modal size="lg" isOpen={conversationModal} fade={true} returnFocusAfterClose={true}>
        <CreateMessageForm message={selectedMessage} onClose={toggleConversationModal} isEdit={true} />
      </Modal>

      <Modal size="lg" isOpen={newConversationModal} fade={true} returnFocusAfterClose={true}>
        <CreateMessageForm
          message={{
            user: {
              name: selectedSpecialist?.name,
            },
            order_id: selectedSpecialist?.order_id,
            order: {
              code: selectedSpecialist?.code,
            },
          }}
          onClose={toggleNewConversationModal}
          isEdit={false}
        />
      </Modal>

      <Modal size="lg" isOpen={showConversationModal} fade={true} returnFocusAfterClose={true}>
        <ViewConversationModal message={selectedMessage} onClose={toggleShowConversationModal} isEdit={false} />
      </Modal>
    </div>
  );
};

export default ConversationView;
