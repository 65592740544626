import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownItem, DropdownMenu, DropdownToggle, Modal, UncontrolledDropdown } from "reactstrap";
import Loading from "../../common/Loading";
import ConfirmDelete from "../../common/modals/ConfirmDelete";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  TooltipComponent,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { deleteCategory, fetchCategories } from "../../redux/slices/categorySlice";
import CreateNewCategory from "./CreateCategories";

const Categories = () => {
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.category.categories);
  const isLoading = useSelector((state) => state.category.isLoading);
  const fetchLoading = useSelector((state) => state.category.fetchLoading);

  const [sm, updateSm] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
    delete: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

  // TODO: fetch the category``

  useEffect(() => {
    dispatch(
      fetchCategories({
        limit: itemPerPage,
        page: currentPage,
      })
    );
  }, [currentPage, dispatch, itemPerPage]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDelete = async () => {
    try {
      const resultAction = await dispatch(
        deleteCategory({ handleClose: () => setModal({ delete: false }), id: selectedCategory?.id })
      );
      const originalPromiseResult = unwrapResult(resultAction);
      originalPromiseResult.handleClose && originalPromiseResult.handleClose();
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <Head title="Category List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Category Lists
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button
                        color="primary"
                        className="btn-icon"
                        onClick={() => setModal((prev) => ({ ...prev, add: true }))}
                      >
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {fetchLoading ? (
          <Loading />
        ) : (
          <Block>
            <DataTable className="card-stretch">
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group justify-end">
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar gx-1">
                      <li>
                        <Button
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggle();
                          }}
                          className="btn btn-icon search-toggle toggle-search"
                        >
                          <Icon name="search"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <div className="toggle-wrap">
                          <Button
                            className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                            onClick={() => updateTableSm(true)}
                          >
                            <Icon name="menu-right"></Icon>
                          </Button>
                          <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                            <ul className="btn-toolbar gx-1">
                              <li className="toggle-close">
                                <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                  <Icon name="arrow-left"></Icon>
                                </Button>
                              </li>

                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                    <Icon name="setting"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end className="dropdown-menu-xs">
                                    <ul className="link-check">
                                      <li>
                                        <span>Show</span>
                                      </li>
                                      <li className={itemPerPage === 10 ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setItemPerPage(10);
                                          }}
                                        >
                                          10
                                        </DropdownItem>
                                      </li>
                                      <li className={itemPerPage === 15 ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setItemPerPage(15);
                                          }}
                                        >
                                          15
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                    <ul className="link-check">
                                      <li>
                                        <span>Order</span>
                                      </li>
                                      <li className={sort === "dsc" ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setSortState("dsc");
                                          }}
                                        >
                                          DESC
                                        </DropdownItem>
                                      </li>
                                      <li className={sort === "asc" ? "active" : ""}>
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setSortState("asc");
                                          }}
                                        >
                                          ASC
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                  <div className="card-body">
                    <div className="search-content">
                      <Button
                        className="search-back btn-icon toggle-search active"
                        onClick={() => {
                          setSearchText("");
                          toggle();
                        }}
                      >
                        <Icon name="arrow-left"></Icon>
                      </Button>
                      <input
                        type="text"
                        className="border-transparent form-focus-none form-control"
                        placeholder="Search by user or email"
                        value={onSearchText}
                        onChange={(e) => onFilterChange(e)}
                      />
                      <Button className="search-submit btn-icon">
                        <Icon name="search"></Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <DataTableBody compact>
                <DataTableHead>
                  <DataTableRow>
                    <span className="sub-text font-bold">Image</span>
                  </DataTableRow>

                  <DataTableRow>
                    <span className="sub-text font-bold">Title</span>
                  </DataTableRow>

                  <DataTableRow className="nk-tb-col-tools text-end">
                    <span className="sub-text font-bold">Action</span>
                  </DataTableRow>
                </DataTableHead>
                {/*Head*/}
                {categories?.data?.length > 0
                  ? categories?.data?.map((item) => {
                      return (
                        <DataTableItem key={item?.id}>
                          <DataTableRow>
                            <div className="user-card">
                              <div className="user-info">
                                <img className="!w-32 aspect-video object-contain" src={item?.image} alt="" />
                              </div>
                            </div>
                          </DataTableRow>

                          <DataTableRow>
                            <div className="user-card">
                              <div className="user-info">
                                <span className="tb-lead">{item?.name}</span>
                              </div>
                            </div>
                          </DataTableRow>
                          <DataTableRow className="nk-tb-col-tools">
                            <ul className="nk-tb-actions gx-1">
                              <li
                                className="nk-tb-action-hidden"
                                onClick={() => {
                                  setModal((prev) => ({ ...prev, edit: true }));
                                  setSelectedCategory(item);
                                }}
                              >
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"edit" + item?.id}
                                  icon="edit-alt-fill"
                                  direction="top"
                                  text="Edit"
                                />
                              </li>

                              <li
                                className="nk-tb-action-hidden"
                                onClick={() => {
                                  setModal((prev) => ({ ...prev, delete: true }));
                                  setSelectedCategory(item);
                                }}
                              >
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"delete" + item?.id}
                                  icon="trash-fill"
                                  direction="top"
                                  text="Delete"
                                />
                              </li>

                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li onClick={() => {}}>
                                        <DropdownItem
                                          tag="a"
                                          onClick={(ev) => {
                                            setModal((prev) => ({ ...prev, edit: true }));
                                            setSelectedCategory(item);
                                          }}
                                        >
                                          <Icon name="edit"></Icon>
                                          <span>Edit</span>
                                        </DropdownItem>
                                      </li>

                                      <li onClick={() => {}}>
                                        <DropdownItem
                                          tag="a"
                                          onClick={(ev) => {
                                            setModal((prev) => ({ ...prev, delete: true }));
                                            setSelectedCategory(item);
                                          }}
                                        >
                                          <Icon name="trash"></Icon>
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
              <div className="card-inner">
                {categories?.data?.length > 0 ? (
                  <PaginationComponent
                    itemPerPage={itemPerPage}
                    totalItems={categories?.meta.total}
                    paginate={paginate}
                    currentPage={currentPage}
                    setItemPerPage={setItemPerPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            </DataTable>
          </Block>
        )}

        <Modal isOpen={modal.add} className="modal-dialog-centered" size="lg">
          <CreateNewCategory onClose={() => setModal({ add: false })} />
        </Modal>

        <Modal isOpen={modal.edit} className="modal-dialog-centered" size="lg">
          <CreateNewCategory onClose={() => setModal({ edit: false })} isEdit={true} editData={selectedCategory} />
        </Modal>

        <Modal isOpen={modal.delete} className="modal-dialog-centered" size="sm">
          <ConfirmDelete onDelete={handleDelete} onClose={() => setModal({ delete: false })} isLoading={isLoading} />
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default Categories;
