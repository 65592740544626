import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
import { downloadFile } from "../../utils/download";
import { createNewOrder, updateOrder } from "./orderSlice";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  fetchLoadingInPaidOrder: false,
  isPhoneSearchLoading: false,
  users: {
    data: [],
    meta: {
      total: 0,
    },
  },

  filteredUsers: {
    data: [],
    meta: {
      total: 0,
    },
  },

  existingUser: null,
  singleUser: null,
  mySpecialists: [],
};

// TODO: create new user
export const createNewUser = createAsyncThunk(
  "createNewUser/users",
  async (
    { data, work_type_id, price, newOrder = true, work_type, history, isAdmin = false, note, specialist },
    thunkApi
  ) => {
    try {
      const response = await axiosInstance.post(`register`, data);

      if (!isAdmin) {
        if (newOrder) {
          const r = await thunkApi.dispatch(
            createNewOrder({
              data: {
                user_id: response.data.data.id,
                work_type_id,
                price,
              },
              specialist,
              note,
            })
          );

          console.log(r, "rrrr");

          if (specialist || note) {
            thunkApi.dispatch(
              updateOrder({
                id: r.payload.data.data.id,
                data: {
                  user_id: r.payload.data.data.user_id,
                  ...(specialist ? { specialist_id: parseInt(specialist), status: "assigned" } : {}),
                  ...(note ? { note: note } : {}),
                },
                fetchOrdersAgain: false,
              })
            );
          }
        } else {
          thunkApi.dispatch(
            updateUser({
              data: {
                work_type: work_type, //for specialist
              },
              id: response.data.data.id,
            })
          );
        }
      }

      return {
        data: response.data,
        history,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: update user
export const updateUser = createAsyncThunk(
  "updateUser/users",
  async ({ data, id, handleClose, updateAuthUser }, thunkApi) => {
    try {
      const response = await axiosInstance.patch(`update-user/${id}`, data);
      if (updateAuthUser) {
        updateAuthUser(response.data.data);
      }
      return {
        data: response.data.data,
        handleClose,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: fetch user
export const fetchUsers = createAsyncThunk(
  "fetchUsers/users",
  async ({ limit = 10, page = 1, params, filter = false }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`users`, {
        params: {
          ...params,
          limit,
          page,
        },
      });
      return {
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
        filter,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: fetch user in edit paid order
export const fetchUsersInPaidOrders = createAsyncThunk(
  "fetchUsersInPaidOrders/users",
  async ({ limit = 10, page = 1, params, filter = false }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`users`, {
        params: {
          ...params,
          limit,
          page,
        },
      });
      return {
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
        filter,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: single user
export const fetchSingleUser = createAsyncThunk("fetchSingleUser/users", async ({ id }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`users/${id}`);
    return response.data.data;
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: search user by phone number
export const searchExistingUser = createAsyncThunk(
  "searchExistingUser/users",
  async ({ params, useUsers = false }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`users`, {
        params,
      });

      return {
        data: response.data.data.data.length ? response.data.data : false,
        useUsers,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: delete user
export const deleteUser = createAsyncThunk("deleteUser/users", async ({ handleClose, id }, thunkApi) => {
  try {
    await axiosInstance.delete(`users/${id}`);
    return {
      data: id,
      handleClose,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: export user
export const exportUser = createAsyncThunk("exportUser/users", async ({ role_id, file_name }, thunkApi) => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const response = await axios.get(`${process.env.REACT_APP_BASEURL}/export?role_id=${role_id}`, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    downloadFile(response.data, file_name);

    return;
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: get my specialists
export const getMySpecialists = createAsyncThunk("getMySpecialists/users", async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get("get-specialists");

    return response.data.data;
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

const userlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetExistingUser: (state) => {
      state.existingUser = null;
    },
  },
  extraReducers: (builder) => {
    // TODO: create new user
    builder.addCase(createNewUser.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createNewUser.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createNewUser.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(JSON.stringify(action.payload.error.message));
    });

    // TODO: search existing user by phone number
    builder.addCase(searchExistingUser.pending, (state, _) => {
      state.isPhoneSearchLoading = true;
    });

    builder.addCase(searchExistingUser.fulfilled, (state, action) => {
      state.isPhoneSearchLoading = false;

      if (action.payload.useUsers) {
        state.users = {
          data: action.payload.data.data,
          meta: {
            total: action.payload.data.total,
          },
        };
      } else {
        state.existingUser = action.payload.data.data;
      }
    });

    builder.addCase(searchExistingUser.rejected, (state, action) => {
      state.isPhoneSearchLoading = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });

    // TODO:update user
    builder.addCase(updateUser.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users.data = state.users.data.map((user) => {
        if (user?.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return user;
        }
      });
      toast.success("Success.");
    });

    builder.addCase(updateUser.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });

    // TODO:fetch user
    builder.addCase(fetchUsers.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.fetchLoading = false;
      if (action.payload.filter) {
        state.filteredUsers = action.payload;
      } else {
        state.users = action.payload;
      }
    });

    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.fetchLoading = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });

    // TODO:fetch user in paid order
    builder.addCase(fetchUsersInPaidOrders.pending, (state, _) => {
      state.fetchLoadingInPaidOrder = true;
    });

    builder.addCase(fetchUsersInPaidOrders.fulfilled, (state, action) => {
      state.fetchLoadingInPaidOrder = false;
      if (action.payload.filter) {
        state.filteredUsers = action.payload;
      } else {
        state.users = action.payload;
      }
    });

    builder.addCase(fetchUsersInPaidOrders.rejected, (state, action) => {
      state.fetchLoadingInPaidOrder = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });

    // TODO:fetch single user
    builder.addCase(fetchSingleUser.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchSingleUser.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.singleUser = action.payload;
    });

    builder.addCase(fetchSingleUser.rejected, (state, action) => {
      state.fetchLoading = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });

    // TODO: delete user
    builder.addCase(deleteUser.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users.data = state.users.data.filter((user) => user.id !== action.payload.data);
      toast.success("User is deleted successfully.");
    });

    builder.addCase(deleteUser.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });

    // TODO: export user
    builder.addCase(exportUser.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(exportUser.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(exportUser.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });

    // TODO: get my specialists
    builder.addCase(getMySpecialists.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(getMySpecialists.fulfilled, (state, action) => {
      state.isLoading = false;
      state.mySpecialists = action.payload;
    });

    builder.addCase(getMySpecialists.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });
  },
});

export default userlice.reducer;

export const { resetExistingUser } = userlice.actions;
