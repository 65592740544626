import { combineReducers } from "redux";
// slices
import authSlice from "./slices/authSlice";
import bannerSlice from "./slices/bannerSlice";
import blogSlice from "./slices/blogSlice";
import categorySlice from "./slices/categorySlice";
import conversationSlice from "./slices/conversationSlice";
import enquirySlice from "./slices/enquirySlice";
import faqSlice from "./slices/faqSlice";
import graphSlice from "./slices/graphSlice";
import invoiceSlice from "./slices/invoiceSlice";
import librarySlice from "./slices/librarySlice";
import logSlice from "./slices/logSlice";
import orderSlice from "./slices/orderSlice";
import practiceAreaSlice from "./slices/practiceAreaSlice";
import reminderSlice from "./slices/reminderSlice";
import resourceSlice from "./slices/resourceSlice";
import roleSlice from "./slices/roleSlice";
import serviceSlice from "./slices/serviceSlice";
import teamSlice from "./slices/teamSlice";
import templateSlice from "./slices/templateSlice";
import testimonialSlice from "./slices/testimonialSlice";
import userSlice from "./slices/userSlice";
import workTypeSlice from "./slices/workTypeSlice";

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  auth: authSlice,
  order: orderSlice,
  user: userSlice,
  team: teamSlice,
  faq: faqSlice,
  workType: workTypeSlice,
  blog: blogSlice,
  testimonial: testimonialSlice,
  enquiry: enquirySlice,
  banner: bannerSlice,
  practiceArea: practiceAreaSlice,
  service: serviceSlice,
  resource: resourceSlice,
  role: roleSlice,
  reminder: reminderSlice,
  library: librarySlice,
  conversation: conversationSlice,
  invoice: invoiceSlice,
  category: categorySlice,
  template: templateSlice,
  log: logSlice,
  graph: graphSlice,
});

export default rootReducer;
