import { unwrapResult } from "@reduxjs/toolkit";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Label, Row, Spinner } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard } from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { createWorkType, updateWorkType } from "../../redux/slices/workTypeSlice";

const CreateNewWorkType = ({ onClose, isEdit = false, editData }) => {
  const dispatch = useDispatch();

  const { errors, register, handleSubmit, setValue } = useForm();

  const isLoading = useSelector((state) => state.workType.isLoading);

  const onFormSubmit = async (data) => {
    try {
      const resultAction = await dispatch(
        isEdit
          ? updateWorkType({
              data,
              handleClose: onClose,
              id: editData?.id,
            })
          : createWorkType({
              data,
              handleClose: onClose,
            })
      );

      const originalPromiseResult = unwrapResult(resultAction);
      originalPromiseResult.handleClose && originalPromiseResult.handleClose();
    } catch (error) {}
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  useEffect(() => {
    if (editData && isEdit) {
      setValue("name", editData?.name);
    }
  }, [editData, isEdit, setValue]);

  return (
    <React.Fragment>
      <Head title={`${isEdit ? "Edit" : "Create"} Work Type`} />
      <Content>
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {isEdit ? `Edit Work Type (${editData?.name})` : "Create Work Type"}
              </BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <Form onSubmit={handleSubmit(onFormSubmit)} className={formClass}>
              <Row className="g-gs">
                {/* Patient Form */}
                <Col md="12">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-name">
                      Name
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        ref={register({ required: true })}
                        type="text"
                        id="fv-name"
                        name="name"
                        className="form-control"
                      />
                      {errors.title && <span className="invalid">This field is required</span>}
                    </div>
                  </div>
                </Col>

                <Col md="12">
                  <div className="flex gap-3 items-center">
                    <Button disabled={isLoading} color="primary" type="submit">
                      {isLoading ? <Spinner size="sm" color="light" /> : "Submit"}
                    </Button>

                    <Button disabled={isLoading} color={"danger"} type="reset" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default CreateNewWorkType;
