import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  template: null,
};

// TODO: fetch all the template
export const fetchTemplate = createAsyncThunk("fetchTemplate/template", async ({ limit = 10, page = 1 }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`email-template`);

    return response.data.data;
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: create template
export const createTemplate = createAsyncThunk("createTemplate/template", async ({ data }, thunkApi) => {
  try {
    const response = await axiosInstance.post(`email-template`, data);

    return {
      data: response.data.data,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: update template
export const updateTemplate = createAsyncThunk("updateTemplate/template", async ({ data, id }, thunkApi) => {
  try {
    const response = await axiosInstance.patch(`email-template/${id}`, data);

    return response.data.data;
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

const templatelice = createSlice({
  name: "template",
  initialState,
  reducers: {
    resetLoadings: (state) => {
      state.isLoading = false;
      state.fetchLoading = false;
    },
  },
  extraReducers: (builder) => {
    // TODO: get template
    builder.addCase(fetchTemplate.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchTemplate.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.template = action.payload;
      state.mainTemplate = state.template?.data?.filter?.((cat) => cat?.parent_id === null);
    });

    builder.addCase(fetchTemplate.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      toast.error(action.payload.error.message || "ERROR");
    });

    // TODO: create template
    builder.addCase(createTemplate.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createTemplate.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success("Template is created successfully.");
    });

    builder.addCase(createTemplate.rejected, (state, action) => {
      state.isLoading = false;
      toast(action.payload.error.message);
    });

    // TODO: update template
    builder.addCase(updateTemplate.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateTemplate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.template = action.payload;
      toast.success("Template is updated successfully.");
    });

    builder.addCase(updateTemplate.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });
  },
});

export default templatelice.reducer;

export const { resetLoadings } = templatelice.actions;
