import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Modal } from "reactstrap";
import { useAuthContext } from "../../auth/useAuthContext";
import Loading from "../../common/Loading";
import ConfirmChangeStatus from "../../common/modals/ConfirmChangeStatus";
import { Block, Button, Icon, PaginationComponent } from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { fetchOrders, fetchSearchedOrders, updateOrder } from "../../redux/slices/orderSlice";
import UploadFile from "../../sections/patients/UploadFile";

const CompletedOrders = () => {
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState({ column: "", direction: "" });
  const [selectedTab, setSelectedTab] = useState("all");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modal, setModal] = useState({
    upload: false,
    complete: false,
  });
  const tabData = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Completed",
      value: "completed",
    },
  ];

  const orders = useSelector((state) => state.order.orders);
  const fetchLoading = useSelector((state) => state.order.fetchLoading);
  const isLoading = useSelector((state) => state.order.isLoading);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  useEffect(() => {
    if (onSearchText === "" || onSearchText === null) {
      dispatch(
        fetchOrders({
          limit: itemPerPage,
          page: currentPage,
          params: {
            ...(selectedTab !== "all"
              ? {
                  status: selectedTab,
                }
              : {}),
            ...(user?.role?.slug === "user" ? { user_id: user?.id } : {}),
            ...(sort.column !== "" && sort.direction !== ""
              ? {
                  sort: `${sort.column}:${sort.direction}`,
                }
              : {}),
          },
        })
      );
    }
  }, [
    currentPage,
    dispatch,
    itemPerPage,
    onSearchText,
    selectedTab,
    sort.column,
    sort.direction,
    user?.id,
    user?.role?.slug,
  ]);

  useEffect(() => {
    if (onSearchText !== null) {
      dispatch(
        fetchSearchedOrders({
          useUsers: true,
          params: {
            ...(user?.role?.slug === "user" ? { user_id: user?.id } : {}),
            ...(onSearchText !== ""
              ? {
                  search: onSearchText,
                }
              : {}),
            ...(sort.column !== "" && sort.direction !== ""
              ? {
                  sort: `${sort.column}:${sort.direction}`,
                }
              : {}),

            ...(selectedTab !== "all"
              ? {
                  status: selectedTab,
                }
              : {}),
          },
        })
      );
    }
  }, [dispatch, onSearchText, selectedTab, sort.column, sort.direction, user?.id, user?.role?.slug]);

  useEffect(() => {
    setCurrentPage(1);
  }, [itemPerPage]);

  const handleChangeStatus = async () => {
    try {
      const resultAction = await dispatch(
        updateOrder({
          fetchOrdersAgain: true,
          limit: itemPerPage,
          page: currentPage,
          params: {
            status: selectedTab,
          },
          handleClose: () => setModal({ complete: false }),
          id: selectedOrder?.id,
          data: {
            user_id: selectedOrder?.user_id,
            status: "completed",
          },
        })
      );
      const originalPromiseResult = unwrapResult(resultAction);
      originalPromiseResult.handleClose && originalPromiseResult.handleClose();
    } catch (error) {}
  };

  const onSortChange = (column) => {
    setSortState((prev) => ({
      column,
      direction: prev.column === column ? (prev.direction === "asc" ? "desc" : "asc") : "asc",
    }));
  };

  return (
    <React.Fragment>
      <Head title="Completed Order List"></Head>
      <Content>
        <div className="flex mb-5 border w-fit rounded">
          {tabData.map((t, i) => (
            <button
              onClick={() => setSelectedTab(t.value)}
              key={i}
              className={`${t.value === selectedTab ? "bg-[#6576FF] text-white" : ""} p-2 py-1 h-fit duration-300`}
            >
              {t.label}
            </button>
          ))}
        </div>

        <Block>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group justify-end">
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                          <Icon name="search"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                    </ul>
                  </div>
                  <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                    <div className="search-content">
                      <Button
                        className="search-back btn-icon toggle-search"
                        onClick={() => {
                          setSearchText(null);
                          toggle();
                        }}
                      >
                        <Icon name="arrow-left"></Icon>
                      </Button>
                      <input
                        type="text"
                        className="form-control border-transparent form-focus-none"
                        placeholder="Search by patient or specialist's name, email or phone"
                        value={onSearchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                      <Button className="search-submit btn-icon">
                        <Icon name="search"></Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-inner p-0">
                <table className="table table-orders">
                  <thead className="tb-odr-head">
                    <tr className="tb-odr-item">
                      <th>
                        <button
                          onClick={() => onSortChange("code")}
                          className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                        >
                          <span>Order ID</span>

                          <span>
                            {sort.column === "code" ? (
                              sort.direction === "asc" ? (
                                <Icon name={"sort-down-fill"} />
                              ) : (
                                <Icon name={"sort-up-fill"} />
                              )
                            ) : (
                              <Icon
                                name="sort-fill"
                                style={{
                                  opacity: 0.3,
                                }}
                              />
                            )}
                          </span>
                        </button>
                      </th>
                      <th>
                        <button
                          onClick={() => onSortChange("name")}
                          className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                        >
                          <span>Patient</span>

                          <span>
                            {sort.column === "name" ? (
                              sort.direction === "asc" ? (
                                <Icon name={"sort-down-fill"} />
                              ) : (
                                <Icon name={"sort-up-fill"} />
                              )
                            ) : (
                              <Icon
                                name="sort-fill"
                                style={{
                                  opacity: 0.3,
                                }}
                              />
                            )}
                          </span>
                        </button>{" "}
                      </th>

                      <th>
                        <button className="sub-text font-bold cursor-pointer w-full text-start flex justify-between">
                          <span>Specialist</span>
                        </button>{" "}
                      </th>
                      <th>
                        <button
                          onClick={() => onSortChange("price")}
                          className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                        >
                          <span>Amount</span>

                          <span>
                            {sort.column === "price" ? (
                              sort.direction === "asc" ? (
                                <Icon name={"sort-down-fill"} />
                              ) : (
                                <Icon name={"sort-up-fill"} />
                              )
                            ) : (
                              <Icon
                                name="sort-fill"
                                style={{
                                  opacity: 0.3,
                                }}
                              />
                            )}
                          </span>
                        </button>{" "}
                      </th>
                      <th>
                        <button
                          onClick={() => onSortChange("created_at")}
                          className="sub-text font-bold cursor-pointer w-full text-start flex justify-between"
                        >
                          <span>Created At</span>

                          <span>
                            {sort.column === "created_at" ? (
                              sort.direction === "asc" ? (
                                <Icon name={"sort-down-fill"} />
                              ) : (
                                <Icon name={"sort-up-fill"} />
                              )
                            ) : (
                              <Icon
                                name="sort-fill"
                                style={{
                                  opacity: 0.3,
                                }}
                              />
                            )}
                          </span>
                        </button>
                      </th>

                      {selectedTab === "all" ? (
                        <>
                          {user?.role?.slug === "admin" || user?.role?.slug === "consultant" ? (
                            <th>
                              <span>Upload</span>
                            </th>
                          ) : null}
                        </>
                      ) : null}

                      <th>
                        <span>Report</span>
                      </th>

                      {selectedTab === "all" ? (
                        <>
                          {user?.role?.slug === "admin" || user?.role?.slug === "consultant" ? (
                            <th>
                              <span>Mark as complete</span>
                            </th>
                          ) : null}
                        </>
                      ) : null}
                    </tr>
                  </thead>

                  {fetchLoading ? (
                    <Loading />
                  ) : (
                    <tbody className="tb-odr-body">
                      {orders?.data?.length > 0
                        ? orders?.data?.map((item) => {
                            return (
                              <tr className="tb-odr-item" key={item.id}>
                                <td className="">{item?.code}</td>

                                <td>
                                  <span>{item?.user?.name}</span>
                                </td>

                                <td>
                                  <span className={`${item?.specialist?.name ? "" : "text-red-500"}`}>
                                    {item?.specialist?.name || "Not assigned yet"}
                                  </span>{" "}
                                </td>

                                <td>
                                  <span>${parseFloat(item?.price)?.toLocaleString()}</span>
                                </td>

                                <td>
                                  <span>{moment(item?.created_at).format("MMM DD, YYYY")}</span>
                                </td>

                                {selectedTab === "all" ? (
                                  <>
                                    {user?.role?.slug === "admin" || user?.role?.slug === "consultant" ? (
                                      <td>
                                        <Button
                                          onClick={() => {
                                            setModal({ upload: true });
                                            setSelectedOrder(item);
                                          }}
                                        >
                                          <Icon
                                            style={{
                                              fontSize: 20,
                                            }}
                                            name={"upload"}
                                          />
                                        </Button>
                                      </td>
                                    ) : null}
                                  </>
                                ) : null}

                                <td>
                                  {item?.report ? (
                                    <Button color={"primary"}>
                                      <a
                                        href={item?.report}
                                        target="__blank"
                                        className="flex items-center text-white gap-1 py-2"
                                      >
                                        <Icon name={"eye"} />
                                        View
                                      </a>
                                    </Button>
                                  ) : (
                                    <Button color={"info"} disabled={true}>
                                      No report
                                    </Button>
                                  )}
                                </td>

                                {selectedTab === "all" ? (
                                  <>
                                    {user?.role?.slug === "admin" || user?.role?.slug === "consultant" ? (
                                      <td>
                                        <Button
                                          onClick={() => {
                                            setModal({ complete: true });
                                            setSelectedOrder(item);
                                          }}
                                        >
                                          <Icon
                                            style={{
                                              fontSize: 20,
                                            }}
                                            name={"done"}
                                          />
                                        </Button>
                                      </td>
                                    ) : null}
                                  </>
                                ) : null}
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  )}
                </table>
              </div>
              <div className="card-inner">
                {orders?.data?.length > 0 ? (
                  <PaginationComponent
                    noDown
                    itemPerPage={itemPerPage}
                    totalItems={orders?.meta?.total}
                    paginate={paginate}
                    currentPage={currentPage}
                    setItemPerPage={setItemPerPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </Block>
      </Content>

      <Modal isOpen={modal.upload} className="modal-dialog-centered" size="lg">
        <UploadFile onClose={() => setModal({ upload: false })} order={selectedOrder} />
      </Modal>

      <Modal isOpen={modal.complete} className="modal-dialog-centered" size="sm">
        <ConfirmChangeStatus
          onChange={handleChangeStatus}
          onClose={() => setModal({ complete: false })}
          isLoading={isLoading}
        />
      </Modal>
    </React.Fragment>
  );
};
export default CompletedOrders;
