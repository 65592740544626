import { unwrapResult } from "@reduxjs/toolkit";
import classNames from "classnames";
import moment from "moment";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, Form, Row, Spinner } from "reactstrap";
import { useAuthContext } from "../../auth/useAuthContext";
import NoData from "../../common/NoData";
import { BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import { createConversation, fetchRepliesConversations } from "../../redux/slices/conversationSlice";

const CreateMessageForm = ({ onClose, order, message, isEdit = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useAuthContext();

  const { register, handleSubmit, errors } = useForm({});

  const isLoading = useSelector((state) => state.conversation.isLoading);
  const fetchRepliesLoading = useSelector((state) => state.conversation.fetchRepliesLoading);
  const replies = useSelector((state) => state.conversation.replies);

  const handleSendMessage = async (values) => {
    // if (!message || message === "<p><br></p>") {
    //   toast.info("Message is empty");
    // } else {
    try {
      const resultAction = await dispatch(
        isEdit && message?.id
          ? createConversation({
              data: {
                message: values.message,
                user_id: user?.id,
                order_id: order?.id || message?.order_id,
                parent_id: message?.id,
              },
              history,
              handleClose: () => onClose(),
              refetch: true,
              ...(user?.role?.slug !== "admin"
                ? {
                    user_id: user?.id,
                  }
                : {}),
            })
          : createConversation({
              data: {
                message: values.message,
                user_id: user?.id,
                order_id: order?.id || message?.order_id,
              },
              refetch: true,
              history,
              handleClose: () => onClose(),
            })
      );

      const originalPromiseResult = unwrapResult(resultAction);
      originalPromiseResult.handleClose && originalPromiseResult.handleClose();
      originalPromiseResult.history && originalPromiseResult.history.push("/conversations");
    } catch (error) {}
  };
  //   };

  //   useEffect(() => {
  //     if (message && isEdit) {
  //       setValue("message", message?.message);
  //     }
  //   }, [isEdit, message, setValue]);

  //   TODO: fetch the replies

  useEffect(() => {
    if (isEdit && message?.id) {
      dispatch(
        fetchRepliesConversations({
          limit: 100,
          params: {
            parent_id: message?.id,
          },
        })
      );
    }
  }, [dispatch, isEdit, message]);

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  return (
    <div className="relative p-4">
      <div size="lg">
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Message to {order?.user?.name || message?.user?.name}(<span className="text-sm font-normal"> regarding </span>
              {order?.code || message?.order?.code})
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>
        <div>
          {/* TODO: show replies */}
          {isEdit && message?.id ? (
            <>
              {fetchRepliesLoading ? (
                <Spinner />
              ) : (
                <div className="max-h-[50vh] overflow-y-auto">
                  {replies?.data?.length ? (
                    <div className="mb-3">
                      {[...replies?.data]?.map((r, i) => (
                        <div className="flex flex-col mb-3">
                          <div key={i} className="flex items-start gap-3 border p-2 py-1 rounded-md h-fit">
                            <h2 className="font-bold text-nowrap">{r?.user?.name} says :</h2>

                            <div className="w-full">
                              <h2>{r?.message}</h2>
                              <div className="flex w-full justify-end">
                                <span className="text-[0.55rem] text-end text-gray-400">
                                  {moment(r?.created_at).format("MMM D, YYYY | hh:mm a")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <NoData message="No replies" />
                  )}
                </div>
              )}
            </>
          ) : null}
          <Form onSubmit={handleSubmit(handleSendMessage)} className={formClass}>
            <Row className="g-gs">
              <Col md="12">
                <div className="form-control-wrap">
                  <textarea
                    ref={register({ required: true })}
                    type="text"
                    id="fv-message"
                    name="message"
                    className="form-control"
                    placeholder={isEdit ? "Type reply" : "Type message"}
                  />
                  {errors.message && <span className="invalid">This field is required</span>}
                </div>
              </Col>

              {/* Save and Cancel Buttons */}
              <Col md="12">
                <div className="flex gap-3 items-center justify-end">
                  <Button disabled={isLoading} color="primary" type="submit">
                    {isLoading ? <Spinner size="sm" color="light" /> : `${isEdit ? "Reply" : "Send"}`}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      <button className="absolute top-5 right-5 cursor-pointer" disabled={isLoading} type="reset" onClick={onClose}>
        <Icon
          name={"cross"}
          style={{
            fontSize: 20,
          }}
        />
      </button>
    </div>
  );
};

export default CreateMessageForm;
