import React from "react";
import ReactQuill from "react-quill";

export const QuillComponent = () => {
  const placeholder = "Hello World!";

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactQuill placeholder={placeholder} style={{ height: "100%" }} />
    </div>
  );
};

export const QuillComponentMinimal = ({ setMessage, message }) => {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "link", "image"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
    ],
  };

  const placeholder = "Type a message";

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "indent",
    "size",
    "header",
    "link",
    "image",
    "color",
    "background",
    "clean",
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactQuill
        value={message}
        onChange={(e) => setMessage(e)}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        style={{ height: "100%" }}
      />
    </div>
  );
};
