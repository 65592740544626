import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  invoices: {
    data: [],
    meta: {
      total: 0,
    },
  },
  singleInvoice: null,
};

// TODO: fetch all the invoices
export const fetchInvoices = createAsyncThunk(
  "fetchInvoices/invoices",
  async ({ limit = 10, page = 1, params }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`order`, {
        params: {
          ...params,
          limit,
          page,
          hasInvoice: true,
        },
      });

      return {
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: fetch single invoice
export const fetchSingleInvoice = createAsyncThunk("fetchSingleInvoice/invoices", async ({ id }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`order/${id}`);
    return response.data.data;
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: create invoice
export const createInvoice = createAsyncThunk("createInvoice/invoices", async ({ data, handleClose }, thunkApi) => {
  try {
    const response = await axiosInstance.post(`invoice`, data);

    return {
      data: response.data.data,
      handleClose,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: update invoice
export const updateInvoice = createAsyncThunk("updateInvoice/invoices", async ({ data, handleClose, id }, thunkApi) => {
  try {
    const response = await axiosInstance.patch(`invoice/${id}`, data);

    return {
      data: response.data.data,
      handleClose,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: delete invoice
export const deleteInvoice = createAsyncThunk("deleteInvoice/invoices", async ({ handleClose, id }, thunkApi) => {
  try {
    await axiosInstance.delete(`invoice/${id}`);
    return {
      data: id,
      handleClose,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: fetch all search invoices
export const fetchSearchedInvoices = createAsyncThunk(
  "fetchSearchedInvoices/invoices",
  async ({ params, limit = 10, page = 1, status }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`order`, {
        params: {
          limit,
          page,
          hasInvoice: true,
          ...params,
        },
      });
      return {
        status: params.status || status,
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

const invoiceslice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    resetLoadings: (state) => {
      state.isLoading = false;
      state.fetchLoading = false;
    },
  },
  extraReducers: (builder) => {
    // TODO: get invoice
    builder.addCase(fetchInvoices.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchInvoices.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.invoices = action.payload;
      state.mainInvoices = state.invoices?.data?.filter?.((cat) => cat?.parent_id === null);
    });

    builder.addCase(fetchInvoices.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      toast.error(action.payload.error.message || "ERROR");
    });

    // TODO: get single invoice
    builder.addCase(fetchSingleInvoice.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchSingleInvoice.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.singleInvoice = action.payload;
    });

    builder.addCase(fetchSingleInvoice.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      toast.error(action.payload.error.message || "ERROR");
    });

    // TODO: create invoice
    builder.addCase(createInvoice.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.invoices.data = [action.payload.data, ...state.invoices.data];
      state.invoices.meta.total = state.invoices.meta.total + 1;
      toast.success("Work Type is created successfully.");
    });

    builder.addCase(createInvoice.rejected, (state, action) => {
      state.isLoading = false;
      toast(action.payload.error.message);
    });

    // TODO: update invoice
    builder.addCase(updateInvoice.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.invoices.data = state.invoices.data.map((invoice) => {
        if (invoice.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return invoice;
        }
      });
      toast.success("Work Type is updated successfully.");
    });

    builder.addCase(updateInvoice.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });

    // TODO: delete invoice
    builder.addCase(deleteInvoice.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.invoices.data = state.invoices.data.filter((invoice) => invoice.id !== action.payload.data);
      toast.success("Work Type is deleted successfully.");
    });

    builder.addCase(deleteInvoice.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });

    // TODO: fetch serached invoices

    builder.addCase(fetchSearchedInvoices.fulfilled, (state, action) => {
      state.invoices = action.payload;
    });

    builder.addCase(fetchSearchedInvoices.rejected, (state, action) => {
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });
  },
});

export default invoiceslice.reducer;

export const { resetLoadings } = invoiceslice.actions;
