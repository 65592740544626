import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  roles: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: fetch roles
export const fetchRoles = createAsyncThunk("fetchRoles/roles", async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get(`roles`);
    return {
      data: response.data.data,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

const rolelice = createSlice({
  name: "roles",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchRoles.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.roles = action.payload;
    });

    builder.addCase(fetchRoles.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(JSON.stringify(action.payload.error || action.payload.error.errors || action.payload.error.message));
    });
  },
});

export default rolelice.reducer;

export const { resetExistingRole } = rolelice.actions;
