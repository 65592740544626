import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  workTypes: {
    data: [],
    meta: {
      total: 0,
    },
  },
  mainWorkTypes: [],
};

// TODO: fetch all the workTypes
export const fetchWorkTypes = createAsyncThunk(
  "fetchWorkTypes/workTypes",
  async ({ limit = 10, page = 1 }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`worktype`, {
        params: {
          limit,
          page,
        },
      });

      return {
        data: response.data.data,
        meta: {
          total: response.data.data.length,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: create workType
export const createWorkType = createAsyncThunk("createWorkType/workTypes", async ({ data, handleClose }, thunkApi) => {
  try {
    const response = await axiosInstance.post(`worktype`, data);

    return {
      data: response.data.data,
      handleClose,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

// TODO: update workType
export const updateWorkType = createAsyncThunk(
  "updateWorkType/workTypes",
  async ({ data, handleClose, id }, thunkApi) => {
    try {
      const response = await axiosInstance.patch(`worktype/${id}`, data);

      return {
        data: response.data.data,
        handleClose,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  }
);

// TODO: delete workType
export const deleteWorkType = createAsyncThunk("deleteWorkType/workTypes", async ({ handleClose, id }, thunkApi) => {
  try {
    await axiosInstance.delete(`worktype/${id}`);
    return {
      data: id,
      handleClose,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

const workTypeSlice = createSlice({
  name: "workType",
  initialState,
  reducers: {
    resetLoadings: (state) => {
      state.isLoading = false;
      state.fetchLoading = false;
    },
  },
  extraReducers: (builder) => {
    // TODO: get workType
    builder.addCase(fetchWorkTypes.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchWorkTypes.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.workTypes = action.payload;
      state.mainWorkTypes = state.workTypes?.data?.filter?.((cat) => cat?.parent_id === null);
    });

    builder.addCase(fetchWorkTypes.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      toast.error(action.payload.error.message || "ERROR");
    });

    // TODO: create workType
    builder.addCase(createWorkType.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createWorkType.fulfilled, (state, action) => {
      state.isLoading = false;
      state.workTypes.data = [action.payload.data, ...state.workTypes.data];
      state.workTypes.meta.total = state.workTypes.meta.total + 1;
      toast.success("Work Type is created successfully.");
    });

    builder.addCase(createWorkType.rejected, (state, action) => {
      state.isLoading = false;
      toast(action.payload.error.message);
    });

    // TODO: update workType
    builder.addCase(updateWorkType.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateWorkType.fulfilled, (state, action) => {
      state.isLoading = false;
      state.workTypes.data = state.workTypes.data.map((workType) => {
        if (workType.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return workType;
        }
      });
      toast.success("Work Type is updated successfully.");
    });

    builder.addCase(updateWorkType.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });

    // TODO: delete workType
    builder.addCase(deleteWorkType.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteWorkType.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log(action.payload, "handleclose");
      state.workTypes.data = state.workTypes.data.filter((workType) => workType.id !== action.payload.data);
      toast.success("Work Type is deleted successfully.");
    });

    builder.addCase(deleteWorkType.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload.error.message);
    });
  },
});

export default workTypeSlice.reducer;

export const { resetLoadings } = workTypeSlice.actions;
