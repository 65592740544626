import { unwrapResult } from "@reduxjs/toolkit";
import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, Form, Label, Row, Spinner } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon, PreviewCard } from "../../components/Component";
import Content from "../../layout/content/Content";
import { createNewReminder } from "../../redux/slices/orderSlice";
import { fetchReminders } from "../../redux/slices/reminderSlice";
import { fetchUsers } from "../../redux/slices/userSlice";

const AssignSpecialistForm = ({ onClick, data, isEdit = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { control, register, handleSubmit, errors, getValues, setValue } = useForm({
    defaultValues: {
      stages: [{ stageName: "" }],
    },
  });

  const [startTime, setStartTime] = useState(new Date());

  const roles = useSelector((state) => state.role.roles);
  const specialists = useSelector((state) => state.user.filteredUsers);
  const stages = useSelector((state) => state.reminder.reminders);
  const isLoading = useSelector((state) => state.order.isLoading);

  // TODO: fetch the specialists
  useEffect(() => {
    if (roles?.data?.find((role) => role?.slug === "consultant")?.id) {
      dispatch(
        fetchUsers({
          limit: 100,
          page: 0,
          params: {
            role_id: roles?.data?.find((role) => role?.slug === "consultant")?.id,
            work_type_id: data?.work_type_id,
          },
          filter: true,
        })
      );

      dispatch(
        fetchReminders({
          limit: 200,
          page: 0,
        })
      );
    }
  }, [dispatch, roles?.data, data?.work_type_id]);

  useEffect(() => {
    // if (data?.specialist_id) {
    if (data?.specialist_id) {
      setValue("specialist", data?.specialist_id);
    }
    // }
  }, [data, data?.specialist_id, setValue]);

  const onFormSubmit = async (formData) => {
    // TODO: first update the order (add specialist) and then send time and reminders

    const updateOrderData = {
      specialist_id: parseInt(formData.specialist),
      status: "assigned",
      user_id: data?.user?.id,
    };

    const reminderData = {
      order_id: data?.id,
      time: moment(startTime).format("HH:mm"),
      reminders: formData.stages.map((s) => parseInt(s.stageName)),
    };

    try {
      const resultAction = await dispatch(
        createNewReminder({
          reminderData,
          history,
          updateOrderData,
        })
      );

      const originalPromiseResult = unwrapResult(resultAction);
      originalPromiseResult.history &&
        originalPromiseResult.history.push(`/reminders?specialist=${getValues().specialist}`);
    } catch (error) {}
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "stages",
  });

  const addStage = () => {
    append({ stageName: "" });
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  return (
    <React.Fragment>
      <Content>
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Specialist for{" "}
                <span className="text-green-600">
                  {data?.user?.name}-{data?.work_type?.name}
                </span>
              </BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <Form onSubmit={handleSubmit(onFormSubmit)} className={formClass}>
              <Row className="g-gs">
                {/* Specialist Name */}
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="specialist-name">
                      Select Specialist
                    </Label>
                    <div className="form-control-wrap">
                      <select
                        ref={register({ required: true })}
                        className="form-control form-select"
                        id="fv-specialist"
                        name="specialist"
                      >
                        {data?.specialist_id ? null : <option label="Select a specialist" value=""></option>}
                        {specialists?.data?.map((s, i) => (
                          <option selected={s?.id === data?.specialist_id} key={i} value={s?.id}>
                            {s?.name}
                          </option>
                        ))}
                      </select>

                      {errors.specialist && <span className="invalid">This field is required.</span>}
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="reminder-time">
                      Time of reminder
                    </Label>
                    <DatePicker
                      selected={startTime}
                      onChange={(date) => setStartTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className="form-control date-picker"
                    />{" "}
                  </div>
                </Col>

                <Col md="12">
                  <label className="block font-semibold mb-2">Stage Name</label>
                  {fields.map((stage, index) => (
                    <div key={stage.id} className="flex items-center mb-2">
                      <select
                        placeholder={`Stage ${index + 1}`}
                        className="form-control"
                        name={`stages.${index}.stageName`}
                        ref={register({ required: true })}
                      >
                        <option label="Select stage" value=""></option>
                        {stages?.data?.map((s, i) => (
                          <option key={i} value={s?.id}>
                            {s?.name} ({s?.days} Days)
                          </option>
                        ))}
                      </select>
                      {errors.stages?.[index]?.stageName && (
                        <span className="invalid">Atleast one stage is required.</span>
                      )}

                      {fields.length > 1 ? (
                        <button type="button" onClick={() => remove(index)} className="ml-2">
                          <Icon
                            name="trash-alt"
                            style={{
                              fontSize: 20,
                              color: "red",
                            }}
                          />
                        </button>
                      ) : null}
                    </div>
                  ))}
                </Col>

                {/* Add Stage Button */}
                <div className="mb-6">
                  <button type="button" onClick={addStage} className="text-blue-500 flex items-center">
                    <span className="mr-2">➕</span> Add stage
                  </button>
                </div>

                {/* Save and Cancel Buttons */}
                <Col md="12">
                  <div className="flex gap-3 items-center">
                    <Button disabled={isLoading} color="primary" type="submit">
                      {isLoading ? <Spinner size="sm" color="light" /> : "Assign"}
                    </Button>

                    <Button disabled={isLoading} color={"danger"} type="reset" onClick={onClick}>
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AssignSpecialistForm;
