import React from "react";
import { Button, Col, Spinner } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle } from "../../components/Component";
import Content from "../../layout/content/Content";

const ConfirmChangeStatus = ({ onChange, onClose, isLoading }) => {
  return (
    <>
      <Content>
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle className={"text-xl"} page>
                Are you sure, you want to change status?
              </BlockTitle>
            </BlockHeadContent>
          </BlockHead>

          <Col md="12">
            <div className="flex gap-3 items-center">
              <Button color="primary" type="submit" onClick={onChange}>
                {isLoading ? <Spinner size="sm" color="light" /> : "Yes"}
              </Button>

              <Button color={"danger"} type="reset" onClick={onClose}>
                No
              </Button>
            </div>
          </Col>
        </Block>
      </Content>
    </>
  );
};

export default ConfirmChangeStatus;
