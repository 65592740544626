import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  practiceAreas: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: fetch all the practiceAreas
export const fetchPracticeAreas = createAsyncThunk(
  "fetchPracticeAreas/practiceAreas",
  async ({ enqueueSnackbar, limit, page = 1, category_id }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`pages`, {
        params: {
          page,
          limit,
          category_id,
        },
      });

      return {
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: create practiceArea
export const createPracticeArea = createAsyncThunk(
  "createPracticeArea/practiceAreas",
  async ({ data, enqueueSnackbar, handleClose }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`pages`, data);

      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: update practiceArea
export const updatePracticeArea = createAsyncThunk(
  "updatePracticeArea/practiceAreas",
  async ({ data, enqueueSnackbar, handleClose, id }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`pages/${id}`, data);

      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: delete practiceArea
export const deletePracticeArea = createAsyncThunk(
  "deletePracticeArea/practiceAreas",
  async ({ enqueueSnackbar, handleClose, id }, thunkApi) => {
    try {
      await axiosInstance.delete(`pages/${id}`);
      return {
        data: id,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

const practiceAreaslice = createSlice({
  name: "practiceArea",
  initialState,
  reducers: {
    resetPracticeArea: (state) => {
      state.fetchLoading = false;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    // TODO: create practiceArea
    builder.addCase(fetchPracticeAreas.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchPracticeAreas.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.practiceAreas = action.payload;
    });

    builder.addCase(fetchPracticeAreas.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: create practiceArea
    builder.addCase(createPracticeArea.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createPracticeArea.fulfilled, (state, action) => {
      state.isLoading = false;
      state.practiceAreas.data = [action.payload.data, ...state.practiceAreas.data];
      state.practiceAreas.meta.total = state.practiceAreas.meta.total + 1;
      action.payload.enqueueSnackbar("PracticeArea is created successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(createPracticeArea.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: update practiceArea
    builder.addCase(updatePracticeArea.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updatePracticeArea.fulfilled, (state, action) => {
      state.isLoading = false;
      state.practiceAreas.data = state.practiceAreas.data.map((practiceArea) => {
        if (practiceArea.slug === action.payload.data.slug) {
          return action.payload.data;
        } else {
          return practiceArea;
        }
      });
      action.payload.enqueueSnackbar("PracticeArea is updated successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(updatePracticeArea.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: delete practiceArea
    builder.addCase(deletePracticeArea.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deletePracticeArea.fulfilled, (state, action) => {
      state.isLoading = false;
      state.practiceAreas.data = state.practiceAreas.data.filter(
        (practiceArea) => practiceArea.slug !== action.payload.data
      );
      action.payload.enqueueSnackbar("PracticeArea is deleted successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(deletePracticeArea.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });
  },
});

export default practiceAreaslice.reducer;

export const { resetPracticeArea } = practiceAreaslice.actions;
