import { unwrapResult } from "@reduxjs/toolkit";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, Form, Label, Row, Spinner } from "reactstrap";
import Loading from "../../common/Loading";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard } from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { fetchCategories } from "../../redux/slices/categorySlice";
import { createLibrary, fetchSingleLibrary, resetSingleLibrary, updateLibrary } from "../../redux/slices/librarySlice";
import Upload from "../app/file-manager/modals/Upload";

const CreateLibraryForm = ({ view = false }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const history = useHistory();

  const { errors, register, handleSubmit, setValue } = useForm();

  const [files, setFiles] = useState([]);

  const isLoading = useSelector((state) => state.library.isLoading);
  const library = useSelector((state) => state.library.singleLibrary);
  const fetchLoading = useSelector((state) => state.library.fetchLoading);
  const categories = useSelector((state) => state.category.categories);

  useEffect(() => {
    dispatch(resetSingleLibrary());
    if (id) {
      dispatch(fetchSingleLibrary({ id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(
      fetchCategories({
        limit: 50,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    if (library && id && categories?.data?.length) {
      setValue("name", library?.name);
      setValue("description", library?.description);
      setValue("category_id", library?.category_id);
    }
  }, [dispatch, id, setValue, library, categories]);

  const onFormSubmit = async (data) => {
    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("category_id", data.category_id);
    formData.append("description", data.description);

    if (files.length) {
      formData.append("file", files[0]);
    }

    try {
      const resultAction = await dispatch(
        id
          ? updateLibrary({
              data: formData,
              id,
              history,
            })
          : createLibrary({
              data: formData,
              history,
            })
      );

      const originalPromiseResult = unwrapResult(resultAction);
      originalPromiseResult.history && originalPromiseResult.history.push("/libraries/list");
    } catch (error) {}
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  useEffect(() => {
    if (library && id) {
      setValue("name", library?.name);
    }
  }, [id, library, setValue]);

  return (
    <React.Fragment>
      <Head title={`${id ? "Edit" : "Create"} Library`} />
      {fetchLoading ? (
        <Loading />
      ) : (
        <Content>
          <Block size="lg">
            <BlockHead>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  {id ? `Edit Library (${library?.name})` : "Create Library"}
                </BlockTitle>
              </BlockHeadContent>
            </BlockHead>
            <PreviewCard>
              <Form onSubmit={handleSubmit(onFormSubmit)} className={formClass}>
                <Row className="g-gs">
                  {/* Patient Form */}
                  <Col md="6">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-name">
                        Name
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="text"
                          id="fv-name"
                          name="name"
                          className="form-control"
                        />
                        {errors.name && <span className="invalid">This field is required</span>}
                      </div>
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-category_id">
                        Category *
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          ref={register({ required: true })}
                          className="form-control form-select"
                          id="fv-category_id"
                          name="category_id"
                        >
                          <option label="Select a category" value=""></option>
                          {categories?.data?.map((item, index) => (
                            <option key={index} value={item?.id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                        {errors.category_id && errors.category_id.type === "required" && (
                          <span className="invalid">This is required</span>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-description">
                        Description
                      </Label>
                      <div className="form-control-wrap">
                        <textarea
                          ref={register({})}
                          type="text"
                          id="fv-description"
                          name="description"
                          className="form-control"
                        />
                        {errors.description && <span className="invalid">This field is required</span>}
                      </div>
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-work_type_id">
                        Files *
                      </Label>
                      <div className="form-control-wrap">
                        <Upload
                          multiple={false}
                          files={files?.length ? files : library?.file ? [library?.file] : []}
                          setFiles={setFiles}
                        />
                      </div>
                    </div>
                  </Col>

                  {view ? null : (
                    <>
                      <hr />

                      <Col md="12">
                        <div className="flex gap-3 items-center">
                          <Button disabled={isLoading} color="primary" type="submit">
                            {isLoading ? <Spinner size="sm" color="light" /> : "Submit"}
                          </Button>

                          <Button
                            disabled={isLoading}
                            color={"danger"}
                            type="reset"
                            onClick={() => {
                              history.go(-1);
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            </PreviewCard>
          </Block>
        </Content>
      )}
    </React.Fragment>
  );
};

export default CreateLibraryForm;
