import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Label, Row, Spinner } from "reactstrap";
import { useAuthContext } from "../../auth/useAuthContext";
import NoData from "../../common/NoData";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import Content from "../../layout/content/Content";
import { fetchRemindersInPaidOrders, updateBulkReminders } from "../../redux/slices/reminderSlice";
import { fetchUsersInPaidOrders } from "../../redux/slices/userSlice";

const EditpaidOrder = ({ order, onClose, reminder, selectedSpecialist, defaultSpecialist }) => {
  const dispatch = useDispatch();
  const { user } = useAuthContext();

  const [startTime, setStartTime] = useState(new Date());
  const [updatedStage, setUpdatedStage] = useState({
    initial: "",
    new: "",
  });

  const { control, register, handleSubmit, errors, setValue, getValues } = useForm({
    defaultValues: {
      stages: [{ stageName: reminder?.id }],
      specialist: order?.specialist_id,
    },
  });

  const workTypes = useSelector((state) => state.workType.workTypes);
  const specialists = useSelector((state) => state.user.filteredUsers);
  const roles = useSelector((state) => state.role.roles);
  const stages = useSelector((state) => state.reminder.reminders);
  const isLoading = useSelector((state) => state.reminder.isLoading);

  useEffect(() => {
    if (order?.id) {
      setValue("price", order?.price);
      setValue("work_type_id", order?.work_type_id);
      setValue("specialist", order?.specialist_id);
      setStartTime(new Date(order?.time));
      // setValue("stages", [{ stageName: reminder?.id }]);
    }
  }, [
    order,
    order?.id,
    order?.price,
    order.setStartTime,
    order?.specialist_id,
    order?.time,
    order?.work_type_id,
    reminder,
    setValue,
  ]);

  // TODO: fetch the specialists

  useEffect(() => {
    if (roles?.data?.find((role) => role?.slug === "consultant")?.id) {
      dispatch(
        fetchUsersInPaidOrders({
          limit: 100,
          page: 0,
          params: {
            role_id: roles?.data?.find((role) => role?.slug === "consultant")?.id,
            work_type_id: order?.work_type_id,
          },
          filter: true,
        })
      );

      dispatch(
        fetchRemindersInPaidOrders({
          limit: 200,
          page: 0,
          loadPaid: true,
        })
      );
    }
  }, [dispatch, roles?.data, order?.work_type_id]);

  const onFormSubmit = async (data) => {
    try {
      const formattedData = {
        reminders: [
          {
            id: order?.reminder_id,
            order_id: order?.id,
            reminder_id: updatedStage.new !== "" ? updatedStage.new : reminder?.id,
          },
          ...data.stages.slice(1).map((stage) => ({
            id: null,
            order_id: order?.id,
            reminder_id: stage.stageName,
          })),
        ],
        order_id: order?.id,
        time: moment(startTime).format("HH:mm"),
      };

      dispatch(
        updateBulkReminders({
          data: formattedData,
          handleClose: onClose,
          user,
          selectedSpecialist,
          specialists,
          defaultSpecialist,
        })
      );
    } catch (error) {}
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "stages",
  });

  const addStage = () => {
    append({ stageName: "" });
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  console.log(fields, "fieldssss");

  return (
    <React.Fragment>
      <Content>
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Edit Order
              </BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <Form onSubmit={handleSubmit(onFormSubmit)} className={formClass}>
            <Row className={"mt-3"}>
              <Col md="6">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-work_type_id">
                    Work Type *
                  </Label>
                  <div className="form-control-wrap">
                    <select
                      disabled
                      ref={register({ required: true })}
                      className="form-control form-select"
                      id="fv-work_type_id"
                      name="work_type_id"
                      // disabled={!isNewOrder}
                    >
                      <option label="Select a work type" value=""></option>
                      {workTypes?.data?.map((item, index) => (
                        <option key={index} value={item?.id}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    {errors.work_type_id && errors.work_type_id.type === "required" && (
                      <span className="invalid">This is required</span>
                    )}
                  </div>
                </div>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-cost">
                    Cost
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      disabled
                      ref={register({ required: true })}
                      type="number"
                      id="fv-cost"
                      name="price"
                      className="form-control"
                    />
                    {errors.cost && errors.cost.type === "required" && (
                      <span className="invalid">This is required</span>
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <hr className="my-4" />
            <Row className="g-gs">
              <Col md="6">
                <div className="form-group">
                  <Label className="form-label" htmlFor="specialist-name">
                    Select Specialist
                  </Label>
                  {specialists?.data?.length > 0 ? (
                    <div className="form-control-wrap">
                      <select
                        disabled
                        ref={register({ required: true })}
                        className="form-control form-select"
                        id="fv-specialist"
                        name="specialist"
                      >
                        <option label="Select a specialist" value=""></option>
                        {specialists?.data?.map((s, i) => (
                          <option key={i} value={s?.id}>
                            {s?.name}
                          </option>
                        ))}
                      </select>

                      {errors.specialist && <span className="invalid">This field is required.</span>}
                    </div>
                  ) : (
                    <NoData message="No specialists" fontSize={10} imgSize={40} />
                  )}
                </div>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <Label className="form-label" htmlFor="reminder-time">
                    Time of reminder
                  </Label>
                  <DatePicker
                    selected={startTime}
                    onChange={(date) => setStartTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="form-control date-picker"
                  />{" "}
                </div>
              </Col>

              <Col md="12">
                <label className="block font-semibold mb-2">Stage Name</label>
                {fields?.map((stage, index) => (
                  <div key={stage.id} className="flex items-center mb-2">
                    {console.log(stage, "stateegefe")}
                    <select
                      onChange={(e) => {
                        if (index === 0) {
                          setUpdatedStage({
                            new: e.target.value,
                            initial: stage.stageName,
                          });
                        }
                      }}
                      key={stage?.stageName}
                      defaultValue={stage?.stageName}
                      placeholder={`Stage ${index + 1}`}
                      className="form-control"
                      name={`stages.${index}.stageName`}
                      ref={register({ required: true })}
                    >
                      <option label="Select stage" value=""></option>
                      {stages?.data?.map((s, i) => (
                        <option selected={s?.id === reminder?.id} key={i} value={s?.id}>
                          {s?.name} ({s?.days} Days)
                        </option>
                      ))}
                    </select>
                    {errors.stages?.[index]?.stageName && (
                      <span className="invalid">Atleast one stage is required.</span>
                    )}

                    {fields.length > 1 && index !== 0 ? (
                      <button type="button" onClick={() => remove(index)} className="ml-2">
                        <Icon
                          name="trash-alt"
                          style={{
                            fontSize: 20,
                            color: "red",
                          }}
                        />
                      </button>
                    ) : null}
                  </div>
                ))}
              </Col>

              {/* Add Stage Button */}
              <div className="mb-6">
                <button type="button" onClick={addStage} className="text-blue-500 flex items-center">
                  <span className="mr-2">➕</span> Add stage
                </button>
              </div>
            </Row>
            <Col md="12" className="mt-5">
              {/* <Button disabled={userLoading || orderLoading} color="primary" type="submit">
                  {userLoading || orderLoading ? <Spinner size="sm" color="light" /> : "Update"}
                </Button> */}
              <Button color="primary" type="submit">
                {isLoading ? <Spinner size="sm" color="light" /> : "Update"}
              </Button>
            </Col>
          </Form>
        </Block>

        <button className="absolute top-5 right-5 cursor-pointer" type="reset" onClick={onClose}>
          <Icon
            name={"cross"}
            style={{
              fontSize: 20,
            }}
          />
        </button>
      </Content>
    </React.Fragment>
  );
};

export default EditpaidOrder;
