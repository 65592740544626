import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Card } from "reactstrap";
import { useAuthContext } from "../auth/useAuthContext";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  PreviewAltCard,
  Row,
} from "../components/Component";
import PatientGraph from "../components/partials/default/patient-graphs/PatientGraph";
import SalesGraph from "../components/partials/default/sales-graph/SalesGraph";
import SalesOverview from "../components/partials/default/sales-overview/SalesOverview";
import TransactionTable from "../components/partials/default/transaction/Transaction";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { fetchGraphs } from "../redux/slices/graphSlice";

const Homepage = () => {
  const { user } = useAuthContext();
  const dispatch = useDispatch();

  const [sm, updateSm] = useState(false);

  useEffect(() => {
    dispatch(fetchGraphs({}));
  }, [dispatch]);

  return (
    <React.Fragment>
      <Head title="Homepage"></Head>
      <Content>
        {user?.role?.slug === "admin" ? (
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page tag="h3">
                  Overview
                </BlockTitle>
                <BlockDes className="text-soft">
                  <p>Welcome to Ryze HRT Dashboard</p>
                </BlockDes>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
        ) : null}
        <Block>
          <Row className="g-gs">
            <Col sm="6" lg="6" xxl="6">
              {user?.role?.slug === "user" ? (
                <PreviewAltCard className="flex flex-col justify-between h-full">
                  <div className="flex flex-col gap-1">
                    <div className="flex justify-between flex-wrap">
                      <BlockTitle page tag="h3">
                        Welcome, {user?.name}
                      </BlockTitle>

                      <Link to="/user-profile" className="w-fit">
                        <Button color={"primary"}>View Profile</Button>
                      </Link>
                    </div>
                    <BlockDes className="text-soft">
                      <p>{user?.email}</p>
                    </BlockDes>

                    <BlockDes className="text-soft">
                      <p>{user?.phone}</p>
                    </BlockDes>
                  </div>
                </PreviewAltCard>
              ) : (
                <PreviewAltCard>
                  <PatientGraph />
                </PreviewAltCard>
              )}
            </Col>

            <Col sm="6" lg="6" xxl="6">
              <PreviewAltCard>
                <SalesGraph />
              </PreviewAltCard>
            </Col>

            {user?.role?.slug === "admin" ? (
              <Col xxl="12">
                <PreviewAltCard className="h-100">
                  <SalesOverview />
                </PreviewAltCard>
              </Col>
            ) : null}
            <Col xxl="12">
              <Card className="card-bordered card-full">
                <TransactionTable />
              </Card>
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Homepage;
