import moment from "moment";
import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { fetchGraphs } from "../../../../redux/slices/graphSlice";
import { Icon } from "../../../Component";
import { LineChart } from "../../charts/default/Charts";

const SalesOverview = () => {
  const graphs = useSelector((state) => state.graph.graphs);
  const [month, setMonth] = useState(new Date());
  const dispatch = useDispatch();

  const total = graphs?.data?.data?.reduce((acc, curr) => {
    return acc + curr?.salesOverview;
  }, 0);

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <div onClick={onClick} ref={ref}>
      <div className="form-icon form-icon-left">
        <Icon name="calendar"></Icon>
      </div>
      <input className="form-control date-picker" type="text" value={value} onChange={onChange} />
    </div>
  ));

  return (
    <React.Fragment>
      <div className="card-title-group align-start gx-3 mb-3">
        <div className="flex justify-between items-center w-full">
          <div className="card-title">
            <h6 className="title">Sales Overview</h6>
            <p>Day wise sales of 30 days. </p>
          </div>

          <div className="form-group">
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <Icon name="calendar"></Icon>
              </div>
              <DatePicker
                selected={month}
                onChange={(date) => {
                  setMonth(date);
                  dispatch(
                    fetchGraphs({
                      params: {
                        month: moment(date).month() + 1,
                        year: moment(month).year(),
                      },
                    })
                  );
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                className="form-control date-picker"
                customInput={<ExampleCustomInput />}
              />{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="nk-sale-data-group align-center justify-between gy-3 gx-5">
        <div className="nk-sale-data">
          <span className="amount">
            ${" "}
            {new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
              parseFloat(total)
            )}
          </span>{" "}
        </div>
        {/* <div className="nk-sale-data">
          <span className="amount sm">
            1,937 <small>Customers</small>
          </span>
        </div> */}
      </div>
      <div className="nk-sales-ck large pt-4">
        <LineChart data={graphs?.data?.data} />
      </div>
    </React.Fragment>
  );
};
export default SalesOverview;
