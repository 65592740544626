import React from "react";

const NoData = ({ message = "no data", fontSize = 15, imgSize = 100 }) => {
  return (
    <div
      className="uppercase flex flex-col justify-center items-center font-bold"
      style={{
        fontSize,
      }}
    >
      <img
        src="/no-data.png"
        alt="not data"
        style={{
          mixBlendMode: "multiply",
          width: imgSize,
        }}
      />
      {message}
    </div>
  );
};

export default NoData;
