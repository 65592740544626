import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  teams: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: fetch all the team members
export const fetchTeamMembers = createAsyncThunk(
  "fetchTeamMembers/teams",
  async ({ enqueueSnackbar, limit = 10, page = 1, search }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`teams`, {
        params: {
          ...(search !== "" && {
            search,
          }),
          limit,
          page,
        },
      });

      return {
        data: response.data.data,
        meta: {
          total: response.data.data.length,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: create the team members
export const createTeamMembers = createAsyncThunk(
  "createTeamMembers/teams",
  async ({ enqueueSnackbar, data, handleClose }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`teams`, data);
      return {
        data: response.data.data,
        meta: {
          total: response.data.data.length,
        },
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: update the team members
export const updateTeamMember = createAsyncThunk(
  "updateTeamMember/teams",
  async ({ enqueueSnackbar, data, handleClose, id }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`teams/${id}`, data);
      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: delete team
export const deleteTeamMember = createAsyncThunk(
  "deleteTeamMember/teams",
  async ({ enqueueSnackbar, handleClose, id }, thunkApi) => {
    try {
      await axiosInstance.delete(`teams/${id}`);
      return {
        data: id,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    resetTeam: (state) => {
      state.isLoading = false;
      state.fetchLoading = false;
    },
  },

  extraReducers: (builder) => {
    // TODO: fetch team members
    builder.addCase(fetchTeamMembers.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchTeamMembers.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.teams = action.payload;
    });

    builder.addCase(fetchTeamMembers.rejected, (state, action) => {
      state.fetchLoading = false;
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: create team member
    builder.addCase(createTeamMembers.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createTeamMembers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.teams.data = [action.payload.data, ...state.teams.data];
      state.teams.meta.total = state.teams.meta.total + 1;
      action.payload.enqueueSnackbar("Team member is created successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(createTeamMembers.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: update teamMember
    builder.addCase(updateTeamMember.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateTeamMember.fulfilled, (state, action) => {
      state.isLoading = false;
      state.teams.data = state.teams.data.map((teamMember) => {
        if (teamMember.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return teamMember;
        }
      });
      action.payload.enqueueSnackbar("Team member is updated successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(updateTeamMember.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });

    // TODO: delete teamMember
    builder.addCase(deleteTeamMember.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteTeamMember.fulfilled, (state, action) => {
      state.isLoading = false;
      state.teams.data = state.teams.data.filter((teamMember) => teamMember.id !== action.payload.data);
      action.payload.enqueueSnackbar("Team member is deleted successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(deleteTeamMember.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(action.payload.error.message, {
        variant: "error",
      });
    });
  },
});

export default teamSlice.reducer;

export const { resetTeam } = teamSlice.actions;
