import { unwrapResult } from "@reduxjs/toolkit";
import classNames from "classnames";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, Form, Label, Modal, Row, Spinner } from "reactstrap";
import NoData from "../../../common/NoData";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard } from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { createNewOrder } from "../../../redux/slices/orderSlice";
import { createNewUser, fetchUsers, resetExistingUser, searchExistingUser } from "../../../redux/slices/userSlice";
import { fetchWorkTypes } from "../../../redux/slices/workTypeSlice";
import SearchPatientList from "../../../sections/patients/SearchPatientList";

const OrderRegistration = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { errors, register, handleSubmit, setValue, reset } = useForm();
  const [isNewOrder, setIsNewOrder] = useState(false); // New state for checkbox
  const [phone, setPhone] = useState(""); // New state for checkbox
  const [workType, setWorkType] = useState(""); // New state for checkbox
  const [searchedUsers, setSearchedUsers] = useState(""); // New state for checkbox
  const [existingUser, setExistingUser] = useState(""); // New state for checkbox
  const [showSearch, setShowSearch] = useState(false);

  const isPhoneSearchLoading = useSelector((state) => state.user.isPhoneSearchLoading);
  const workTypes = useSelector((state) => state.workType.workTypes);
  const userLoading = useSelector((state) => state.user.isLoading);
  const orderLoading = useSelector((state) => state.order.isLoading);
  const roles = useSelector((state) => state.role.roles);
  const specialists = useSelector((state) => state.user.filteredUsers);
  const existingUsers = useSelector((state) => state.user.existingUser);

  useEffect(() => {
    dispatch(
      fetchWorkTypes({
        limit: 50,
        page: 0,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchUsers({
        limit: 100,
        page: 0,
        params: {
          role_id: roles?.data?.find((role) => role?.slug === "consultant")?.id,
          work_type_id: workType,
        },
        filter: true,
      })
    );
  }, [dispatch, roles?.data, workType]);

  const onFormSubmit = async (data) => {
    try {
      const resultAction = await dispatch(
        !isNewOrder && existingUser
          ? createNewOrder({
              data: {
                user_id: existingUser?.id,
                price: data.price,
                work_type_id: data.work_type_id,
              },
              history,
              specialist: data.specialist,
              note: data.note,
            })
          : createNewUser({
              data: {
                name: data.name,
                email: data.email,
                phone: data.phone,
                alt_email: data.alt_email,
                alt_phone: data.alt_phone,
              },
              price: data.price,
              work_type_id: data.work_type_id,
              history,
              specialist: data.specialist,
              note: data.note,
            })
      );

      const originalPromiseResult = unwrapResult(resultAction);
      originalPromiseResult.history && originalPromiseResult.history.push("/orders-list");
    } catch (error) {}
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  useEffect(() => {
    if (!isNewOrder) {
      setValue("name", existingUser?.name);
      setValue("email", existingUser?.email);
      setValue("alt_email", existingUser?.alt_email);
      setValue("phone", existingUser?.phone);
      setValue("alt_phone", existingUser?.alt_phone);
    }
  }, [existingUser, isNewOrder, reset, setValue]);

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    const debouncedDispatch = debounce(() => {
      setShowSearch(true);
      if (phone !== "") {
        dispatch(
          searchExistingUser({
            params: {
              phone,
            },
          })
        );
      }
    }, 0); // Adjust the delay as needed

    debouncedDispatch();

    return () => {
      debouncedDispatch.cancel();
    };
  }, [dispatch, phone]);

  return (
    <React.Fragment>
      <Head title="Order Registration" />
      <Content>
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Register New Order
              </BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <Form onSubmit={handleSubmit(onFormSubmit)} className={formClass}>
              <Row className="g-gs">
                {/* Checkbox to toggle new order mode */}
                <Col md="12">
                  <div className="form-group flex gap-2 items-center">
                    <Label className="form-label" htmlFor="new-order-checkbox">
                      Register as New Patient
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="checkbox"
                        id="new-order-checkbox"
                        onChange={(e) => {
                          setIsNewOrder(e.target.checked);
                          setExistingUser("");
                          setShowSearch(false);
                          reset();
                          dispatch(resetExistingUser());
                        }}
                        checked={isNewOrder}
                      />
                    </div>
                  </div>
                </Col>

                {/* Phone Number Input */}
                <Col md="12">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="search-phone">
                      Search by name or phone number
                    </Label>
                    <div className="form-control-wrap flex gap-5 items-center">
                      <input
                        type="tel"
                        id="search-phone"
                        className="form-control"
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Type phone number or name"
                        ref={register({
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message: "Invalid phone number",
                          },
                        })}
                        disabled={isNewOrder}
                      />
                      {/* <Button disabled={isNewOrder || phone === ""} onClick={handlePhoneSearch}>
                        {isPhoneSearchLoading ? <Spinner size="sm" color="light" /> : "Search"}
                      </Button> */}
                    </div>
                    {showSearch && phone !== "" ? (
                      <>
                        {isPhoneSearchLoading ? (
                          <>Searching...</>
                        ) : (
                          <>
                            {existingUsers?.length > 0 ? (
                              <div className="space-y-4 flex flex-col">
                                {[...existingUsers]?.map((e, i) => (
                                  <button
                                    type="button"
                                    key={i}
                                    className="w-fit text-left bg-white p-2 rounded-lg hover:bg-red-600"
                                    onClick={() => {
                                      setShowSearch(false);
                                      setExistingUser(e);
                                    }}
                                  >
                                    <span className="font-medium text-gray-800">{e?.name}</span>
                                    <span className="text-gray-600 ml-2">({e?.phone})</span>
                                  </button>
                                ))}
                              </div>
                            ) : (
                              <NoData />
                            )}
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                </Col>

                <hr />

                {/* Order Form */}
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-full-name">
                      Full Name
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        ref={register({ required: true })}
                        type="text"
                        id="fv-full-name"
                        name="name"
                        className="form-control"
                        disabled={!isNewOrder}
                      />
                      {errors.name && <span className="invalid">This field is required</span>}
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-primary-email">
                      Primary Email address *
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        ref={register({
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        type="email"
                        id="fv-primary-email"
                        name="email"
                        className="form-control"
                        disabled={!isNewOrder}
                      />
                      {errors.email && errors.email.type === "required" && (
                        <span className="invalid">This is required</span>
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                        <span className="invalid">{errors.email.message}</span>
                      )}
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-additional-email">
                      Additional Email address
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        ref={register({
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        type="email"
                        id="fv-additional-email"
                        name="alt_email"
                        className="form-control"
                        disabled={!isNewOrder}
                      />
                      {errors.alt_email && errors.alt_email.type === "pattern" && (
                        <span className="invalid">{errors.alt_email.message}</span>
                      )}
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-primary-phone">
                      Primary Phone Number *
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        ref={register({ required: true })}
                        type="tel"
                        id="fv-primary-phone"
                        name="phone"
                        className="form-control"
                        disabled={!isNewOrder}
                      />
                      {errors.phone && errors.phone.type === "required" && (
                        <span className="invalid">This is required</span>
                      )}
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-additional-phone">
                      Additional Phone Number
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        ref={register()}
                        id="fv-additional-phone"
                        name="alt_phone"
                        className="form-control"
                        disabled={!isNewOrder}
                      />
                    </div>
                  </div>
                </Col>

                <hr />

                <Col md="12">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-note">
                      Note
                    </Label>
                    <div className="form-control-wrap">
                      <textarea
                        multiple
                        ref={register({ required: false })}
                        type="text"
                        id="fv-note"
                        name="note"
                        className="form-control"
                      />
                      {errors.note && <span className="invalid">This field is required</span>}
                    </div>
                  </div>
                </Col>

                <hr />
                <Row className={"mt-3"}>
                  <Col md="4">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-work_type_id">
                        Work Type *
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          onChange={(e) => setWorkType(e.target.value)}
                          ref={register({ required: true })}
                          className="form-control form-select"
                          id="fv-work_type_id"
                          name="work_type_id"
                          // disabled={!isNewOrder}
                        >
                          <option label="Select a work type" value=""></option>
                          {workTypes?.data?.map((item, index) => (
                            <option key={index} value={item?.id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                        {errors.work_type_id && errors.work_type_id.type === "required" && (
                          <span className="invalid">This is required</span>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-specialist">
                        Specialist
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          ref={register({ required: false })}
                          className="form-control form-select"
                          id="fv-specialist"
                          name="specialist"
                          // disabled={!isNewOrder}
                        >
                          <option label="Select a specialist" value=""></option>
                          {specialists?.data?.map((s, i) => (
                            <option key={i} value={s?.id}>
                              {s?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="form-group">
                      <Label className="form-label" htmlFor="fv-cost">
                        Cost * ($)
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          ref={register({ required: true })}
                          type="number"
                          id="fv-cost"
                          name="price"
                          className="form-control"
                        />
                        {errors.price && errors.price.type === "required" && (
                          <span className="invalid">This is required</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>

                <Col md="12">
                  <Button disabled={userLoading || orderLoading} color="primary" type="submit">
                    {userLoading || orderLoading ? <Spinner size="sm" color="light" /> : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </PreviewCard>
        </Block>
      </Content>

      <Modal isOpen={searchedUsers} className="modal-dialog-centered" size="sm">
        <SearchPatientList onClose={() => setSearchedUsers(false)} setExistingUser={setExistingUser} />
      </Modal>
    </React.Fragment>
  );
};

export default OrderRegistration;
