import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  logs: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: fetch all the log
export const fetchLogs = createAsyncThunk("fetchLog/logs", async ({ limit = 10, page = 1 }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`logs`, {
      params: {
        limit,
        page,
      },
    });

    return {
      data: response.data.data.data,
      meta: {
        total: response.data.data.total,
      },
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ error });
  }
});

const loglice = createSlice({
  name: "log",
  initialState,
  reducers: {
    resetLoadings: (state) => {
      state.isLoading = false;
      state.fetchLoading = false;
    },
  },
  extraReducers: (builder) => {
    // TODO: get log
    builder.addCase(fetchLogs.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchLogs.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.logs = action.payload;
    });

    builder.addCase(fetchLogs.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      toast.error(action.payload.error.message || "ERROR");
    });
  },
});

export default loglice.reducer;

export const { resetLoadings } = loglice.actions;
