import React from "react";
import { KanbanColumn } from "./KanbanPartials";

const KanbanBoard = ({ columns, selectedSpecialist, defaultSpecialist }) => {
  return (
    <div>
      {/* <Droppable droppableId="all-columns" direction="horizontal" type="column">
        {(provided) => ( */}
      <div
        className="kanban-container"
        id="kanban-container"
        style={{ width: `${columns?.columnOrder?.length * 320}px` }}
        // {...provided.droppableProps}
        // ref={provided.innerRef}
      >
        {columns?.columnOrder?.map((columnId, index) => {
          const column = columns?.columns?.[columnId];

          return (
            <KanbanColumn
              defaultSpecialist={defaultSpecialist}
              selectedSpecialist={selectedSpecialist}
              data={columns}
              column={column}
              key={index}
              index={index}
            />
          );
        })}
        {/* {provided.placeholder} */}
      </div>
      {/* )} */}
      {/* </Droppable> */}
    </div>
  );
};

export default KanbanBoard;
