import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row, Spinner } from "reactstrap";
import { useAuthContext } from "../../auth/useAuthContext";
import { Icon } from "../../components/Component";
import { changePassword } from "../../redux/slices/authSlice";

const ChangePw = ({ setModal }) => {
  const dispatch = useDispatch();
  const { logout } = useAuthContext();

  const [passStateOld, setPassStateOld] = useState(false);
  const [passStateNew, setPassStateNew] = useState(false);

  const isLoading = useSelector((state) => state.auth.isLoading);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm();

  const onChangePassword = async (formData) => {
    try {
      const resultAction = await dispatch(
        changePassword({ data: formData, closeModal: () => setModal(false), logout })
      );
      const originalPromiseResult = unwrapResult(resultAction);
      originalPromiseResult.closeModal && originalPromiseResult.closeModal();
      originalPromiseResult.logout && originalPromiseResult.logout();
    } catch (error) {}
  };

  // Watch the new password field to compare with confirm password
  const password = watch("password");

  return (
    <Form className="is-alter" onSubmit={handleSubmit(onChangePassword)}>
      <div className="p-2">
        <h5 className="title mb-3 font-bold text-lg">Change Password</h5>

        <Row className="gy-4">
          <Col md="6">
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="password">
                  New Password
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPassStateOld(!passStateOld);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passStateOld ? "is-hidden" : "is-shown"}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>
                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type={passStateOld ? "text" : "password"}
                  id="password"
                  name="password"
                  ref={register({ required: "This field is required" })}
                  placeholder="Enter new password"
                  className={`form-control-lg form-control ${passStateOld ? "is-hidden" : "is-shown"}`}
                />
                {errors.password && <span className="invalid">{errors.password.message}</span>}
              </div>
            </div>
          </Col>

          <Col md="6">
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="confirmPassword">
                  Confirm Password
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#confirmPassword"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPassStateNew(!passStateNew);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passStateNew ? "is-hidden" : "is-shown"}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>
                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type={passStateNew ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  ref={register({
                    required: "This field is required",
                    validate: (value) => value === password || "Passwords do not match",
                  })}
                  placeholder="Enter password again"
                  className={`form-control-lg form-control ${passStateNew ? "is-hidden" : "is-shown"}`}
                />
                {errors.confirmPassword && <span className="invalid">{errors.confirmPassword.message}</span>}
              </div>
            </div>
          </Col>

          <Col size="12">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
              <li>
                <Button disabled={isLoading} color="primary" size="lg" type="submit">
                  {isLoading ? <Spinner size="sm" color="light" /> : "Change Password"}
                </Button>
              </li>
              <li>
                <a
                  href="#dropdownitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModal(false);
                  }}
                  className="link link-light"
                >
                  Cancel
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default ChangePw;
