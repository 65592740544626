import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Modal, UncontrolledTooltip } from "reactstrap";
import { useAuthContext } from "../../auth/useAuthContext";
import ConfirmDelete from "../../common/modals/ConfirmDelete";
import NoData from "../../common/NoData";
import { Icon, UserAvatar } from "../../components/Component";
import { deleteReminder } from "../../redux/slices/reminderSlice";
import CreateMessageForm from "../../sections/conversations/CreateMessageForm";
import AssignSpecialistForm from "../../sections/patients/AssignSpecialistForm";
import EditpaidOrder from "../../sections/patients/EditPaidOrder";
import { findUpper } from "../../utils/Utils";

export const KanbanCard = ({ card, index, reminder, selectedSpecialist, defaultSpecialist, column }) => {
  const { user } = useAuthContext();
  const dispatch = useDispatch();

  const [patientModal, setPatientModal] = useState(false);
  const [conversationModal, setConversationModal] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const togglePatientModal = () => {
    setPatientModal(!patientModal);
  };

  const toggleConversationModal = () => {
    setConversationModal(!conversationModal);
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const isLoading = useSelector((state) => state.reminder.isLoading);
  const specialists = useSelector((state) => state.user.users);

  const handleDelete = async () => {
    try {
      const resultAction = await dispatch(
        deleteReminder({
          handleClose: toggleDeleteModal,
          id: selectedReminder.reminder_id,
          from: "reminder",
          user,
          selectedSpecialist,
          specialists,
          defaultSpecialist,
        })
      );
      const originalPromiseResult = unwrapResult(resultAction);
      originalPromiseResult.handleClose && originalPromiseResult.handleClose();
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <div key={JSON.stringify(card?.id)} index={index}>
        <div className="mt-2">
          <div className="kanban-item !cursor-default">
            <div className="flex justify-between items-center mb-2">
              <h6 className="title font-bold">{card?.code}</h6>
              <UserAvatar
                theme={card?.avatarBg}
                className="xs"
                text={findUpper(card?.specialist?.name || "-")}
                image={card?.image}
              ></UserAvatar>
            </div>

            <div className="kanban-item-title">
              <h6 className="title font-bold">{card?.user?.name}</h6>
            </div>

            <h2 className="text-gray-400 mt-2">$ {card?.price || "NIL"}</h2>

            <div className="kanban-item-meta">
              <ul className="kanban-item-meta-list">
                <li>
                  <Icon name="calendar"></Icon>
                  <span>{moment(card?.created_at).format("YYYY-MM-DD")}</span>
                </li>
                <li>
                  <Icon name="notes"></Icon>
                  <span>{card?.work_type?.name}</span>
                </li>
              </ul>
            </div>

            <div className="flex justify-between items-center">
              <div className="kanban-item-meta">
                <ul className="kanban-item-meta-list">
                  <UncontrolledTooltip
                    autohide={false}
                    placement="top"
                    target={`UncontrolledTooltipExample${card?.id}${column?.id?.replace(/\s+/g, "")}`}
                  >
                    {card?.user?.phone}
                  </UncontrolledTooltip>
                  <li
                    id={`UncontrolledTooltipExample${card?.id}${column?.id?.replace(/\s+/g, "")}`}
                    className="cursor-pointer"
                  >
                    <Icon name="call"></Icon>
                  </li>

                  <UncontrolledTooltip
                    autohide={false}
                    placement="top"
                    target={`UncontrolledTooltipExampleNote${card?.id}${column?.id?.replace(/\s+/g, "")}`}
                  >
                    {card?.note || "NO NOTE"}
                  </UncontrolledTooltip>
                  <li
                    id={`UncontrolledTooltipExampleNote${card?.id}${column?.id?.replace(/\s+/g, "")}`}
                    className="cursor-pointer"
                  >
                    <Icon name="file"></Icon>
                  </li>

                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      toggleConversationModal();
                      setSelectedReminder(card);
                    }}
                  >
                    <Icon name="chat"></Icon>
                  </li>

                  {user?.role?.slug === "admin" ? (
                    <li
                      onClick={() => {
                        toggleEditModal();
                        setSelectedReminder(card);
                      }}
                      className="cursor-pointer"
                    >
                      <Icon name="edit"></Icon>
                    </li>
                  ) : null}

                  {user?.role?.slug === "admin" ? (
                    <li
                      onClick={() => {
                        toggleDeleteModal();
                        setSelectedReminder(card);
                      }}
                      className="cursor-pointer"
                    >
                      <Icon name="trash"></Icon>
                    </li>
                  ) : null}
                </ul>
              </div>

              {/* <button id="assign-specialist" onClick={togglePatientModal}>
                <Icon
                  name="edit"
                  style={{
                    fontSize: 15,
                  }}
                />
              </button> */}
              {/* <Tooltip isOpen={tooltipOpen} toggle={toggle} target={"assign-specialist"} placement={"top"}>
                  Assign Specialist
                </Tooltip> */}
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" isOpen={patientModal} fade={true} returnFocusAfterClose={true}>
        <AssignSpecialistForm onClick={togglePatientModal} data={card} isEdit={true} />
      </Modal>

      <Modal size="lg" isOpen={conversationModal} fade={true} returnFocusAfterClose={true}>
        <CreateMessageForm order={selectedReminder} onClose={toggleConversationModal} data={card} isEdit={false} />
      </Modal>

      <Modal size="lg" isOpen={editModal} fade={true} returnFocusAfterClose={true}>
        <EditpaidOrder
          reminder={reminder}
          order={selectedReminder}
          onClose={toggleEditModal}
          data={card}
          isEdit={false}
          selectedSpecialist={selectedSpecialist}
          defaultSpecialist={defaultSpecialist}
        />
      </Modal>

      <Modal isOpen={deleteModal} className="modal-dialog-centered" size="sm">
        <ConfirmDelete onDelete={handleDelete} onClose={toggleDeleteModal} isLoading={isLoading} />
      </Modal>
    </React.Fragment>
  );
};

export const KanbanCardList = ({ data, setData, patients, column, selectedSpecialist, defaultSpecialist }) => {
  return patients?.patient?.length > 0 ? (
    patients?.patient?.map((patient, index) => {
      return (
        <KanbanCard
          defaultSpecialist={defaultSpecialist}
          selectedSpecialist={selectedSpecialist}
          reminder={patients?.reminder}
          card={patient}
          data={data}
          setData={setData}
          key={patient?.id}
          index={index}
          column={column}
        />
      );
    })
  ) : (
    <NoData />
  );
};

export const KanbanColumn = ({ data, setData, column, selectedSpecialist, defaultSpecialist }) => {
  return (
    <React.Fragment>
      <div className="kanban-board">
        <div className={`kanban-board-header kanban-${column.theme}`}>
          <div className="kanban-title-board">
            <div className="kanban-title-content">
              <h6 className="title">{column?.text}</h6>
              <Badge color="outline-light" pill className="text-dark">
                {column?.patients?.length}
              </Badge>
            </div>
          </div>
        </div>

        <div className="kanban-drag">
          <KanbanCardList
            defaultSpecialist={defaultSpecialist}
            selectedSpecialist={selectedSpecialist}
            data={data}
            setData={setData}
            patients={column}
            column={column}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
