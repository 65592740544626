import React from "react";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle } from "../../components/Component";
import Head from "../../layout/head/Head";
import ConversationView from "../../sections/conversations/ConversationView";

const Conversations = () => {
  return (
    <React.Fragment>
      <Head title="Conversations" />
      <div className="nk-content">
        <Block size="lg">
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Conversations
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <ConversationView />
        </Block>
      </div>
    </React.Fragment>
  );
};

export default Conversations;
