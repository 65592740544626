import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Badge } from "reactstrap";
import { useAuthContext } from "../../../../auth/useAuthContext";
import Loading from "../../../../common/Loading";
import { fetchOrders } from "../../../../redux/slices/orderSlice";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../../table/DataTable";
import UserAvatar from "../../../user/UserAvatar";

const TransactionTable = () => {
  const { user } = useAuthContext();

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [trans, setTrans] = useState("");

  const fetchLoading = useSelector((state) => state.order.fetchLoading);
  const orders = useSelector((state) => state.order.orders);

  useEffect(() => {
    dispatch(
      fetchOrders({
        limit: itemPerPage,
        page: currentPage,
        params: {
          ...(user?.role?.slug !== "admin" ? { user_id: user?.id } : {}),
        },
      })
    );
  }, [currentPage, dispatch, itemPerPage, user?.id, user?.role?.slug]);

  console.log(trans, "trans");
  return (
    <React.Fragment>
      <div className="card-inner">
        <div className="card-title-group">
          <div className="flex justify-between w-full">
            <h6 className="title">
              <span className="me-2">Orders</span>{" "}
            </h6>

            <h6 className="title underline">
              <Link
                to={`${user?.role?.slug !== "user" ? "/completed-orders-list" : "/my-orders-list"}`}
                className="me-2"
              >
                View all
              </Link>{" "}
            </h6>
          </div>
        </div>
      </div>
      <DataTableBody className="border-top" bodyclass="nk-tb-orders">
        <DataTableHead>
          <DataTableRow>
            <span>Order ID</span>
          </DataTableRow>

          <DataTableRow size="md">
            <span>Created Date</span>
          </DataTableRow>

          <DataTableRow size="sm">
            <span>Patient</span>
          </DataTableRow>

          <DataTableRow size="sm">
            <span>Specialist</span>
          </DataTableRow>

          <DataTableRow size="lg">
            <span>Amount</span>
          </DataTableRow>

          <DataTableRow>
            <span className="d-none d-sm-inline">Status</span>
          </DataTableRow>
        </DataTableHead>
        {fetchLoading ? (
          <Loading />
        ) : (
          <>
            {orders?.data?.map((item, idx) => {
              return (
                <DataTableItem key={idx}>
                  <DataTableRow>
                    <span className="tb-lead">{item?.code}</span>
                  </DataTableRow>

                  <DataTableRow size="md">
                    <span className="tb-sub">{moment(item?.created_at).format("MMM DD, YYYY")}</span>
                  </DataTableRow>

                  <DataTableRow size="sm">
                    <div className="user-card">
                      <UserAvatar size="sm" text={item?.user?.name?.[0]}></UserAvatar>
                      <div className="user-name">
                        <span className="tb-sub">{item?.user?.name}</span>
                      </div>
                    </div>
                  </DataTableRow>

                  <DataTableRow size="sm">
                    {item?.specialist?.name ? (
                      <div className="user-card">
                        <UserAvatar size="sm" text={item?.specialist?.name?.[0]}></UserAvatar>
                        <div className="user-name">
                          <span className="tb-sub">{item?.specialist?.name}</span>
                        </div>
                      </div>
                    ) : (
                      <span className={`${item?.specialist?.name ? "" : "text-red-500"}`}>Not assigned yet</span>
                    )}
                  </DataTableRow>

                  <DataTableRow size="md">
                    <span className="tb-sub">${parseFloat(item?.price)?.toLocaleString()}</span>
                  </DataTableRow>
                  <DataTableRow>
                    <Badge color={item?.paid === 1 ? "success" : "danger"} className="badge-dot">
                      <span>{item?.paid === 1 ? "Paid" : "Pending"}</span>
                    </Badge>
                  </DataTableRow>
                </DataTableItem>
              );
            })}
          </>
        )}
      </DataTableBody>
    </React.Fragment>
  );
};
export default TransactionTable;
