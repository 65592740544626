import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import Loading from "../../common/Loading";
import NoData from "../../common/NoData";
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle, PaginationComponent } from "../../components/Component";
import Content from "../../layout/content/Content";
import { fetchCategories } from "../../redux/slices/categorySlice";
import LibraryCategoryCard from "../../sections/library/LibraryCategoryCard";

const LibraryCategoryCardUI = () => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);

  useEffect(() => {
    dispatch(
      fetchCategories({
        limit: 50,
      })
    );
  }, [dispatch]);

  const categories = useSelector((state) => state.category.categories);
  const fetchLoading = useSelector((state) => state.category.fetchLoading);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Content>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Library Categories
            </BlockTitle>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {fetchLoading ? (
        <Loading />
      ) : (
        <>
          {categories?.data?.length > 0 ? (
            <Row className="g-gs">
              {categories?.data?.map((c, i) => (
                <LibraryCategoryCard key={i} item={c} />
              ))}
            </Row>
          ) : (
            <NoData size={100} message="No Libraries" />
          )}
        </>
      )}

      {categories?.data?.length > 0 && (
        <div className="mt-3">
          <PaginationComponent
            itemPerPage={itemPerPage}
            totalItems={categories?.meta?.total}
            paginate={paginate}
            currentPage={currentPage}
            setItemPerPage={setItemPerPage}
          />
        </div>
      )}
    </Content>
  );
};

export default LibraryCategoryCardUI;
