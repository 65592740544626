import download from "downloadjs";
import * as htmlToImage from "html-to-image";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import Loading from "../../common/Loading";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { fetchSingleInvoice } from "../../redux/slices/invoiceSlice";

const InvoiceDetails = ({ match }) => {
  const dispatch = useDispatch();

  const invoice = useSelector((state) => state.invoice.singleInvoice);
  const fetchLoading = useSelector((state) => state.invoice.fetchLoading);

  useEffect(() => {
    const id = match.params.id;
    if (id !== undefined || null || "") {
      dispatch(fetchSingleInvoice({ id }));
    }
  }, [match.params.id, dispatch]);

  const downloadPDF = () => {
    htmlToImage.toPng(document.getElementById("print-section")).then(function (dataUrl) {
      download(dataUrl, `INVOICE_${invoice?.invoice_no}`);
    });
  };

  return (
    <React.Fragment>
      <Head title="Invoice Detail"></Head>
      {fetchLoading ? (
        <Loading />
      ) : (
        <Content>
          <BlockHead>
            <BlockBetween className="g-3">
              <BlockHeadContent>
                <BlockTitle>
                  Invoice <strong className="text-primary small">#{invoice?.invoice_no}</strong>
                </BlockTitle>
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      Created At:
                      <span>{moment(invoice?.invoiced_date).format("MMM DD, YYYY")}</span>
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="flex items-center gap-2">
                  <Link to={`${process.env.PUBLIC_URL}/invoice-list`}>
                    <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                  </Link>
                  <Link to={`${process.env.PUBLIC_URL}/invoice-list`}>
                    <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>

                  <Button onClick={downloadPDF} color="primary" outline className="btn-icon btn-white btn-dim !h-fit">
                    <Icon name="download"></Icon>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <div className="invoice" id="print-section">
            <Block>
              <div className="invoice">
                <div className="invoice-wrap">
                  <div className="invoice-brand text-center font-bold">
                    {/* <img src={LogoDark} alt="" /> */}
                    Ryze HRT
                  </div>

                  <div className="invoice-head">
                    <div className="flex flex-col">
                      <img src="/logo.png" alt="logo" className="w-16 mb-2 h-16" />
                      <div className="invoice-contact">
                        <span className="overline-title">Invoice To</span>
                        <div className="invoice-contact-info">
                          <h4 className="title">{invoice?.user?.name}</h4>
                          <ul className="list-plain">
                            <li>
                              <Icon name="mail-fill"></Icon>
                              <span>{invoice?.user?.email}</span>
                            </li>
                            <li>
                              <Icon name="call-fill"></Icon>
                              <span>{invoice?.user?.phone}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col gap-2">
                      <div className="invoice-contact">
                        <span className="overline-title">Invoice From</span>
                        <div className="invoice-contact-info">
                          <h4 className="title">admin@ryzehrt.com</h4>
                          <ul className="list-plain">
                            <li>Hormone Replacement Therapy</li>
                          </ul>
                        </div>
                      </div>

                      <div className="invoice-desc">
                        <ul className="list-plain">
                          <li className="invoice-id">
                            <span>Invoice ID</span>:<span>{invoice?.invoice_no}</span>
                          </li>
                          <li className="invoice-date">
                            <span>Date</span>:<span>{moment(invoice?.invoiced_date).format("MMM DD, YYYY")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="invoice-bills">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>ORDER ID</th>
                            <th>Specialist</th>
                            <th>User</th>
                            <th>Work Type</th>
                            <th>Status</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{invoice?.code}</td>
                            <td>{invoice?.specialist?.name || "---"}</td>
                            <td>{invoice?.user?.name}</td>
                            <td>{invoice?.work_type?.name}</td>
                            <td>
                              <Badge
                                color={
                                  invoice?.status === "paid" ||
                                  invoice?.status === "assigned" ||
                                  invoice?.status === "completed"
                                    ? "success"
                                    : "danger"
                                }
                                className="badge-dot"
                              >
                                <span>
                                  {invoice?.status === "paid" ||
                                  invoice?.status === "assigned" ||
                                  invoice?.status === "completed"
                                    ? "Paid"
                                    : "Pending"}
                                </span>
                              </Badge>
                            </td>
                            <td>${invoice?.price}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan="3"></td>
                            <td colSpan="2">Grand Total</td>
                            <td>${invoice?.price}</td>
                          </tr>
                        </tfoot>
                      </table>
                      <div className="nk-notes ff-italic fs-12px text-soft">
                        NOTE: Invoice was created on a computer and is valid without the signature and seal.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Block>
          </div>
        </Content>
      )}
    </React.Fragment>
  );
};
export default InvoiceDetails;
